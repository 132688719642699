import './SpeedySignup.scss';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import React, {useContext, useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {RootState} from "../../app/store";
import {closeSpeedySignup, ISignupPayload, resetErrorState, signUpPayload, signUpUser} from "./sessionSlice";
import {CustomButton} from "../utils/CustomButton";
import {Alert, AlertTitle, CardActions, CircularProgress, Divider, FormHelperText, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import Box from "@mui/material/Box";
import {FormattedMessage, useIntl} from "react-intl";
import {Link, useNavigate} from "react-router-dom";
import {closeProduct} from "../productDetails/productDetailsSlice";
import {I18nContext} from "../locale/LocaleWrapper";
import {useAppSelector} from "../../app/hooks";
import {primaryColor, secondaryColor} from "../catalog/catalogSlice";
import {openSnackBar} from "../global/globalSlice";
import {getSelection} from "../selection/selectionSlice";


function SpeedySignup() {
  const intl = useIntl();
  const { locale } = useContext(I18nContext);
  const primColor = useAppSelector(primaryColor);
  const secColor = useAppSelector(secondaryColor);
  const navigate = useNavigate();

  const emailRef = useRef<HTMLInputElement>();
  const waiting = useSelector((state: RootState) => state.session.waiting);
  const errorMessages = useSelector((state: RootState) => state.session.errorMessages);
  const signUpSelectionPayload = useSelector((state: RootState) => state.session.signUpPayload);

  const [errors, setErrors] = useState<Array<string>>([])
  const dispatch = useDispatch();

  useEffect(() => {
    emailRef?.current?.focus();
    if (errorMessages.length > 0) {
      setErrors(errorMessages.map((message: string) => intl.formatMessage({id: 'error.' + message})));
      dispatch(resetErrorState());
    }
  }, [errorMessages])

  const closeDialog = () => {
    setErrors([]);
    dispatch(closeSpeedySignup())
  }

  const goToSignin = () => {
    setErrors([]);
    dispatch(closeSpeedySignup())
    dispatch(closeProduct())
    navigate(`/${locale}/login`)
  }

  async function handleSubmit() {
    setErrors([]);
    if (emailRef?.current === undefined || emailRef.current.value === "") {
      return setErrors([intl.formatMessage({id: "session.type-email"})])
    }

    const payload: ISignupPayload = {
      email: emailRef.current.value,
      firstName: '',
      lastName: '',
      password: '',
      locale: locale,
      color1: primColor,
      color2: secColor,
      selection: signUpSelectionPayload
    }

    const response = await dispatch(signUpUser(payload)) as any;

    if (response.type === 'session/signUpUser/fulfilled') {
      dispatch(closeProduct())
      dispatch(getSelection())

      let successMessage = '';
      let warning = false;
      if (response.payload.addedOtherColors) {
        successMessage = intl.formatMessage({id: "selection.added-with-other-colors"})
        warning = true
      } else {
        successMessage = intl.formatMessage({id: "selection.added"})
      }
      dispatch(openSnackBar({severity: warning ? "warning" : "success", message: successMessage, noAutoClose: warning}));
    }

  }

  return (
    <Dialog open={true} className='speedy-signup'>
      <DialogTitle>{intl.formatMessage({id: "account.speedy.title"})}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {intl.formatMessage({id: "account.speedy.body"})}
        </DialogContentText>
        {errors.length > 0 &&
          <Alert severity="error" aria-live="assertive" sx={{mt: 3}}>
            <AlertTitle>{intl.formatMessage({id: "global.error"})}</AlertTitle>
            {errors.map((error, index) => {return <p key={`alert-${index}`}>{error}</p>})}
          </Alert>
        }
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label={intl.formatMessage({id: "session.email"})}
          inputRef={emailRef}
          type="email"
          fullWidth
          variant="standard"
          helperText={intl.formatMessage({id: "session.email-privacy"})}
        />
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={closeDialog}>{intl.formatMessage({id: "global.cancel"})}</CustomButton>
        <Box sx={{position: 'relative', ml:'5px'}}>
          <Button variant="contained" disabled={waiting} color="success" onClick={handleSubmit}>
            {intl.formatMessage({id: "account.speedy.save"})}
          </Button>
          {waiting && (
            <CircularProgress
              size={24}
              sx={{
                color: grey[500],
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </DialogActions>
      <Box sx={{p:1,mt:2,mb:2, textAlign: 'center'}}>
        {intl.formatMessage({id: "session.already"})} <Box component="span" onClick={goToSignin}
                                                           sx={{textDecoration: 'underline', cursor: 'pointer'}}>
          {intl.formatMessage({id: "session.login"})}
        </Box>
      </Box>
    </Dialog>
  )
}

export default SpeedySignup;