import React, {PropsWithChildren, useState} from 'react';
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import {IntlShape, useIntl} from "react-intl";
import CloseIcon from "@mui/icons-material/Close";
import {closeHelpTip, removeHelpTip} from "../sessions/sessionSlice";
import MarkdownRenderer from "./MarkdownRenderer";

const HELP_TIPS_PAUSE_MINUTES = 15;

export type PopoverEditProps = {
  text: string | null,
  onSetText: Function,
  title: string,
  editable?: boolean
}

const UserHelpTipContent = (props: {
  component: string,
  anchor: string,
  messageKey: string,
  intl: IntlShape,
  dispatch: Function
}) => {

  const { component, anchor, messageKey, intl, dispatch} = props;

  const handleClose = () => {
    dispatch(closeHelpTip({component: component, anchor: anchor}));
  }

  const handleRemove = () => {
    dispatch(closeHelpTip({component: component, anchor: anchor}));
    dispatch(removeHelpTip({component: component, anchor: anchor}));
  }

  const handlePause = () => {
    const pauseUntil = Date.now() + HELP_TIPS_PAUSE_MINUTES * 60 * 1000;
    localStorage.setItem('helpTipPausedUntil', pauseUntil.toString());
    dispatch(closeHelpTip({component: component, anchor: anchor}));
  };

  const contentTitle = intl.formatMessage({id: `help-${component}-${messageKey}-title`});
  const contentMessage = intl.formatMessage({id: `help-${component}-${messageKey}-message`});
  const suppressHelpTip = intl.formatMessage({id: 'help-remove'});
  const pauseHelp = intl.formatMessage({id: 'help-pause'});

  return (
    <>
      <IconButton aria-label="close" onClick={handleClose} className='close' >
        <CloseIcon />
      </IconButton>
      <Box className='title'><MarkdownRenderer markdown={contentTitle} /></Box>
      {contentMessage !== '-' && <Box className='content'>
	      <MarkdownRenderer markdown={contentMessage} />
      </Box>}
      <Box className='pause' onClick={handlePause}>
        {pauseHelp}
      </Box>
      <Box className='remove' onClick={handleRemove} >
        {suppressHelpTip}
      </Box>
    </>
  );
}

export default UserHelpTipContent;