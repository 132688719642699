import React, {useState} from 'react';
import {ILogoInfos, ILogoVersion} from "../logos/logosSlice";
import {IAddonInfos, ICollectionProduct, IImageAddon} from "./boutiqueSlice";
import {FormattedMessage, FormattedNumber} from "react-intl";
import {SelectAddonLogo} from "./SelectAddonLogo";
import {Checkbox, FormControlLabel} from "@mui/material";
import {arrEq} from "../../app/utils";

export const LogoAddonConfig = (props: {
  collectionProduct: ICollectionProduct,
  imgAddon: IImageAddon,
  addonInfos: IAddonInfos,
  colorIndex: number,
  disabled: boolean,
  multiLogos: boolean,
  setAddonInfos: (colorIndex: number, addonInfos: IAddonInfos) => void,
  logos: ILogoInfos[],
  editMode: boolean,
  index: number
}) => {

  const {collectionProduct, imgAddon, addonInfos, colorIndex, disabled, multiLogos, setAddonInfos, logos, editMode, index} = props;

  // const logosInitValue = (multiLogos && addonInfos.logoIds ?
  //       addonInfos.logoIds : [addonInfos.logoId])
  //   .map(logoId => logos.find(l => l.id === logoId)) as Array<ILogoInfos | undefined>;

  // const [selectedLogos, setSelectedLogos] = useState<Array<ILogoInfos | undefined>>(logosInitValue);

  // const logoVersionsInitValue = logosInitValue
  //   .map(logo => logo?.versions.find(v => v.id === addonInfos.logoVersionId));
  // const [logoVersions, setLogoVersions] = useState<Array<ILogoVersion | undefined>>(logoVersionsInitValue);

  const handleSelectLogo = (logoIds: number[], logoVersionIds: number[]) => {
    // console.log('At LogoAddonConfig level, handleSelectLogo ' + logoId + ' version ' +  logoVersionId);

    // if any of the loogIds is 0, it means that the user has selected the 'none' option, erase everything
    // unless the previous selection was already 'none', which is empty or [0]
    if (logoIds.includes(0) && addonInfos.logoIds.length > 0 && !arrEq(logoIds, [0])) {
      // setSelectedLogos([]);
      // setLogoVersions([]);

      setAddonInfos(colorIndex, {
        addonId: imgAddon.id,
        name: imgAddon.name,
        maxWidthSmall: imgAddon.maxWidthSmall,
        maxWidthLarge: imgAddon.maxWidthLarge,
        maxHeightSmall: imgAddon.maxHeightSmall,
        maxHeightLarge: imgAddon.maxHeightLarge,
        logoIds: [],
        logoVersionIds: [],
        zoom: addonInfos.zoom,
        optional: addonInfos.optional
      });
    } else {

      setAddonInfos(colorIndex, {
        addonId: imgAddon.id,
        name: imgAddon.name,
        logoIds: logoIds.filter((id) => id !== 0),
        logoVersionIds: logoVersionIds.filter((id) => id),
        maxWidthSmall: imgAddon.maxWidthSmall,
        maxWidthLarge: imgAddon.maxWidthLarge,
        maxHeightSmall: imgAddon.maxHeightSmall,
        maxHeightLarge: imgAddon.maxHeightLarge,
        zoom: addonInfos.zoom,
        optional: addonInfos.optional
      })
    }

  }

  // in case a logo version is directly selected
  // 2 cases : logo is already selected and its version is changing, or a new logo is selected
  const handleSelectLogoVersion = (logoId: number, logoVersionId: number) => {
    if (multiLogos) {

      // if in multiLogos mode, we can have multiple logos and multiple versions
      const newLogoIds = addonInfos.logoIds || [];
      const newLogoVersionIds = addonInfos.logoVersionIds || [];

      // if the logoId is already in the list, change the version, otherwise add it
      const logoIndex = newLogoIds.indexOf(logoId);
      if (logoIndex >= 0) {
        // logoId is already in the list, change the version
        newLogoVersionIds[logoIndex] = logoVersionId;
      } else {
        // logoId is not in the list, add it
        newLogoIds.push(logoId);
        newLogoVersionIds.push(logoVersionId);
      }

      // update the state
      setAddonInfos(colorIndex, {
        addonId: imgAddon.id,
        name: imgAddon.name,
        maxWidthSmall: imgAddon.maxWidthSmall,
        maxWidthLarge: imgAddon.maxWidthLarge,
        maxHeightSmall: imgAddon.maxHeightSmall,
        maxHeightLarge: imgAddon.maxHeightLarge,
        logoIds: newLogoIds,
        logoVersionIds: newLogoVersionIds,
        zoom: addonInfos.zoom,
        optional: addonInfos.optional
      })

    } else {
      handleSelectLogo([logoId], [logoVersionId]);
    }
  }


  const handleOptional = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setPersoActivated(event.target.checked);

    setAddonInfos(colorIndex, {
      addonId: imgAddon.id,
      name: imgAddon.name,
      maxWidthSmall: imgAddon.maxWidthSmall,
      maxWidthLarge: imgAddon.maxWidthLarge,
      maxHeightSmall: imgAddon.maxHeightSmall,
      maxHeightLarge: imgAddon.maxHeightLarge,
      logoIds: addonInfos.logoIds,
      logoVersionIds: addonInfos.logoVersionIds,
      zoom: addonInfos.zoom,
      optional: event.target.checked
    })
  }

  // if (imgAddon.name === 'big-logo-chest' && colorIndex === 0) {
  //   console.log("Rendering LogoAddonConfig 1 ", addonInfos);
  // }


  let logoVersionSelected: ILogoVersion | undefined;
  // if (addonInfos.logoId > 0) {
  //   const logo = logos.find(l => l.id === addonInfos.logoId);
  //   if (logo) {
  //     logoVersionSelected = logo.versions.find(v => v.id === addonInfos.logoVersionId);
  //   }
  // } else {
    if (addonInfos.logoIds[0] && addonInfos.logoVersionIds[0]) {
      const firstLogoId = addonInfos.logoIds[0];
      const logo = logos.find(l => l.id === firstLogoId);
      const logoVersionId = addonInfos.logoVersionIds[0];
      if (logo && logoVersionId) {
        logoVersionSelected = logo.versions.find(v => v.id === logoVersionId);
      }
    }
  // }

  // if (imgAddon.name === 'big-logo-chest' && colorIndex === 0) {
  //   console.log("Rendering LogoAddonConfig 2 " + imgAddon.name + " with logoVersionSelected ", logoVersionSelected);
  // }

  // console.log("Rendering LogoAddonConfig 2 logoVersionSelected=" + logoVersionSelected + " with logoVersionSelected.height= " + logoVersionSelected?.height + " and addonInfos.maxHeightSmall=" + addonInfos.maxHeightSmall);

  const warningLogoTooWide = () => {

    if (logoVersionSelected && addonInfos.maxWidthSmall > 0) {
      let warningValue = 0;

      // if the product has different sizes, we warn only if the logo is too wide for the largest size
      if (addonInfos.maxWidthLarge > 0) {
        if (logoVersionSelected.width > addonInfos.maxWidthLarge) {
          warningValue = addonInfos.maxWidthLarge;
        }
      } else {
        // if the product has only one size, therefore only addonInfos.maxWidthSmall would be set, compare with it
        if (logoVersionSelected.width > addonInfos.maxWidthSmall) {
          warningValue = addonInfos.maxWidthSmall;
        }
      }

      if (warningValue > 0)
        return <div className='too-big'>
          <FormattedMessage id='addon.too-wide' values={{w: logoVersionSelected.width, max: warningValue}}/>
        </div>
    }
  }

const warningLogoTooHigh = () => {
  if (logoVersionSelected && addonInfos.maxHeightSmall > 0) {
    let warningValue = 0;

    // if the product has different sizes, we warn only if the logo is too high for the largest size
    if (addonInfos.maxHeightLarge > 0) {
      if (logoVersionSelected.height > addonInfos.maxHeightLarge) {
        warningValue = addonInfos.maxHeightLarge;
      }
    } else {
      // if the product has only one size, therefore only addonInfos.maxHeightSmall would be set, compare with it
      if (logoVersionSelected.height > addonInfos.maxHeightSmall) {
        warningValue = addonInfos.maxHeightSmall;
      }
    }

    if (warningValue > 0)
      return <div className='too-big'>
        <FormattedMessage id='addon.too-high' values={{h: logoVersionSelected.height, max: warningValue}}/>
      </div>
  }
}

  if (colorIndex === 0 || logoVersionSelected) {
    return (
      <div id={index === 0 ? 'assign-logo' : `assgnlg${index}`}
           className={`logo-addon-config ${disabled ? 'disabled' : ''}  ${logoVersionSelected ? 'selected' : ''}`}>
        <div className="addon-name">
          <div className='name'>
            <FormattedMessage id={`addon.${imgAddon.name}`}/>
            {multiLogos && addonInfos.logoIds.length > 1 && <div className='multi-logos'>
              <FormattedMessage id='addon.multi-logos' values={{nb: addonInfos.logoIds.length}} />
            </div>}
            {multiLogos && addonInfos.logoIds.length === 1 && <div className='multi-none'>
              <FormattedMessage id='addon.multi-none' />
            </div>}
            {warningLogoTooWide()}
            {warningLogoTooHigh()}
          </div>
          <div className={`choose-logo optional ${addonInfos.optional ? 'checked' : ''}`}>
            <FormControlLabel label={<FormattedMessage id='addon.optional-logo' />}
                              labelPlacement="start"
                              control={
                                <Checkbox sx={{'&.MuiCheckbox-root': {color: '#808080'}}} size='small'
                                          disabled={colorIndex > 0 || disabled || !editMode}
                                          checked={addonInfos.optional}
                                          onChange={handleOptional}/>
                              }
            />
          </div>
        </div>
        <div className='addon-logo-price'>
          <div className='select-addon-logo'>
            <SelectAddonLogo collectionProduct={collectionProduct} logos={logos} imageAddon={imgAddon}
                             addonInfo={addonInfos} selectLogoVersion={handleSelectLogo}
                             designateLogoVersion={handleSelectLogoVersion}
                             noneAllowed={colorIndex === 0} editMode={editMode} multiLogos={multiLogos} />
          </div>
          <div className={`addon-price ${addonInfos.optional ? 'optional' : ''}`}>
            {logoVersionSelected &&
                <FormattedNumber value={logoVersionSelected.priceCents / 100} style="currency" currency={'EUR'}
                                 minimumFractionDigits={2} maximumFractionDigits={2}
              />}
          </div>
        </div>
      </div>
    )
  } else {
    return null;
  }
}
