import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import produce from "immer";
import {RootState} from "../../app/store";
import {AlertColor} from "@mui/material";

export interface IGlobalState {
  catalogVisible: boolean;
  helpVisible: boolean;
  fullScreenState: IFullScreenComponent | null;
  snackBarState: ISnackBarState;
  confirmDialogState: IConfirmDialogState;
  backDropState: {
    showBackDrop: boolean,
  };
}

const initialState: IGlobalState = {
  catalogVisible: false,
  helpVisible: false,
  fullScreenState: null,
  snackBarState: {
    snackBarOpened: false,
    snackBarSeverity: "success",
    snackBarMessage: "",
    noAutoClose: false
  },
  confirmDialogState: {
    dialogOpened: false,
    dialogTitle: "",
    dialogMessage: "",
    dialogConfirm: "",
    dialogAction: null
  },
  backDropState: {
    showBackDrop: false,
  },
};

export interface ISnackBarState {
  snackBarOpened: boolean;
  snackBarSeverity: AlertColor;
  snackBarMessage: string;
  noAutoClose: boolean;
}

export interface IConfirmDialogState {
  dialogOpened: boolean;
  dialogTitle: string;
  dialogMessage: string;
  dialogMessageHTML?: string;
  dialogConfirm: string;
  dialogConfirmColor?: AlertColor;
  dialogAction: (() => void) | null;
  dialogActionMethod?: string;
  dialogActionParam?: number | string | Object | null;
}

export interface IFullScreenComponent {
  name: string;
  targetType?: string;
  targetId?: number;
  displayedId: number;
  param?: string;
}

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    displayCatalog: (state, action) => {
      state.catalogVisible = action.payload;
    },
    displayHelp: (state, action) => {
      state.helpVisible = action.payload
    },
    openInFullScreen: (state, action) => {
      state.fullScreenState = action.payload;
    },
    closeFullScreen: (state) => {
      state.fullScreenState = null;
    },
    showBackDrop: (state) => {
      state.backDropState.showBackDrop = true;
    },
    hideBackDrop: (state) => {
      state.backDropState.showBackDrop = false;
    },
    openSnackBar: (state, action) => {
      state.snackBarState.snackBarOpened = true;
      state.snackBarState.snackBarSeverity = action.payload.severity;
      state.snackBarState.snackBarMessage = action.payload.message;
      state.snackBarState.noAutoClose = action.payload.noAutoClose;
    },
    closeSnackBar: (state) => {
      state.snackBarState.snackBarOpened = false;
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialogState.dialogOpened = true;
      state.confirmDialogState.dialogTitle = action.payload.title;
      state.confirmDialogState.dialogMessage = action.payload.message;
      state.confirmDialogState.dialogMessageHTML = action.payload.messageHTML;
      state.confirmDialogState.dialogConfirm = action.payload.confirm;
      if (action.payload.confirmColor !== undefined) {
        state.confirmDialogState.dialogConfirmColor = action.payload.confirmColor;
      } else {
        state.confirmDialogState.dialogConfirmColor = "error";
      }
      state.confirmDialogState.dialogAction = action.payload.action;
      state.confirmDialogState.dialogActionMethod = action.payload.actionMethod;
      state.confirmDialogState.dialogActionParam = action.payload.actionParam;
    },
    closeConfirmDialog: (state) => {
      state.confirmDialogState.dialogOpened = false;
      state.confirmDialogState.dialogTitle = '';
      state.confirmDialogState.dialogMessage = '';
      state.confirmDialogState.dialogConfirm = '';
      state.confirmDialogState.dialogAction = null;
    },
  }
});

export const {
  displayCatalog,
  displayHelp,
  openInFullScreen,
  closeFullScreen,
  showBackDrop,
  hideBackDrop,
  openSnackBar,
  closeSnackBar,
  openConfirmDialog,
  closeConfirmDialog,
} = globalSlice.actions;


export const showBackDropState = (state: RootState) => state.global.backDropState.showBackDrop;

export const snackBarState = (state: RootState) => state.global.snackBarState;
export const confirmDialogState = (state: RootState) => state.global.confirmDialogState;

export const fullScreenComponent = (state: RootState) => state.global.fullScreenState;

export default globalSlice.reducer;








