
export interface IProductColor {
  code: string;
  hexcode: string;
  textcolor: string;
}

// keep this list in sync with the list in the backend
// located at app/models/procure/color_value.rb
export const ProductColors: IProductColor[] = [
  {code:'none',    hexcode: '#FFFFFF', textcolor: 'black'},
  {code:'grey',    hexcode: '#C0C0C0', textcolor: 'black'},
  {code:'white',   hexcode: '#FFFFFF', textcolor: 'black'},
  // {code:'green',   hexcode: '#60FF60', textcolor: 'black'},
  {code:'green',   hexcode: '#49a168', textcolor: 'white'},
  {code:'yellow',  hexcode: '#FFFF40', textcolor: 'black'},
  {code:'orange',  hexcode: '#FF8000', textcolor: 'black'},
  {code:'red',     hexcode: '#FF4040', textcolor: 'white'},
  {code:'pink',    hexcode: '#FFC4FC', textcolor: 'black'},
  {code:'purple',  hexcode: '#7D51CC', textcolor: 'white'},
  {code:'blue',    hexcode: '#1565C0', textcolor: 'white'},
  {code:'navy',    hexcode: '#2A3A92', textcolor: 'white'},
  // {code:'brown',   hexcode: '#964B00', textcolor: 'white'},
  {code:'black',   hexcode: '#000000', textcolor: 'white'},
]
export const HexCode = (colorCode: string) => {
  let productColor = ProductColors.find(color => color.code === colorCode);

  return productColor === undefined ? '#FFFFFF' : productColor.hexcode;
}

export const TextColor = (colorCode: string) => {
  let productColor = ProductColors.find(color => color.code === colorCode);

  return productColor === undefined ? '#000000' : productColor.textcolor;
}

const MAX_COLOR_DISTANCE = 255 * 255 * 3;

export const colorProximity = (color1:string, color2:string) =>
{
  if (!color1 || !color2) {
    console.log("colorProximity: missing color1 or color2");
    return 0;
  }
  // Convert hexadecimal colors to RGB
  const r1 = parseInt(color1.slice(1, 3), 16);
  const g1 = parseInt(color1.slice(3, 5), 16);
  const b1 = parseInt(color1.slice(5, 7), 16);

  const r2 = parseInt(color2.slice(1, 3), 16);
  const g2 = parseInt(color2.slice(3, 5), 16);
  const b2 = parseInt(color2.slice(5, 7), 16);

  const distance = Math.pow(r2 - r1, 2) + Math.pow(g2 - g1, 2) + Math.pow(b2 - b1, 2);

  return (30 * (MAX_COLOR_DISTANCE - distance) / MAX_COLOR_DISTANCE);
}

export const INCOMPATIBLE_WITH_BLACK = ['black', 'navy'];
export const INCOMPATIBLE_WITH_WHITE = ['white'];
