import React, {useEffect, useState} from 'react';

const FacebookLikeButton: React.FC = () => {
  useEffect(() => {
    // Ensure the Facebook SDK is loaded and parse the widget
    if (window.FB && window.FB.XFBML) {
      window.FB.XFBML.parse();
    }
  }, []);

  return (
    <div>
      <div
        className="fb-like"
        data-href="https://www.facebook.com/dagobaapp/"
        data-width=""
        data-layout="box_count"
        data-action="like"
        data-size="large"
        data-share="true"
      ></div>
    </div>
  );
};

const FacebookLikeButtonWrapper: React.FC = () => {
  const [fbLoaded, setFbLoaded] = useState(false);

  useEffect(() => {
    // Poll for FB availability (or attach to a custom event in fbAsyncInit)
    const interval = setInterval(() => {
      if (window.FB) {
        setFbLoaded(true);
        clearInterval(interval);
      }
    }, 100);
    return () => clearInterval(interval);
  }, []);

  if (!fbLoaded) {
    return <div>Loading...</div>; // or nothing
  }
  return <FacebookLikeButton />;
};


export default FacebookLikeButtonWrapper;
