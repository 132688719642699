// SEO.tsx
import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { I18nContext, SUPPORTED_LOCALES } from "../locale/LocaleWrapper";

interface SEOProps {
  title: string;
  description: string;
}

const baseUrl = process.env.REACT_APP_PUBLIC_URL;

const SEO: React.FC<SEOProps> = ({ title, description }) => {
  const { locale } = useContext(I18nContext);
  const location = useLocation();

  // Split the current pathname into segments
  const segments = location.pathname.split("/").filter(Boolean);
  // If the first segment is a supported locale, remove it
  const subpath = segments.length > 0 && SUPPORTED_LOCALES.includes(segments[0])
    ? "/" + segments.slice(1).join("/")
    : location.pathname;

  // Build the canonical URL using the current locale and the subpath (without duplicate locale)
  const canonicalUrl = `${baseUrl}/${locale}${subpath}`;

  return (
    <Helmet>
      {/* Set the <html> lang attribute */}
      <html lang={locale} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalUrl} />

      {/* Generate alternate links for each supported locale */}
      {SUPPORTED_LOCALES.map((lang) => (
        <link
          key={lang}
          rel="alternate"
          hrefLang={lang}
          href={`${baseUrl}/${lang}${subpath}`}
        />
      ))}

      {/* x-default: the fallback URL (here, English is chosen as the default) */}
      <link rel="alternate" hrefLang="x-default" href={`${baseUrl}/en${subpath}`} />

      {/* Open Graph tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:locale" content={locale === "en" ? "en_US" : "fr_FR"} />

      {/* Twitter meta tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
};

export default SEO;
