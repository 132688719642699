import React from "react";

const ShirtSvg = (height: string) => {

  return (
    <svg version="1.1" viewBox="0 0 64 64" style={{'height': height}}>
      <g className="icon">
        <path d="M24.3,26.2l-8.2-14.1l-0.7-1.2c-0.3-0.5-0.4-0.9-0.5-1.4h-3c-1.9,0-3.4,1.5-3.4,3.4V57c0,1.9,1.5,3.4,3.4,3.4h15.8V28.1
				c-0.1,0-0.2,0-0.3,0C26.2,28.1,25,27.4,24.3,26.2L24.3,26.2z"/>
        <path d="M55.5,57V12.9c0-1.9-1.5-3.4-3.4-3.4h-3c-0.1,0.5-0.2,1-0.5,1.4l-0.7,1.2l-8.2,14.1c-0.7,1.2-1.9,1.9-3.1,1.9
				c-0.1,0-0.2,0-0.3,0v32.2h15.8C54,60.3,55.5,58.8,55.5,57L55.5,57z"/>
        <polygon points="32,12.3 34.7,9.5 40.1,3.8 23.9,3.8 29.3,9.5 32,12.3 			"/>
        <path d="M36.3,26c0.6,0.1,1.2-0.1,1.6-0.8l8.2-14.1l0.7-1.2c0.1-0.1,0.1-0.3,0.2-0.4c0.2-0.6,0.1-1.2-0.2-1.6l-2.2-3.5
				c-0.5-0.8-1.5-0.8-2.2-0.1l-0.2,0.2l-1.9,2l-2.9,3l-4.1,4.4l-1,1.1L32,15.3l-0.4-0.4l-1-1.1l-4.1-4.4l-2.9-3l-1.5-1.6l-0.6-0.6
				c-0.3-0.3-0.6-0.5-1-0.5c-0.5,0-0.9,0.2-1.2,0.6l-2.2,3.5C17.1,7.9,17.1,8,17,8.1c-0.2,0.4-0.2,0.9,0,1.3c0,0.1,0.1,0.3,0.2,0.4
				l0.7,1.2l8.2,14.1c0.4,0.7,1.1,1,1.6,0.8c0.3-0.1,0.7-0.3,0.9-0.7l1.5-2.8l0.8-1.6l1.1-2.2l1.1,2.2l0.8,1.6l1.5,2.8
				C35.6,25.7,35.9,26,36.3,26L36.3,26z"/>
        <path d="M32,31.8c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2s2-0.9,2-2C34,32.7,33.1,31.8,32,31.8L32,31.8z"/>
        <path d="M32,41c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2s2-0.9,2-2C34,41.9,33.1,41,32,41L32,41z"/>
        <path d="M32,50.3c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2s2-0.9,2-2C34,51.2,33.1,50.3,32,50.3L32,50.3z"/>
      </g>
    </svg>
  );
}


export default ShirtSvg;

