import './logo.scss';
import React, {useState, useEffect, ReactElement, useContext} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../app/store";
import {
  ILogoInfos,
  storeLogoInfo,
  saveLogo,
  editLogo,
  editedLogoSelector,
  editedLogoSavingSelector,
  editedLogoHasChangesSelector,
  NEW_LOGO_NAME,
  editedLogoImageChangedSelector,
  logoDeletableSelector,
  logoUsageExplanationHTML,
  TECHNIQUE_DTF,
  TECHNIQUE_EBD,
  TECHNIQUE_STC,
  MAX_LOGO_FILE_SIZE,
  NAME_FILTER_REGEX, NAME_VALIDATION_REGEX, anchorNameForTechnique
} from "./logosSlice";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  RadioGroup
} from "@mui/material";
import Radio from '@mui/material/Radio';
import IconButton from "@mui/material/IconButton";
import TransitEnterexitIcon from '@mui/icons-material/TransitEnterexit';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpIcon from '@mui/icons-material/Help';
import {CustomButton} from "../utils/CustomButton";
import ImageUploading, { ImageListType } from "react-images-uploading";
import {ErrorsType, ImageType} from "react-images-uploading/dist/typings";
import InlineEdit from "../utils/InlineEdit";
import {openConfirmDialog, openSnackBar} from "../global/globalSlice";

import WhiteBgArticle from './img/white_bg_article.jpg';
import DarkBgArticle from './img/dark_bg_article.jpg';
import {FormattedMessage, useIntl} from "react-intl";
import {SelectLogoBg} from "./SelectLogoBg";
import LogoVersionsCard from "./LogoVersionsCard";
import Box from "@mui/material/Box";
import {BoudaToolTip} from "../utils/BoudaToolTip";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FileUpload from "../utils/FileUpload";
import {postLogoFile} from "../../app/logosAPI";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from '@mui/icons-material/Error';
import {impersonatingUser} from "../sessions/sessionSlice";
import {getBoutiques} from "../dashboard/boutiqueSlice";
import {useHelpTips} from "../../app/helpToolTip";
import {openDocAtAnchor} from "../../app/website";
import {I18nContext} from "../locale/LocaleWrapper";

function LogoForm() {

  const intl = useIntl();
  const {locale} = useContext(I18nContext);

  const currentUser = useSelector((state : RootState) => state.session.currentUser);
  const impersonating = impersonatingUser(currentUser);

  const editedLogo = useSelector(editedLogoSelector);
  const editable = editedLogo?.technique !== TECHNIQUE_EBD || (impersonating || currentUser?.superAdmin) || false;

  const logoDeletable = useSelector(logoDeletableSelector);
  const canDeleteLogo = logoDeletable && editedLogo && (impersonating || editedLogo.technique === TECHNIQUE_DTF);

  const logoHasChanges = useSelector(editedLogoHasChangesSelector);
  const logoImageChanged = useSelector(editedLogoImageChangedSelector);

  const saving = useSelector (editedLogoSavingSelector);
  const dispatch = useDispatch<AppDispatch>();

  const [nameError, setNameError] = useState(false);

  const [images, setImages] = React.useState([] as ImageType[]);

  const saveable = !saving && !nameError && images.length > 0;

  // Help tips of this component
  useHelpTips('logoform', intl, dispatch);

  useEffect(() => {
    if (editedLogo && editedLogo.logoUrl !== null && editedLogo.logoUrl !== '' && editedLogo.logoUrl !== 'new') {
      setImages([{dataURL: editedLogo.logoUrl}]);
    }
  }, [editedLogo, logoHasChanges]);

  const logoChanged = () => (editedLogo && logoHasChanges);

  const logoIsUsed = (editedLogo && editedLogo.id !== 0 && editedLogo.usage.length > 0);

  const logoNameChangePrompt = intl.formatMessage({ id: "logos.give-name"});
  const editedLogoName = (editedLogo && editedLogo.name !== NEW_LOGO_NAME) ? editedLogo.name : logoNameChangePrompt;
  const logoNameDefined = () => (editedLogo && editedLogo.name !== NEW_LOGO_NAME && editedLogo.name !== logoNameChangePrompt  && editedLogo.name !== '?');

  const setInfos = (field: string, value:string|boolean|number|null) => {
    if (field === 'name' && value === '') {
      setNameError(false);
      value = '?'
    }
    if (field === 'name' && value !== NEW_LOGO_NAME && value !== logoNameChangePrompt) {
      setNameError(false);
    }
    // console.log("setInfos with field " + field + " and value " + value);
    dispatch(storeLogoInfo({field: field, value: value}))
  }

  const onChange = (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
    if (imageList.length > 0) {
      if (imageList[0].dataURL !== undefined) {
        setInfos('logoUrl', 'new');
      } else {
        setInfos('logoUrl', '');
      }
    } else {
      setInfos('logoUrl', 'none');
    }
    // console.log("%cSetting the state to images= " + JSON.stringify(imageList), "color:green");
    setImages(imageList);
  };

  const savedLogo = () => {
    let logoInfos;

    if (images.length > 0) {
      if (images[0].file !== undefined || images[0].dataURL !== undefined) {
        // console.log("%csaving logo with new image " + JSON.stringify(images[0]), "color:red");

        const filePassed = images[0].dataURL;
        // console.log("%cfile passed " + JSON.stringify(filePassed), "color:purple");
        logoInfos = {...editedLogo, file: filePassed} as ILogoInfos;
      } else {
        // console.log("saving logo with same image");
        logoInfos = {...editedLogo, file: null} as ILogoInfos;
      }
    } else {
      // console.log("saving logo without image");
      logoInfos = {...editedLogo, file: null, logoUrl: 'none'} as ILogoInfos;
    }

    return(logoInfos);
  }

  const handleSave = () => {

    if (editedLogo && logoNameDefined()) {

      if (images.length > 0) {
        // if any of the embroidery files have changed, save them first
        // first create a logo copy with all the embroidery files
        const logoWithEmbroideryFiles = {...editedLogo} as ILogoInfos;
        let newEmbroideryFiles = false;
        ['pdf', 'emb', 'dst'].forEach((ext) => {
          const file = embroideryFiles.find((file) => file.extension === ext)?.file;
          if (file) {
            // @ts-ignore
            logoWithEmbroideryFiles[(ext + 'File') as keyof ILogoInfos] = file;
            newEmbroideryFiles = true;
          }
        });

        // if any file was added
        if (newEmbroideryFiles) {
          saveEmbLogoFiles(logoWithEmbroideryFiles);
        } else {
          doSaveLogoInfosDirectly();
        }

      } else {
        dispatch(openSnackBar({severity: 'error', message: intl.formatMessage({ id: "logos.image-error"})}));
      }

    } else {
      // console.log("Cannot save logo without name")
      setNameError(true);
      dispatch(openSnackBar({severity: 'error', message: intl.formatMessage({ id: "logos.name-error"})}));
    }
  }

  async function doSaveLogoInfosDirectly() {

    const response = await dispatch(saveLogo(savedLogo())) as any;

    if (response.type === 'logos/saveLogo/fulfilled') {
      dispatch(openSnackBar({severity: 'success', message: intl.formatMessage({ id: "logos.saved"})}));

      // if the logo is used in any boutique, reload the boutiques
      if (logoIsUsed) {
        dispatch(getBoutiques());
      }
    }

    if (response.type === 'logos/saveLogo/rejected') {
      dispatch(openSnackBar({severity: 'error', message: intl.formatMessage({ id: "logos.not-saved"}), noAutoClose: true}));
    }
  }

  async function saveEmbLogoFiles(logoInfos: ILogoInfos) {

    const response = await postLogoFile(logoInfos);

    if (response.error) {
      console.log("Error saving logo infos: " + response.error);
    } else {
      // console.log("%cLogo infos saved: ", 'color:red', response);
      // save the new logo into the store
      setEmbroideryFiles(originalEmbroideryFiles(response));
      dispatch(editLogo(response));
    }
  }

  // saving to local state the selected files
  interface IEmbroideryFiles {
    extension: string;
    file: File | undefined;
    originalFileName: string;
  }

  const originalEmbroideryFiles = (logo: ILogoInfos | null) => ['pdf', 'emb', 'dst'].map((ext) => (
    {
      extension: ext,
      file: undefined,
      originalFileName: logo ? logo[(ext + 'FileName') as keyof ILogoInfos] : ''
    } as IEmbroideryFiles));

  const [embroideryFiles, setEmbroideryFiles] = useState<IEmbroideryFiles[]>(originalEmbroideryFiles(editedLogo));

  useEffect(() => {
    if (editedLogo) {
      // console.log("Resetting embroidery files");
      // setEmbroideryFiles(originalEmbroideryFiles);
    }
  }, [editedLogo]);

  const saveEmbroideryFile = (field: string, value: File|null) => {
    console.log("setFile with field " + field + " and value " + value);

    // save the file to the local state
    if (value) {
      // setEmbroideryFiles([...embroideryFiles || [], {extension: field.substring(0,3), file: value}]);
      setEmbroideryFiles(embroideryFiles.map((file) => {
        if (file.extension === field.substring(0,3)) {
          return {extension: file.extension, file: value, originalFileName: file.originalFileName}
        } else {
          return file
        }
      }));

      // save the file name to the editedLogo
      const fieldName = field + 'Name';
      setInfos(fieldName, value.name);
    }
  }

  const removeEmbroideryFile = (field: string) => {
    console.log("removeFile with field " + field);

    // remove the file from the local state
    setEmbroideryFiles(embroideryFiles.map((file) => {
      if (file.extension === field.substring(0,3)) {
        return {extension: file.extension, file: undefined, originalFileName: file.originalFileName}
      } else {
        return file
      }}));

    // remove the file name from the editedLogo
    const fieldName = field + 'Name';
    setInfos(fieldName, null);
  }

  const handleCancel = () => {
    dispatch(editLogo(null))
  }

  const handleDelete = () => {
    if (editedLogo && canDeleteLogo) {
      dispatch(openConfirmDialog({
        title: intl.formatMessage({ id: "logos.delete-title"}, {name: editedLogo.name}),
        message: intl.formatMessage({ id: "logos.delete-question"}),
        confirm: intl.formatMessage({ id: "logos.delete-confirm"}),
        actionMethod: 'removeLogo',
        actionParam: editedLogo.id,
        }
      ));
    }
  }

  const logoActions = () => {
    if (logoChanged()) {
      return (
        <div className="save-actions">
          <CustomButton onClick={handleCancel}>
            <FormattedMessage id="global.cancel" />
          </CustomButton>
          <Button variant="contained" color="success" onClick={handleSave} disabled={!saveable}>
            {saving && <CircularProgress size={24} color='success' sx={{mr:1}} />}
            <FormattedMessage id="global.save" />
          </Button>
        </div>
      )

    } else {
      return (
        <div className="save-actions">
          {editedLogo && editedLogo.id !== 0 && canDeleteLogo &&
            <BoudaToolTip key={`remove-logo-${editedLogo.id}`} placement="bottom-end" sx={{textAlign: 'center'}}
                          title={logoIsUsed ? intl.formatMessage({id: "logos.non-deletable"}) : ''} arrow >
              <span>
                <Button disabled={!!logoIsUsed} variant="contained" color="error" onClick={handleDelete} autoFocus>
                  <FormattedMessage id="global.delete" />
                </Button>
              </span>
            </BoudaToolTip>
          }
          <CustomButton onClick={handleCancel}>
            <FormattedMessage id="global.close" />
          </CustomButton>
        </div>
      )
    }
  }

  const logImageURL = (url:string) => {
    // console.log(timestamp + " %cRendering with image URL => " + url, "color:black;background-color:lightblue");
    return true;
  }

  // console.log('Render LogoForm with logoImageChanged=' + logoImageChanged + ' and editedLogo.id = ' + JSON.stringify(editedLogo));

  const hasError = (errors: ErrorsType) =>
    errors !== null && (errors.acceptType || errors.maxFileSize || errors.resolution)

  const qualityOK = editedLogo && Math.max(editedLogo.pixelsWidth, editedLogo.pixelsHeight) >= 3500 &&
    editedLogo.lowestResolution >= 299;

  const logoBgClass = editedLogo?.forDarkBg === 2 ? '' : editedLogo?.forDarkBg === 1 ? 'dark-bg' : 'light-bg';

  return (
    editedLogo &&
      <div className="logo-form">
        <div className='name'>
          {!saving && editable && <span id='logo-name'>
	          <InlineEdit key={`logo-${editedLogo.id}-name`} text={editedLogoName} errorMode={nameError}
	                      typeFilterRegex={NAME_FILTER_REGEX}
	                      validationRegex={NAME_VALIDATION_REGEX}
                        onSetText={(value: string) => setInfos('name', value)} /></span>
            || <>{editedLogoName} {!editable && <span className='admin-managed'>({intl.formatMessage({id: "logos.admin-managed"})})</span>}</>
          }
        </div>
        <div className='logo-edit'>
          <ImageUploading
            value={images}
            onChange={onChange}
            maxNumber={1}
            maxFileSize={MAX_LOGO_FILE_SIZE}
            acceptType={['png']}
          >
            {({
                imageList,
                onImageUpload,
                errors,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps
              }) => (<>
              <Grid container spacing={1} className='logos'>

                <Grid item xs={12} sm={6} className='image-wrapper'>

                  {!!editedLogo?.id && editedLogo.technique === TECHNIQUE_DTF && editedLogo.logoUrl &&
                    <Box id='logo-quality' className='image-stats'>
                      <Box>
                        <FormattedMessage id="global.dimensions" /> {editedLogo.pixelsWidth}x{editedLogo.pixelsHeight} pixels - <FormattedMessage id="global.resolution" /> {editedLogo.resolution}
                      </Box>

                      { // Si la plus grande dimension est inférieure à 3500 pixels, on affiche un message d'erreur
                        Math.max(editedLogo.pixelsWidth, editedLogo.pixelsHeight) < 3500 &&
                          <Box className='error'>
                            <ErrorIcon fontSize={'small'} sx={{color: 'red', mr: '4px'}}/>
                            <Box sx={{textAlign: 'left'}}><FormattedMessage id="logos.low-dimensions" /></Box>
                          </Box> || <Box className='valid'>
                            <CheckCircleIcon fontSize={'small'} sx={{color: 'green', mr: '4px'}}/>
                            <Box sx={{textAlign: 'left'}}><FormattedMessage id="logos.dimensions-ok" /></Box>
                          </Box>
                      }

                      { // Si la resolution est inférieure à 300 dpi, on affiche un message d'erreur
                        editedLogo.lowestResolution < 299 &&
                          <Box className='error'>
                            <ErrorIcon fontSize={'small'} sx={{color: 'red', mr: '4px'}}/>
                            <Box sx={{textAlign: 'left'}}><FormattedMessage id="logos.low-resolution" /></Box>
                          </Box> || <Box className='valid'>
                            <CheckCircleIcon fontSize={'small'} sx={{color: 'green', mr: '4px'}}/>
                            <Box sx={{textAlign: 'left'}}><FormattedMessage id="logos.resolution-ok" /></Box>
                        </Box>
                      }
                    </Box>
                  }

                  { editedLogo.automated && <div className='open-file-explanation'>
		                <TransitEnterexitIcon /> <FormattedMessage id="logos.open-file-explanation" />
	                </div>}

                  <div className={`dropzone ${logoBgClass}`}>
                    {(imageList.length == 0 || hasError(errors)) &&
                      <button className='load-logo' style={isDragging ? {backgroundColor: "#ddffdd"} : undefined}
                              onClick={onImageUpload} {...dragProps} disabled={saving}>
                        {hasError(errors) &&
                          <div className='errors'>
                            {errors?.acceptType && <span><FormattedMessage id="logos.type-not-allowed" /></span>}
                            {errors?.maxFileSize && <span><FormattedMessage id="logos.max-file-size" values={{size: MAX_LOGO_FILE_SIZE / 1000000}} /></span>}
                            {errors?.resolution && <span><FormattedMessage id="logos.required-resolution" /></span>}
                          </div> || <FormattedMessage id="logos.click-or-drop" />
                        }
                      </button>
                    }
                    { !hasError(errors) &&  <>
                      {imageList.map((image, index) => (
                        <div key={`${editedLogo.id}-${index}`} className='image-item'>
                          {image.dataURL && logImageURL(image.dataURL) && <>
                            <img key={`${editedLogo.id}-${index}-${image.dataURL.slice(-20)}`} src={image.dataURL}/>

                            {editable && <div className="image-change">
                              <IconButton onClick={() => onImageUpdate(index)} disabled={saving} sx={{size: 'small'}}>
                                <ImageSearchIcon/>
                              </IconButton>
                            </div>}

                            <div className="image-help">
                              <IconButton onClick={() => openDocAtAnchor(locale, 'image-format')}>
                                <HelpIcon className="help-icon" sx={{fontSize: 28}}/>
                              </IconButton>
                            </div>

                            {!editedLogo.automated && editable && <div className="image-delete">
                            <IconButton onClick={() => onImageRemove(index)} disabled={saving}
                                        sx={{size: 'small'}}>
                              <DeleteIcon/>
                            </IconButton>
                          </div>}

                          </>
                          }
                          </div>
                            ))}
                        </>}
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} className='logo-versions'>
                  {// Logo in creation
                   editedLogo.id === 0 && logoImageChanged &&
                      <div className='logo-techniques'>
                        <FormControl disabled={saving}>
                          <FormLabel id="technique-radio-buttons-group-label">
                            <FormattedMessage id={"logos.technique"} />
                          </FormLabel>
                          <RadioGroup aria-labelledby="technique-radio-buttons-group-label"
                              value={editedLogo.technique} name="technique-radio-buttons"
                              onChange={(e) => setInfos('technique', e.target.value)}
                          >
                            <FormControlLabel value={TECHNIQUE_DTF} control={<Radio/>} label={intl.formatMessage({ id: "logos.dtf-title"})}/>
                            <div className='techique-description'>
                              <div className='paragraphe'>
                                <FormattedMessage id={"logos.dtf-explanation-1"} />
                              </div>
                              <div className='paragraphe'>
	                              <FormattedMessage id={"logos.dtf-explanation-2"} />
                              </div>
                              <div className='paragraphe'>
	                              <FormattedMessage id={"logos.dtf-explanation-3"} />
                              </div>
                            </div>
                            {impersonating && <>
                              <FormControlLabel value={TECHNIQUE_EBD} control={<Radio/>}
                                                label={intl.formatMessage({ id: "logos.ebd-title"})}/>
                              <div className='techique-description'>
                                <div className='paragraphe'>
                                  <FormattedMessage id={"logos.ebd-explanation-1"} />
                                </div>
                                <div className='paragraphe'>
                                  <FormattedMessage id={"logos.ebd-explanation-2"} />
                                </div>
                                <div className='paragraphe'>
                                  <FormattedMessage id={"logos.ebd-explanation-3"} />
                                </div>
                              </div>
                            </>}
                            {impersonating && <>
                              <FormControlLabel value={TECHNIQUE_STC} control={<Radio/>}
                                                label={intl.formatMessage({ id: "logos.cte-title"})}/>
                              <div className='techique-description'>
                                <div className='paragraphe'>
                                  <FormattedMessage id={"logos.cte-explanation-1"} />
                                </div>
                                <div className='paragraphe'>
                                  <FormattedMessage id={"logos.cte-explanation-2"} />
                                </div>
                              </div>
                            </>}
                          </RadioGroup>
                        </FormControl>
                      </div>
                  }
                  { // Logo already created
                    editedLogo.id !== 0 && <> <Box sx={{display: 'flex', alignItems: 'center'}}>
                      <FormControl id='logo-technique'>
                        <Select autoWidth value={editedLogo.technique} size='small' disabled={!editable}
                                onChange={(e) => {
                                  if (impersonating) {
                                    setInfos('technique', e.target.value)
                                  }
                                }}>
                          <MenuItem value={TECHNIQUE_DTF}><FormattedMessage id={"logos.dtf-selected"} /></MenuItem>
                          {(impersonating || editedLogo.technique === TECHNIQUE_EBD) &&
                              <MenuItem value={TECHNIQUE_EBD}><FormattedMessage id={"logos.ebd-selected"} /></MenuItem>
                          }
                          {(impersonating || editedLogo.technique === TECHNIQUE_STC) &&
                              <MenuItem value={TECHNIQUE_STC}><FormattedMessage id={"logos.cte-selected"} /></MenuItem>
                          }
                        </Select>
                      </FormControl>
                      <Box className="technique-help">
                        <IconButton onClick={() => openDocAtAnchor(locale, anchorNameForTechnique(editedLogo.technique))}>
                          <HelpIcon className="help-icon" sx={{ fontSize: 32 }} />
                        </IconButton>
                      </Box>
                    </Box>
                    {editedLogo.technique === 0 &&
	                    <div className='logo-versions-header'>
                        <FormattedMessage id="logos.calculated-versions"/>
		                    <br/>
		                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
			                    <span className='areas'><FormattedMessage id="logos.dtf-areas"/></span>
			                    <IconButton onClick={() => openDocAtAnchor(locale, 'logo-versions')}>
				                    <HelpIcon className="help-icon" sx={{fontSize: 24}}/>
			                    </IconButton>
		                    </Box>
                      </div>
                    }

                    { /* Table of logo versions */ }
	                  <LogoVersionsCard logo={editedLogo} saving={saving}
                                      superAdmin={!!currentUser?.superAdmin}
                                      impersonating={!!impersonating}/>

                    { // Force redo print png of all versions
                      (impersonating || currentUser?.superAdmin) && <>
	                      <FormControlLabel label={<FormattedMessage id={`logos.recreate-print-pngs`}/>}
                          control={ <Checkbox sx={{'&.MuiCheckbox-root': {color: '#808080'}}} size='small'
                                              disabled={false} checked={!!editedLogo.redoPrintVersions}
                                              onChange={(e) => {
                                                setInfos('redoPrintVersions', e.target.checked);
                                              }} /> } />
                      </>
                    }

                    { // Upload Embroidery files
                    editedLogo.technique === TECHNIQUE_EBD && impersonating && <>
                      <FileUpload key={`upld-pdf-${editedLogo.id}`} extension='pdf' inviteId='logos.upload-embroidery-pdf'
                                  file={embroideryFiles.find((file) => file.extension === 'pdf')?.file}
                                  saveFile={(file: File|null) => saveEmbroideryFile('pdfFile', file)}
                                  removeFile={() => removeEmbroideryFile('pdfFile')}
                                  currentFileName={embroideryFiles.find((file) => file.extension === 'pdf')?.originalFileName || ''}
                      />
                      <FileUpload key={`upld-emb-${editedLogo.id}`} extension='emb' inviteId='logos.upload-embroidery-emb'
                                  file={embroideryFiles?.find((file) => file.extension === 'emb')?.file}
                                  saveFile={(file: File|null) => saveEmbroideryFile('embFile', file)}
			                            removeFile={() => removeEmbroideryFile('embFile')}
			                            currentFileName={embroideryFiles.find((file) => file.extension === 'emb')?.originalFileName || ''}
                      />
                      <FileUpload key={`upld-dst-${editedLogo.id}`} extension='dst' inviteId='logos.upload-embroidery-dst'
                                  file={embroideryFiles?.find((file) => file.extension === 'dst')?.file}
			                            saveFile={(file: File|null) => saveEmbroideryFile('dstFile', file) }
			                            removeFile={() => removeEmbroideryFile('dstFile')}
			                            currentFileName={embroideryFiles.find((file) => file.extension === 'dst')?.originalFileName || ''}
                      />
                      </>
                    }
                  </>}
                </Grid>
              </Grid>

              <Box className='logo-select-bg' >
                {editedLogo.id !== 0 && <div>
                  <SelectLogoBg logo={editedLogo} onSetBg={(newBg: number) => setInfos('forDarkBg', newBg)}
                                editable={editable && (!editedLogo.automated || logoImageChanged)} locale={locale} />
                </div>}
              </Box>

              <Grid container spacing={1} className='logos'>
                {imageList[0] &&
                  <Grid container spacing={1} className='logo-previews'>
                    <Grid item xs={12} sm={6} className='preview-article'>
                      <div className='preview-logo-title'>
                        <FormattedMessage id="logos.rendering-white-bg" />
                      </div>
                      <img src={WhiteBgArticle}/>
                      <div className='logo-overlay'>
                        <img src={imageList[0].dataURL}/>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6} className='preview-article'>
                      <div className='preview-logo-title'>
                        <FormattedMessage id="logos.rendering-dark-bg" />
                      </div>
                      <img src={DarkBgArticle}/>
                      <div className='logo-overlay'>
                        <img src={imageList[0].dataURL}/>
                      </div>
                    </Grid>

                  </Grid>
                }

              </Grid>


              { // Show logo usages
                logoIsUsed && <Box className='logo-usage'>
                  <div id='logo' className='usage-header'>
                    <FormattedMessage id="logos.used-versions" />
                  </div>
                  <div id='logo-usage' className='usage'
                       dangerouslySetInnerHTML={{ __html: logoUsageExplanationHTML(editedLogo) }} />
                </Box>
              }

            </>)}
          </ImageUploading>
        </div>
        {logoActions()}
      </div>
  )

}

export default LogoForm;