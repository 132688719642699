import React, {lazy, useState} from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import {
  GridColDef,
  GridRenderEditCellParams,
  useGridApiContext, GridRowId, GridRowModel, GridRowSelectionModel
} from '@mui/x-data-grid';
import {frFR} from "@mui/x-data-grid/locales";
import {useAppSelector} from "../../../app/hooks";
import {
  CatalogStateSelector,
  fetchProductDefinition, setProductsProperty, updateProductsProperty
} from "../../catalog/catalogSlice";
import {StripedDataGrid} from "../../utils/StrippedDataGrid";
import {PriceFormatted} from "../../../app/product";
import {FormattedMessage, useIntl} from "react-intl";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {CatalogCategories, ProductStyles} from "../../../app/catalog";
import {IProductPropertyUpdate, updateProductPropertyRequest} from "../../../app/productAPI";
import {openConfirmDialog, openSnackBar} from "../../global/globalSlice";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import HideSourceIcon from '@mui/icons-material/HideSource';
import DeleteIcon from '@mui/icons-material/Delete';
import {BoudaToolTip} from "../../utils/BoudaToolTip";
import {getFirstAndLastNElements} from "../../../app/utils";

const updatableProperties = ['title', 'category', 'style'];

export default function ProductsTable() {

  const intl = useIntl();
  const dispatch = useDispatch();

  const catalogState = useAppSelector(CatalogStateSelector);
  let products = catalogState.filteredProducts;

  // console.log("Render ProductsTable with currentCategory: ", catalogState.currentCategory);
  if (catalogState.currentCategory !== 'all') {
    products = products.filter((p) => p.category === catalogState.currentCategory)
  }

  const [selection, setSelection] = useState<GridRowSelectionModel>([]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      headerClassName: 'catalog-header',
      align: 'center',
      width: 80,
      sortable: false,
    },
    {
      field: 'sku',
      headerName: 'SKU',
      headerClassName: 'catalog-header',
      width: 130,
      sortable: true,
    },
    {
      field: 'title',
      headerName: 'Name',
      headerClassName: 'catalog-header',
      flex: 1,
      editable: true,
      renderEditCell: (params) => EditTitle(params)
    },
    {
      field: 'boutiques',
      headerName: 'Btqs',
      headerClassName: 'catalog-header',
      align: 'center',
      width: 50,
      editable: false,
      renderCell: (params) => {
        if (params.value.length === 0) {
          return '';
        } else {
          const displayedBoutiques = getFirstAndLastNElements(params.value, 15, '------------------');

          return (
            <BoudaToolTip placement="right"
              title={<div style={{ whiteSpace: 'pre-line' }}>{displayedBoutiques.join('\n')}</div>}>
              <div>{params.value.length}</div>
            </BoudaToolTip>
          )
        }
      },
    },
    {
      field: 'brand',
      headerClassName: 'catalog-header',
      headerName: 'Brand',
      width: 150
    },
    {
      field: 'category', //
      headerClassName: 'catalog-header',
      headerName: 'Category',
      editable: true,
      width: 150,
      renderCell: (params) => {
        return <FormattedMessage id={`category.${params.value}`} />;
      },
      renderEditCell: (params) => EditCategory(params)
    },
    {
      field: 'style',
      headerClassName: 'catalog-header',
      headerName: 'Style',
      editable: true,
      width: 150,
      renderCell: (params) => {
        return(
          <div className='admin-style'>
            <div>{params.value}</div>
            <div className='fabric'>{params.row.fabric}</div>
          </div>
        );
      },
      renderEditCell: (params) => EditStyle(params)
    },
    {
      field: 'price',
      headerClassName: 'catalog-header',
      headerName: 'Price',
      width: 70
    },
    {
      field: "action",
      headerClassName: 'catalog-header',
      headerName: "Action",
      sortable: false,
      width: 120,
      renderCell: (params) => {
        const onOpen = (e: any) => {
          e.stopPropagation(); // don't select this row after clicking
          dispatch(fetchProductDefinition({id: params.row.id}));
        };

        const onHide = (e: any) => {
          e.stopPropagation(); // don't select this row after clicking

          const updateProperty = {
            productIds: selectedProductIds(params.row.id),
            propertyName: 'withdraw',
            propertyValue: 'true',
          } as IProductPropertyUpdate;

          dispatch(openConfirmDialog({
            title: intl.formatMessage({ id: "admin.withdraw-confirm-title"}),
            messageHTML: intl.formatMessage(
              { id: "admin.withdraw-confirm-question"},
              { products: `<ul>${updateProperty.productIds.map(
                    (id) => `<li key="upypi${id}">${id} - ${catalogState.filteredProducts.find((p) => p.id === id)?.title}</li>`
                  ).join('')}</ul>`
              }),
            confirm: intl.formatMessage({ id: "admin.withdraw-confirm"}),
            actionMethod: 'updateProductsProperty',
            actionParam: updateProperty
          }));
        };

        const onDelete = (e: any) => {
          e.stopPropagation(); // don't select this row after clicking

          const updateProperty = {
            productIds: selectedProductIds(params.row.id),
            propertyName: 'delete',
            propertyValue: 'true',
          } as IProductPropertyUpdate;

          dispatch(openConfirmDialog({
            title: intl.formatMessage({ id: "admin.delete-confirm-title"}),
            messageHTML: intl.formatMessage(
              { id: "admin.delete-confirm-question"},
              { products: `<ul>${updateProperty.productIds.map(
                  (id) => `<li key="upypi${id}">${id} - ${catalogState.filteredProducts.find((p) => p.id === id)?.title}</li>`
                ).join('')}</ul>`
              }),
            confirm: intl.formatMessage({ id: "admin.delete-confirm"}),
            actionMethod: 'updateProductsProperty',
            actionParam: updateProperty
          }));
        };

        return (
          <>
            <OpenInNewIcon className='admin-action-icon' onClick={onOpen} />
            <HideSourceIcon className='admin-action-icon' onClick={onHide} />
            <DeleteIcon className='admin-action-icon' onClick={onDelete} />
          </>
        )
      }
    },

    // If we need to make a virtual value calculated
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params: GridValueGetterParams) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
  ];

  const selectedProductIds = (mainProductId: GridRowId) => {

    // console.log("Calling selectedProductIds: ", mainProductId, selection);
    const selectedIds = [mainProductId].concat(selection);

    return selectedIds.filter((e, index) => selectedIds.indexOf(e) === index);
  }

  const EditTitle = (props: GridRenderEditCellParams) => {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return <input type="text" value={value} onChange={handleValueChange} style={{width: '100%', padding: 10, border: 'none'}} />;
  }

  const EditCategory = (props: GridRenderEditCellParams) => {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (event: SelectChangeEvent) => {
      const newValue = event.target.value; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return(
      <Select value={value} onChange={handleValueChange}>
        {CatalogCategories.map((category, i) => (
          <MenuItem key={`category${i+1}`} value={category.code}><FormattedMessage id={`category.${category.code}`} /></MenuItem>
        ))}
      </Select>
    )
  }

  const EditStyle = (props: GridRenderEditCellParams) => {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (event: SelectChangeEvent) => {
      const newValue = event.target.value; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs: 300 });
    };

    return(
      <Select value={value || ''} onChange={handleValueChange}>
        {ProductStyles.map((style,i) => (
          <MenuItem key={`style${i+1}`} value={style}>{style}</MenuItem>
        ))}
      </Select>
    )
  }


  const rows = () => products.map((product, index) => (
    {
      id: product.id,
      sku: product.sku,
      title: product.title,
      boutiques: product.boutiques,
      brand: product.brand,
      category: product.category,
      style: product.style,
      fabric: product.fabric,
      price: PriceFormatted(product.prices[0])
    }));

  const processRowUpdate = React.useCallback(

    async (newRow: GridRowModel, oldRow: GridRowModel) => {
      const changedProperty = updatableProperties.find((property) => {
        return newRow[property] !== oldRow[property];
      });
      const changedPropertyValue = changedProperty ? newRow[changedProperty] : null;

      if (changedPropertyValue) {
        const updateProperty = {
          productIds: selectedProductIds(newRow.id),
          propertyName: changedProperty,
          propertyValue: changedPropertyValue
        } as IProductPropertyUpdate;

        const response = await updateProductPropertyRequest(updateProperty);

        if (response.error) {
          dispatch(openSnackBar({severity: 'error', message: response.error}));
          return oldRow;
        } else {
          // Update the product property in the store
          dispatch(setProductsProperty({updatedProperty: updateProperty}))
          dispatch(openSnackBar({severity: 'success', message: "Product successfully saved"}));
          return newRow;
        }
      } else {
        return oldRow;
      }
    },
    [updateProductPropertyRequest, selection],
   );

  const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    dispatch(openSnackBar({severity: 'error', message: "Product not saved"}));
  }, []);


  return (
    <Box
      sx={{
        height: 'calc(100vh - 238px)',
        width: '100%',
        '& .catalog-header': {
          backgroundColor: '#808080',
          color: 'white',
          fontWeight: 'bold',
          border: '1px solid #FFFFFF',
        },
      }}
    >
      <StripedDataGrid
        rows={rows()} columns={columns} loading={false} checkboxSelection disableRowSelectionOnClick
        onRowSelectionModelChange={(selectionModel, details) => {
         // console.log("Setting the selection value to: ", selectionModel);
         setSelection(selectionModel);
        }}
        processRowUpdate={processRowUpdate} onProcessRowUpdateError={handleProcessRowUpdateError}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
      />
    </Box>
  );
}


