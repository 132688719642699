import React from "react";

const PoloSvg = (height: string) => {

  return (
    <svg version="1.1" viewBox="0 0 64 64" style={{'height': height}}>
      <g className="icon">
        <path d="M14.1,30.6C14.1,30.6,14.1,30.6,14.1,30.6c0.7-7,0.8-14.9-1.1-20.6c-0.2-0.6-0.4-1.2-0.7-1.7
				c-2.6,1.5-4.4,4-5,7l-3,14.8l0.1,0l0.1,0l9.2,1.9L14.1,30.6L14.1,30.6z"/>
        <polygon points="29.9,6.5 30.2,6.8 32,9.2 33.9,6.8 34.1,6.5 34.2,6.4 36.1,4 36.4,3.6 27.7,3.6 28,4 29.9,6.4
				29.9,6.5 			"/>
        <path d="M49.2,8.9c0.2-0.5,0.4-1,0.6-1.5l-1.5-0.6l-2.9-1.2l-0.9-0.4l-0.1,0c0,0,0,0,0,0l0,0
				c0.4,0.8,0.5,1.6,0.2,2.5l-0.1,0.4l-2.8,9.9c-0.4,1.5-1.8,2.5-3.4,2.5c-0.7,0-1.5-0.2-2.1-0.7L32,16.8l-4.2,3.1
				c-0.6,0.4-1.3,0.7-2.1,0.7c-1.6,0-2.9-1-3.4-2.5l-2.8-10l-0.1-0.3c-0.2-0.8-0.2-1.7,0.2-2.5l-0.1,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0
				l-1,0.4l-2.9,1.2l-1.4,0.6c0.2,0.5,0.4,0.9,0.6,1.5c1,2.8,1.6,6.4,1.8,10.5c0.2,4.5-0.1,9.7-1,15.6v23.5c0,1.4,1.1,2.5,2.5,2.5
				h27.8c1.4,0,2.5-1.1,2.5-2.5V35.2c-0.9-5.9-1.2-11.2-1-15.8C47.6,15.3,48.2,11.8,49.2,8.9L49.2,8.9z"/>
        <path d="M56.6,15.3c-0.6-2.9-2.4-5.5-5-7c-0.2,0.5-0.4,1-0.6,1.6c-1.2,3.5-2.2,9.8-1.1,20.5c0,0,0,0,0,0l0.5,1.6
				l9.3-2c0,0,0,0,0,0L56.6,15.3L56.6,15.3z"/>
        <path d="M25.3,4.2l-0.1-0.2c-0.2-0.2-0.4-0.4-0.7-0.4c-0.5-0.1-1,0.1-1.3,0.5l-0.4,0.6h0l-1,1.5
				c-0.2,0.3-0.3,0.7-0.2,1l2.9,10.3c0.2,0.8,1.2,1.1,1.9,0.6l3.8-2.8l1.3-0.9c0.3-0.2,0.4-0.5,0.5-0.8c0,0.3,0.2,0.6,0.5,0.8
				l1.3,0.9l3.8,2.8c0.7,0.5,1.6,0.2,1.9-0.6l2.9-10.3c0.1-0.3,0-0.7-0.2-1l-1-1.4l-0.4-0.6c-0.5-0.7-1.4-0.7-1.9-0.1l-0.2,0.2l0,0
				v0l-1.7,2.2l-3.6,4.6l-1.2,1.6c-0.1,0.2-0.2,0.3-0.2,0.5c0-0.2-0.1-0.4-0.2-0.5l-1.2-1.6L27,6.5L25.3,4.2L25.3,4.2z"/>
      </g>
    </svg>
  );
}


export default PoloSvg;

