import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import "@fontsource/roboto-condensed/300.css";
import "@fontsource/roboto-condensed/400.css";
import "@fontsource/roboto-condensed/700.css";

import './styles/index.scss';
import './styles/app.scss';
import './styles/fonts.css';
import './styles/catalog.scss';
import './styles/product.scss';
import './features/selection/selection.scss';
import './styles/inline_edit.scss';
import LocaleWrapper from "./features/locale/LocaleWrapper";
import {HelmetProvider} from "react-helmet-async";

const container = document.getElementById('root')!;
const root = createRoot(container);

// add a tag in the head of the document

//
// const loadFacebookSDK = () => {
//   const script = document.createElement('script');
//   script.async = true;
//   script.defer = true;
//   script.crossOrigin = 'anonymous';
//   script.src = 'https://connect.facebook.net/fr_FR/sdk.js#xfbml=1&version=v12.0';
//   script.nonce = 'aOgaVGo5'; // Add the nonce to the script tag
//   document.body.appendChild(script);
// };
//
// loadFacebookSDK();


(window as any).fbAsyncInit = function () {
  if ((window as any).FB) {
    (window as any).FB.init({
      xfbml: true,
      version: 'v12.0'
    });
  }
};

const loadFacebookSDK = () => {
  // Check if the SDK has already been loaded.
  if (document.getElementById('facebook-jssdk')) return;

  const script = document.createElement('script');
  script.id = 'facebook-jssdk';
  script.async = true;
  script.defer = true;
  script.crossOrigin = 'anonymous';
  // Remove the query string parameters so that FB.init is called from fbAsyncInit.
  script.src = 'https://connect.facebook.net/fr_FR/sdk.js';
  script.nonce = 'aOgaVGo5'; // your nonce value, if required
  document.body.appendChild(script);
};

loadFacebookSDK();

root.render(
  // <React.StrictMode> QR code not working in strict mode
    <HelmetProvider>
      <Provider store={store}>
        <LocaleWrapper>
          <App />
        </LocaleWrapper>
      </Provider>
    </HelmetProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
