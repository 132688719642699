import SearchIcon from "@mui/icons-material/Search";
import * as React from "react";
import {alpha, styled} from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import {ChangeEvent, memo, useCallback, useState} from "react";
import {filterProducts} from "./catalogSlice";
import {filterProduction} from "../admin/production/productionSlice";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../app/store";
import {useIntl} from "react-intl";
import {debounce} from "lodash";

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  // marginRight: theme.spacing(2),
  marginRight: 'clamp(0.3rem, 1vw, 2rem)',
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    // marginLeft: theme.spacing(3),
    marginLeft: 'clamp(0.3rem, 1vw, 2rem)',
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1rem + ${theme.spacing(3)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '16ch',
    },
  },
}));


const SearchCatalog = () => {

  const intl = useIntl();
  const dispatch = useDispatch<AppDispatch>();

  const currentUser = useSelector((state : RootState) => state.session.currentUser);
  const loggedUser = currentUser && currentUser.id !== undefined;
  const userProduction = loggedUser && currentUser.production

  // const currentFilter = useAppSelector(currentFilterKey);
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedDispatch = useCallback(
    debounce((value) => {
      dispatch(filterProducts(value));
      if (userProduction) {
        dispatch(filterProduction(value))
      }
    }, 500),
    [dispatch]
  );

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;
    setSearchTerm(search);

    if (search !== null && (search.length > 2 || search.length === 0)) {
      debouncedDispatch(search);
      // dispatch(filterProducts(search))
      // if (userProduction) {
      //   dispatch(filterProduction(search))
      // }
    }

  };

  return(
    <Search>
      <SearchIconWrapper>
        <SearchIcon/>
      </SearchIconWrapper>
      <StyledInputBase
        key='search-catalog'
        // autoFocus
        placeholder={`${intl.formatMessage({id: `global.search`})} ...`}
        inputProps={{'aria-label': 'search'}}
        onChange={handleSearchChange}
        // onKeyDown={handleSearchKeyDown}
        value={searchTerm}
      />
    </Search>
  );
};

export default SearchCatalog;
