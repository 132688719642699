import {createRef, ReactElement, ReactNode, useEffect} from 'react';
import {PlacesType, TooltipRefProps} from 'react-tooltip';
import { annotate } from 'rough-notation';
import UserHelpTipContent from "../features/utils/UserHelpTipContent";
import {IntlShape} from "react-intl";
import {RoughAnnotation, RoughAnnotationType} from "rough-notation/lib/model";
import {useSelector} from "react-redux";
import {RootState} from "./store";
// @ts-ignore
import variables from "../styles/_variables.scss";

// Create a ref to the tooltip component
const helpToolTip = createRef<TooltipRefProps>();
export default helpToolTip;

let annotation: RoughAnnotation | null = null;

export const useHelpTips = (component: string, intl: IntlShape, dispatch: Function) => {

  const helpItem = useSelector((state: RootState) =>
    state.session.currentUser?.helpItems.find((hi) => hi.component === component));

  useEffect(() => {
    if (helpToolTip.current && helpItem) {

      // First close any open tooltip
      helpToolTip.current?.close()

      const pausedUntil = localStorage.getItem('helpTipPausedUntil');
      if (pausedUntil && Date.now() < parseInt(pausedUntil, 10)) {
        // Pause is active, do not show help tips
        return;
      }

      // first scroll into view the logo-background div
      const logoBg = document.getElementById(helpItem.anchor);
      if (logoBg) {
        logoBg.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});

        if (annotation) {
          annotation.hide();
          annotation = null;
        }
        annotation = annotate(logoBg, {
          type: helpItem.rough as RoughAnnotationType,
          animationDuration: 1200,
          color: variables.helpTipBgTransp,
          padding: 2,
          strokeWidth: 2,
        });
        annotation.show();
      }

      // Add a slight delay to ensure the element is fully rendered
      // and the rough-notation is applied
      // and the user has time to see the scroll
      setTimeout(() => {
        helpToolTip.current?.open({
          anchorSelect: '#' + helpItem.anchor,
          content: UserHelpTipContent({
            component: helpItem.component,
            anchor: helpItem.anchor,
            messageKey: helpItem.messageKey,
            intl: intl,
            dispatch: dispatch
          }),
          place: helpItem.placement as PlacesType,
        });
      }, 700);

    } else {
      if (annotation) {
        annotation.hide();
        annotation = null;
      }
      helpToolTip.current?.close({delay: 200})
    }
  }, [helpToolTip, helpItem]);
};
