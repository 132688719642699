import './website.scss';
import React, {useContext, useEffect, useLayoutEffect, useState} from "react";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import Footer from "./Footer";
import MarkdownDocumentation from "../utils/MarkdownDocumentation";
import {RootState} from "../../app/store";
import {impersonatingUser, ROLE_BOUTIQUE} from "../sessions/sessionSlice";
import SEO from "./SEO";
import { useSelector } from "react-redux";
import { getH1Headers } from "../../app/markdown";

const HelpPage = (props: {doc: string}) => {
  const {doc} = props;
  const intl = useIntl();
  const currentUser = useSelector((state: RootState) => state.session.currentUser);
  const loggedUser = currentUser && currentUser.id !== undefined;
  const userBoutique = loggedUser && currentUser.role !== undefined &&
    (currentUser.role >= ROLE_BOUTIQUE || impersonatingUser(currentUser));

  return (
    <>
      <SEO
        title={`DAGOBA - ${intl.formatMessage({id: 'help-page-title'})}`}
        description={getH1Headers(doc)}
      />
      <div className='website'>
        <Box className='section help-center'>
          <h1 className='title'>
            {intl.formatMessage({id: 'help-page-title'})}
          </h1>
          <MarkdownDocumentation intl={intl} markdownContent={doc} allowPrivateContent={userBoutique || false} />
        </Box>
        <Footer intl={intl} />
      </div>
    </>
  );
};

export default HelpPage;
