import TShirtSvg from "../features/catalog/category/TShirtSvg"
import HoodieSvg from "../features/catalog/category/HoodieSvg"
import JacketSvg from "../features/catalog/category/JacketSvg"
import SweaterSvg from "../features/catalog/category/SweaterSvg";
import PoloSvg from "../features/catalog/category/PoloSvg";
import SweatsuitSvg from "../features/catalog/category/SweatsuitSvg";
import PantsSvg from "../features/catalog/category/PantsSvg";
import CoatSvg from "../features/catalog/category/CoatSvg";
import JerseySvg from "../features/catalog/category/JerseySvg";
import Headwear from "../features/catalog/category/Headwear";
import KeikoSvg from "../features/catalog/category/KeikoSvg";
import BagSvg from "../features/catalog/category/BagSvg";
import {IProduct, IVariantInfos} from "./product";
import TankTopSvg from "../features/catalog/category/TankTopSvg";
import ShirtSvg from "../features/catalog/category/ShirtSvg";
import AccessorySvg from "../features/catalog/category/AccessorySvg";
import ShortsSvg from "../features/catalog/category/ShortsSvg";
import {colorProximity} from "./color";
import exp from "node:constants";


export enum Statuses {
  Initial = "Not Fetched",
  Loading = "Loading Catalog",
  UpToDate = "Up To Date",
  Deleted = "Deleted",
  Error = "Error"
}

export interface ICategoryInfos {
  code: string;
  status: Statuses;
}

export interface ICategoryState {
  category: string;
  products: IProduct[];
  status: Statuses;
}

export interface ICatalogCategory {
  // name: string;
  code: string;
  icon: Function;
}

export const CatalogCategories: ICatalogCategory[] = [
  {
    // name: "T-Shirt",
    code: "tshirts",
    icon: TShirtSvg
  },
  {
    // name: "Débardeur",
    code: "tanktops",
    icon: TankTopSvg
  },
  {
    // name: "Polos",
    code: "polos",
    icon: PoloSvg
  },
  {
    // name: "Sweaters",
    code: "sweaters",
    icon: HoodieSvg
  },
  // {
  //   name: "Hoodies",
  //   code: "hoodies",
  //   icon: HoodieSvg
  // },
  {
    // name: "Jackets",
    code: "jackets",
    icon: JacketSvg
  },
  {
    // name: "Coats",
    code: "coats",
    icon: CoatSvg
  },
  {
    // name: "Sweatsuits",
    code: "sweatsuits",
    icon: SweatsuitSvg
  },
  {
    // name: "Pants",
    code: "shorts",
    icon: ShortsSvg
  },
  {
    // name: "Pants",
    code: "pants",
    icon: PantsSvg
  },
  // {
  //   name: "Jerseys",
  //   code: "jerseys",
  //   icon: JerseySvg
  // },
  {
    // name: "Shirts",
    code: "shirts",
    icon: ShirtSvg
  },
  {
    // name: "Keikos",
    code: "martial",
    icon: KeikoSvg
  },
  {
    // name: "Headwear",
    code: "headwear",
    icon: Headwear
  },
  {
    // name: "Bags",
    code: "bags",
    icon: BagSvg
  },
  {
    // name: "Bags",
    code: "accessories",
    icon: AccessorySvg
  },
];

export enum ColorTypes{
  Primary = "Primary",
  Secondary = "Secondary",
}

// to be copied into app/models/dagoba/product.rb
export const ProductBrands = [
  // 'Eldera',
  'Erima', 'Errea', 'Joma', 'Mizuno Martial', 'Mizuno Team',
  // 'Asquith & Fox', 'Atlantis',
  'AWDis Just Cool', 'AWDis Just Hoods', 'AWDis Just Polos', 'AWDis Just Ts',
  'B&C', //'Bella+Canvas',
  'Fruit of the Loom',
  'Gildan',
  // 'James&Nicholson',
  'Kariban',
  'Regatta', 'Result', 'Russell',
  // 'SOL´S',
  'Skinnifit', 'StormTech',
  // 'Tombo',
  'ProAct', 'Spiro',
  'BagBase', 'Beechfield',
  // 'Black&Match',
  'Quadra', 'Towel City', 'Westford Mill',
]

export const ProductStyles = ['Lifestyle', 'Sportswear', 'Accessoire']

 // { :name => 'Small Text Above Logo Right Thigh',:type => 'Text',:position => 'thighright-above', :exclude => ''},

export const ProductPersonalizations = [
  'small-text-logo-right',
  'small-text-logo-left',
  'big-text-top-back',
  'big-text-middle-back',
  'big-number-chest',
  'big-number-chest-2',

  'separator',

  'small-text-logo-right-thigh',
  'small-text-under-logo-right-thigh',
  'small-text-above-logo-right-thigh',
  'big-number-right-thigh',

  'separator',

  'small-text-logo-left-thigh',
  'small-text-under-logo-left-thigh',
  'small-text-above-logo-left-thigh',
  'big-number-left-thigh',

  'separator',

  'small-text-bag',
  'small-text-bag-under-logo',
  'big-text-bag',

  'separator',

  'small-text-logo-right-2',
  'small-text-logo-left-2',
  'big-text-top-back-2',
  'big-text-middle-back-2',
]

export const MAX_HEAWEAR_LOGO_HEIGHT = 50;
export const MAX_HEAWEAR_LOGO_WIDTH = 90;


export interface IProductImageInfos {
  name: string;
  url: string;
  delete: boolean;
  side?: string;
  replace?: boolean;
  replacementName?: string;
  replacementUrl?: string;
  imgBase64?: string | null;
}

export interface IProductSizeDefinition {
  supplierSize: string;
  dagobaSize: string;
  height: number;
  priceCents: number | null;
  warning?: boolean | null;
  add?: boolean | null;
}

export interface IProductColorDefinition {
  supplierColor: string;
  dagobaColor: string;
  hexColor1: string;
  hexColor2: string;
  color1: string;
  color2: string;
  discontinued: boolean | null;
  extra: boolean | null;
  missing: boolean | null;
  images: IProductImageInfos[];
  colorCode: string | null;
  colorSku: string | null;
  backTemplateImage: string | null;
  rightTemplateImage: string | null;
  leftTemplateImage: string | null;
  add: boolean | null;
  remove: boolean | null;
}

export interface IProductGender {
  name: string;
  code: number;
}

export const GENDER_NONE = -1;
export const GENDER_UNISEX = 0;
export const GENDER_MAN = 1;
export const GENDER_WOMAN = 2;
export const GENDER_CHILD = 3;


export const ProductGenders: IProductGender[] = [
  {name: "None", code: GENDER_NONE},
  {name: "Unisex", code: GENDER_UNISEX},
  {name: "Male", code: GENDER_MAN},
  {name: "Female", code: GENDER_WOMAN},
  {name: "Child", code: GENDER_CHILD},
]

export  interface IProductSearch {
  id?: number | null;
  sku?: string | null;
  brand?: string | null;
  title?: string | null;
  gender?: number;
}

export interface ISizeGuideSnippet {
  id: number;
  title: string;
  handle: string;
}

interface IProductImageTemplate {
  gender: number;
  side: string;
  name: string;
}

export const ProductImageTemplates = [
  {gender: GENDER_UNISEX, side: 'back', name: 'T-Shirt-MC'},
  {gender: GENDER_UNISEX, side: 'right', name: 'T-Shirt-MC'},
  {gender: GENDER_UNISEX, side: 'left', name: 'T-Shirt-MC'},
  {gender: GENDER_UNISEX, side: 'back', name: 'T-Shirt-ML'},
  {gender: GENDER_UNISEX, side: 'right', name: 'T-Shirt-ML'},
  {gender: GENDER_UNISEX, side: 'left', name: 'T-Shirt-ML'},
  {gender: GENDER_UNISEX, side: 'back', name: 'Hoodie-Man'},
  {gender: GENDER_UNISEX, side: 'back', name: 'Tanktop-Man'},
  {gender: GENDER_UNISEX, side: 'back', name: 'Basket-Man'},
  {gender: GENDER_UNISEX, side: 'back', name: 'TS-Short-Man'},

  {gender: GENDER_WOMAN, side: 'back',  name: 'TS-Fem-MC'},
  {gender: GENDER_WOMAN, side: 'right', name: 'TS-Fem-MC'},
  {gender: GENDER_WOMAN, side: 'left', name: 'TS-Fem-MC'},
  {gender: GENDER_WOMAN, side: 'back',  name: 'TS-Fem-ML'},
  {gender: GENDER_WOMAN, side: 'right', name: 'TS-Fem-ML'},
  {gender: GENDER_WOMAN, side: 'left', name: 'TS-Fem-ML'},
  {gender: GENDER_WOMAN, side: 'back', name: 'Hoodie-Fem'},
  {gender: GENDER_WOMAN, side: 'back', name: 'Tanktop-Fem'},
  {gender: GENDER_WOMAN, side: 'back', name: 'Basket-Fem'},
  {gender: GENDER_WOMAN, side: 'back', name: 'TS-Short-Fem'},
]

export  interface IProductDefinition {
  source: string;
  id: number | null;
  sku: string | null;
  catalogReady: boolean | null;
  brand: string | null;
  bestSeller: boolean | null;
  style: string | null;
  category: string | null;
  dagobaTitle: string | null;
  supplierTitle: string | null;
  description: string | null;
  dagobaPriceCents: number | null;
  fabric: string | null;
  grammage: string | null;
  weight: number | null;
  gender: number;
  minQuantity: number;
  cannotSellAlone: boolean | null;
  colors: IProductColorDefinition[];
  sizes: IProductSizeDefinition[];
  sizeGuides?: ISizeGuideSnippet[];
  sizeGuideId?: number | null;
  pricePerSize: boolean | null;
  personalizations: string[];
  discontinued: boolean | null;
  brandReference: string | null;
  brandPriceCents:  number | null;
  lstReference: string | null;
  lstPriceCents:  number | null;
  ralawiseReference: string | null;
  ralawisePriceCents:  number | null;
  topTexReference: string | null;
  topTexPriceCents:  number | null;
  imbretexReference: string | null;
  imbretexPriceCents:  number | null;
  connectedTo: string[];
  connectionCode: string | null;
  connections?: string[];
  createJunior?: string | null;
}

export const InitialImageDefinition = {
  name: 'New',
  url: 'New',
  delete: false,
  side: 'X',
}
export const InitialColorDefinition =  {
  supplierColor: 'New color',
  dagobaColor: 'Nouvelle couleur',
  hexColor1: '#000000',
  hexColor2: '#ffffff',
  color1: 'black',
  color2: 'white',
  discontinued: null,
  extra: null,
  missing: null,
  images: [InitialImageDefinition],
  colorCode: null,
  colorSku: null,
  backTemplateImage: null,
  rightTemplateImage: null,
  leftTemplateImage: null,
  add: true,
  remove: false,
}

export const ProductDefinitionDefault: IProductDefinition = {
  source: 'dagoba',
  id: 0,
  sku: null,
  catalogReady: false,
  brand: null,
  bestSeller: null,
  style: null,
  category: null,
  dagobaTitle: 'Nouveau produit',
  supplierTitle: null,
  description: null,
  dagobaPriceCents: null,
  fabric: null,
  grammage: null,
  weight: null,
  gender: -1,
  minQuantity: 1,
  cannotSellAlone: false,
  colors: [InitialColorDefinition],
  sizes: [
    {
      supplierSize: '3XS',
      dagobaSize: '3XS',
      height: 48,
      priceCents: null,
    },
    {
      supplierSize: '2XS',
      dagobaSize: '2XS',
      height: 50,
      priceCents: null,
    },
    {
      supplierSize: 'XS',
      dagobaSize: 'XS',
      height: 52,
      priceCents: null,
    },
    {
      supplierSize: 'S',
      dagobaSize: 'S',
      height: 54,
      priceCents: null,
    },
    {
      supplierSize: 'M',
      dagobaSize: 'M',
      height: 56,
      priceCents: null,
    },
    {
      supplierSize: 'L',
      dagobaSize: 'L',
      height: 58,
      priceCents: null,
    },
    {
      supplierSize: 'XL',
      dagobaSize: 'XL',
      height: 60,
      priceCents: null,
    },
    {
      supplierSize: 'XXL',
      dagobaSize: 'XXL',
      height: 62,
      priceCents: null,
    },
    {
      supplierSize: '3XL',
      dagobaSize: '3XL',
      height: 64,
      priceCents: null,
    },
    {
      supplierSize: '4XL',
      dagobaSize: '4XL',
      height: 66,
      priceCents: null,
    },
    {
      supplierSize: '5XL',
      dagobaSize: '5XL',
      height: 68,
      priceCents: null,
    },
  ],
  pricePerSize: false,
  personalizations: [],
  discontinued: null,
  brandReference: null,
  brandPriceCents: null,
  lstReference: null,
  lstPriceCents: null,
  ralawiseReference: null,
  ralawisePriceCents: null,
  topTexReference: null,
  topTexPriceCents: null,
  imbretexReference: null,
  imbretexPriceCents: null,
  connectedTo: [],
  connectionCode: null,
}


export interface ICatalogState {
  currentCategory: string;
  categories: ICategoryState[];
  primaryColor: string;
  primaryColorHex: string;
  secondaryColor: string;
  secondaryColorHex: string;
  currentGender: number;
  currentBrand: string;
  currentStyle: string;
  filterKey: string;
  filteredProducts: IProduct[];
  status: string;
  initialDefinition: IProductDefinition[] | null;
  shownDefinition: IProductDefinition[] | null;
  definitionHasChanges: boolean;
  definitionSaving: boolean;
}

function bonusNeutral(color: string) {
  if (color === 'white') return(10);
  if (color === 'black') return(5);
  return 0;
}

//
const redStyle = "color: red;";
const greenStyle = "color: green;";
// const blueStyle = "color: blue;";
// const orangeStyle = "color: orange;";
// const purpleStyle = "color: purple;";
// const boldStyle = "font-weight: bold;";
//
const LOG_PRODUCTS = ['B&C-TU03T', 'FTL-SS047' ]
// const LOG_PRODUCTS = ['']

export const SCORE_CLOSE_COLOR = 10;

export const VariantColorsScore = (
  product: IProduct,
  variant: IVariantInfos,
  primaryColor: string,
  primaryColorHex: string,
  secondaryColor: string,
  secondaryColorHex: string
) => {
  let score = product.bestSeller ? 3 : 1

  if (variant.color1 === primaryColor) {
    score += 100;
    // if (LOG_PRODUCTS.includes(product.sku)) {
    //   console.log("100 for (variant.color1 === primaryColor) " + product.sku + " " +  variant.friendlyColor + " total score " + score);
    // }

    // if (variant.hexColor1 !== '') {
    if (variant.hexColor1) {
      score += colorProximity(variant.hexColor1, primaryColorHex);

      // if (LOG_PRODUCTS.includes(product.sku)) {
      //   console.log("%ccolorProximity 1 " + colorProximity(variant.hexColor1, primaryColorHex) + " for " + product.sku + " " +  variant.friendlyColor + " total score " + score, greenStyle);
      // }
    } else {
      console.log("No hex1 color for " + product.sku + " " +  variant.friendlyColor + " color1 " + variant.color1 + " " + variant.color2)
    }

    if (secondaryColor === 'none' && variant.color2 === '') {
      score += 60;
    } else {

      if (secondaryColor !== 'none' && variant.color2 !== '') {

        // if (variant.hexColor2 !== '') {
        if (variant.hexColor2) {
          score += colorProximity(variant.hexColor2, secondaryColorHex);
          //
          // if (LOG_PRODUCTS.includes(product.sku)) {
          //   console.log("%ccolorProximity 2 " + colorProximity(variant.hexColor2, secondaryColorHex) + " for " + product.sku + " " +  variant.friendlyColor + " total score " + score, blueStyle);
          // }

        } else {
          console.log("No hex2 color for " + product.sku + " " +  variant.friendlyColor + " color2 " + variant.color2)
        }

        if (variant.color2 === secondaryColor) {
          score += 50;
        } else {

          if (variant.color2 === primaryColor) {
            score += 20;
          }

          score += bonusNeutral(variant.color2);
        }

      }
    }

  } else {

    if (secondaryColor !== 'none') {

      if (variant.color1 === secondaryColor) {

        score += 60;
        // if (LOG_PRODUCTS.includes(product.sku)) {
        //   console.log("40 for (variant.color1 === secondaryColor) " + product.sku + " " +  variant.friendlyColor + " total score " + score);
        // }

        if (variant.hexColor1) {
          score += colorProximity(variant.hexColor1, secondaryColorHex);

          // if (LOG_PRODUCTS.includes(product.sku)) {
          //   console.log("%ccolorProximity 3 " + colorProximity(variant.hexColor1, secondaryColorHex) + " for " + product.sku + " " +  variant.friendlyColor + " total score " + score, purpleStyle);
          // }

        } else {
          console.log("No hex1 color for " + product.sku + " " +  variant.friendlyColor + " color1 " + variant.color1 + " " + variant.color2)
        }
      }

    }

    if (variant.color2 !== '') {

      if (variant.color2 === primaryColor) {
        score += (variant.color1 === secondaryColor) ? 50 : 40;
        // if (LOG_PRODUCTS.includes(product.sku)) {
        //   console.log(((variant.color1 === secondaryColor) ? 50 : 40) + " for (variant.color2 === primaryColor) and (variant.color1 === secondaryColor) " + product.sku + " " +  variant.friendlyColor + " total score " + score);
        // }
      }

      if (secondaryColor !== 'none') {

        if (variant.hexColor2) {
          score += colorProximity(variant.hexColor2, primaryColorHex);
          // if (LOG_PRODUCTS.includes(product.sku)) {
          //   console.log("%ccolorProximity 4 " + colorProximity(variant.hexColor2, primaryColorHex) + " for " + product.sku + " " +  variant.friendlyColor + " total score " + score, orangeStyle);
          // }
        } else {
          // console.log("No hex2 color for " + product.sku + " " +  variant.friendlyColor + " color2 " + variant.color2)
        }

        if (variant.color2 === secondaryColor) {
          score += 10 + bonusNeutral(variant.color1);
        }
      }
    }
  }

  // Now we want that very close colors have the same score so that the price criteria is more important
  // divide par 10 and round to 0 decimal
  const roundedScore = Math.round(score / 10) + 1;

  // if ((variant.color1 === primaryColor || variant.color1 === secondaryColor) && (LOG_PRODUCTS.includes(product.sku))) {
  //   console.log("%cFinal for " + product.sku + " " +  variant.friendlyColor + " total score=" + Math.round(score) + " roundedScore=" + roundedScore, redStyle);
  // }

  return roundedScore;
}

function matchColorsScore(product: IProduct) {
  return Math.max(...product.variants.map(variant => variant.colorMatch), 1);
}

function filterGender(product: IProduct, gender: number) {
  // if (gender == GENDER_UNISEX) return (product.gender == GENDER_NONE || product.gender == GENDER_UNISEX || product.gender == GENDER_MAN || product.gender == GENDER_WOMAN || gender == GENDER_CHILD);
  if (gender === GENDER_UNISEX) return true;
  if (gender === GENDER_WOMAN || gender === GENDER_CHILD) return (product.gender === gender);
}

function filterBrand(product: IProduct, brand: string) {
  return((brand === 'all') ? true : (product.brand === brand));
}

function filterStyle(product: IProduct, style: string) {
  return((style === 'all') ? true : (product.style === style));
}

function filterColor(product: IProduct, state: ICatalogState) {
  return (product.variants.some(variant => (variant.color1 == state.primaryColor || variant.color1 == state.secondaryColor ||
      variant.color2 == state.primaryColor || (variant.color2 !== '' && variant.color2 == state.secondaryColor)
  )));
}

function filterProductByKey(product: IProduct, key: string) {
  if ((product.title !== undefined && product.title !== null && product.title.toLowerCase().includes(key))
    || (product.sku !== undefined && product.sku !== null && product.sku.toLowerCase().includes(key)))
    return true;
  // if (product.description !== undefined && product.description !== null && product.description.toLowerCase().includes(key)) return true;
  return(false);
}


function getOtherVersions(product: IProduct, categoryIndex: number, allProducts: IProduct[], state: ICatalogState) {
  if (product.connection === '') return [];

  if (product.otherVersions.length > 0) {
    return product.otherVersions;
  } else {
    const otherVersions = [] as IProduct[];
    const productsToSearch = (categoryIndex !== -1) ? state.categories[categoryIndex].products : allProducts;
    product.connectedProductIds.forEach((id) => {
      const otherProduct = productsToSearch.find((p) => p.id === id);
      if (otherProduct) {
        otherVersions.push(otherProduct);
      }
    });
    
    return otherVersions;
  }
}

export const getProductById = (state: ICatalogState, productId: number) => {

  // console.log("Looking for product: " + productId + " ...")
  let foundProduct: IProduct | undefined;
  
  state.categories.some((category) => {
    foundProduct = category.products.find((p) => p.id === productId);
    return foundProduct !== undefined;
  });

  return foundProduct || null;
}

export const updateStateProductsProperty = (state: ICatalogState, property: keyof IProduct, value: string, productIds: number[]) => {
  let catalogChanged = false;
  productIds.forEach((productId) => {
    const product = getProductById(state, productId);
    if (product) {
      // console.log("Updating product " + productId + " " + property + " to " + value);
      (product[property] as string) = value;
      catalogChanged = true;
    }
  });
  if (catalogChanged) {
    // console.log("Catalog changed, updating filtered products")
    filter_products(state);
  }
}

const filterProductsByGender = (filterKey: string, currentGender: number, products: IProduct[]) => {
  // we filter by gender only when we are not searching by key
  if (filterKey === '') {
    // if the gender filter is unisex, we take all products but we filter out
    // the WOMAN and JUNIOR products which are listed as otherVersions of any product
    if (currentGender === GENDER_UNISEX) {
      return products.filter((product: IProduct) =>
        !(
          ((product.gender === GENDER_WOMAN || product.gender === GENDER_CHILD)) &&
          products.some((p: IProduct) =>
            p.gender !== GENDER_CHILD && p.gender !== product.gender &&
            p.connectedProductIds.includes(product.id)
          )
        )
      );
    } else {
      return products.filter((product: IProduct) => filterGender(product, currentGender));
    }

  } else {
    return products;
  }
}

export const filter_products = (state: ICatalogState) => {

  // console.time('filter_products ' + state.currentCategory);
  // let allProducts: IProduct[] = [];
  //
  // // put all products in one array
  // CatalogCategories.forEach((category, index) => {
  //   allProducts.push(...state.categories[index].products);
  // });

  const allProducts = state.categories.flatMap(category => category.products);

  let filteredProducts: IProduct[] = [];
  filteredProducts = allProducts.filter(product => filterProductByKey(product, state.filterKey));
  filteredProducts = filteredProducts.filter(product => filterBrand(product, state.currentBrand));
  filteredProducts = filteredProducts.filter(product => filterStyle(product, state.currentStyle));

  const category_index = state.categories.findIndex(category => category.category === state.currentCategory);
  // add other versions to products
  filteredProducts = filteredProducts.map((product: IProduct) => ({
    ...product,
    otherVersions: getOtherVersions(product, category_index, allProducts, state)
  }));

  let filteredGenderedProducts = filterProductsByGender(state.filterKey, state.currentGender, filteredProducts);

  // console.log("Before sorting: " + filteredGenderedProducts.length + " products")
  // filteredGenderedProducts.forEach((product: Product) => {
  //   console.log(product.title + " " + product.colorMatch);
  // });

  state.filteredProducts = filteredGenderedProducts.map((product: IProduct) => {
    return({
      ...product,
      variants: product.variants.map((variant: IVariantInfos) => ({
                ...variant,
                colorMatch: VariantColorsScore(product, variant, state.primaryColor, state.primaryColorHex, state.secondaryColor, state.secondaryColorHex)
              } as IVariantInfos)).
              sort((a, b) => b.colorMatch - a.colorMatch),
      // otherVersions: getOtherVersions(product, category_index, allProducts, state)
    })
  })
    .map((product: IProduct) => ({
    ...product,
    colorMatch: matchColorsScore(product)
  } as IProduct)).
  sort((a, b) => b.colorMatch - a.colorMatch).
  sort((a, b) => a.subCategory.localeCompare(b.subCategory))
    // .slice(0, 1);

  if (state.filterKey !== '') {
    const uniqueGenderSet: Set<number> = new Set([]);
    filteredGenderedProducts.forEach((product: IProduct) => {
      uniqueGenderSet.add(product.gender);
    });
    // console.log("After filter : uniqueGenderSet size = " + uniqueGenderSet.size + " => " + JSON.stringify(Array.from(uniqueGenderSet)));
    if (uniqueGenderSet.size === 1) {
      state.currentGender = Array.from(uniqueGenderSet)[0];
    }
  }

  // console.timeEnd("filter_products " + state.currentCategory);

}

