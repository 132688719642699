import './website.scss';
import React, {useContext, useEffect} from "react";
import {IntlShape} from "react-intl";
import Box from "@mui/material/Box";
import {Grid} from "@mui/material";

import FacebookLikeButton from "../utils/FacebookLikeButton";
// import FacebookIcon from '@mui/icons-material/Facebook';
import logo from "../../images/dagoba.png";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../app/store";
import {impersonatingUser, ROLE_BOUTIQUE} from "../sessions/sessionSlice";
import {openDemoBoutique} from "../dashboard/boutiqueSlice";
import {To, useNavigate} from "react-router-dom";
import FacebookLikeButtonWrapper from "../utils/FacebookLikeButton";
import {I18nContext} from "../locale/LocaleWrapper";

const Footer =  (props: {
  intl: IntlShape,
}) => {

  const intl = props.intl;
  const { locale } = useContext(I18nContext);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const currentUser = useSelector((state : RootState) => state.session.currentUser);
  const loggedUser = currentUser && currentUser.id !== undefined;
  const userBoutique = loggedUser && currentUser.role !== undefined &&
    (currentUser.role >= ROLE_BOUTIQUE || impersonatingUser(currentUser))

  const clickBoutique = () => {
    if (userBoutique) {
      navigate(`/${locale}/dashboard`)
    } else {
      navigate(`/${locale}/catalog`)
      dispatch(openDemoBoutique({open: true, loading: false}));
    }
  }

  return (
    <Box className='section footer'>
      <Box className='four-panels'>
        <Grid container>
          <Grid item xs={12} sm={3}>
            <Box className='content'>
              <Box className='content-title logo'>
                <img src={logo} className='logo' onClick={() => navigate(`/${locale}`)} />
              </Box>
              <Box className='content-item'>
              10 rue de penthièvre
              </Box>
              <Box className='content-item'>
                75008 PARIS
              </Box>
              <Box className='content-item sas'>
                {intl.formatMessage({id: 'footer.company'})}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box className='content second'>
              <Box className='content-title'>
                {intl.formatMessage({id: 'footer.services'})}
              </Box>
              <Box className='content-item link' onClick={() => navigate(`/${locale}/catalog`)}>
                {intl.formatMessage({id: 'footer.catalog'})}
              </Box>
              <Box className='content-item link' onClick={clickBoutique}>
                {intl.formatMessage({id: 'footer.shop'})}
              </Box>
              <Box className='content-item link' onClick={() => navigate(`/${locale}/help`)}>
                {intl.formatMessage({id: 'footer.support'})}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box className='content third'>
              <Box className='content-title'>
                {intl.formatMessage({id: 'footer.legal'})}
              </Box>
              <Box className='content-item link' onClick={() => navigate(`/${locale}/terms-of-service`)}>
                {intl.formatMessage({id: 'footer.cgs'})}
              </Box>
              <Box className='content-item link' onClick={() => navigate(`/${locale}/terms-of-sale`)}>
                {intl.formatMessage({id: 'footer.cgv'})}
              </Box>
              <Box className='content-item link' onClick={() => navigate(`/${locale}/legal-notices`)}>
                {intl.formatMessage({id: 'footer.notices'})}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box className='content follow'>
              <Box className='content-title'>
                {intl.formatMessage({id: 'footer.follow'})}
              </Box>
              <Box className='content-item'>
                <FacebookLikeButtonWrapper />
              </Box>
              <Box className='content-item sas'>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )

}

export default Footer;