// https://joelmturner.com/blog/inline-text-edit-react-hooks

import React, {useCallback, useState, useRef, useEffect, FormEvent} from 'react';
import { useKeyPress, useOnClickOutside } from '../../app/hooks';
import DOMPurify from "dompurify";
import {BoudaToolTip} from "./BoudaToolTip";


const InlineEdit = (props:{
  text: string | null,
  onSetText: Function,
  editable?: boolean,
  maxWidth?: number,
  widthPixels?: number,
  errorMode?: boolean,
  errorText?: string,
  color?: string,
  tip?: string
  typeFilterRegex?: RegExp,
  validationRegex?: RegExp,

}) => {

  const { text, onSetText, errorMode, errorText, color, widthPixels, tip , typeFilterRegex, validationRegex} = props;
  const editable = props.editable || props.editable === undefined;

  const maxWidth = props.maxWidth || 1024;

  const [isInputActive, setIsInputActive] = useState(false);
  const [inputValue, setInputValue] = useState(text || '');

  const wrapperRef = useRef(null);
  const textRef = useRef(null);
  const inputRef = useRef(null);

  const enter = useKeyPress('Enter');
  const tab = useKeyPress('Tab');
  const esc = useKeyPress('Escape');

  const submitNewValue = () => {
    let filteredValue = inputValue;
    if (validationRegex) {
      filteredValue = inputValue.replace(validationRegex, '');
    }
    if (filteredValue !== text) {
      onSetText(filteredValue);
      setInputValue(filteredValue);
    }
    setIsInputActive(false);
  }

  // check to see if the user clicked outside of this component
  useOnClickOutside(wrapperRef, () => {
    if (isInputActive) submitNewValue();
  });

  const onEnter = useCallback(() => {
    if (enter) submitNewValue();
  }, [enter, inputValue, onSetText]);

  const onEsc = useCallback(() => {
    if (esc) {
      setInputValue(text || '');
      setIsInputActive(false);
    }
  }, [esc, text]);

  // focus the cursor in the input field on edit start
  useEffect(() => {
    if (isInputActive && inputRef !== null && inputRef.current !== null) {
      let input = inputRef.current as HTMLElement;
      input.focus();
      // console.log("InlineEdit just set focus (" + inputValue + ")");
    }
  }, [isInputActive]);

  useEffect(() => {
    if (isInputActive) {
      onEnter();
      onEsc();
    }
  }, [onEnter, onEsc, isInputActive]); // watch the Enter and Escape key presses

  const handleInputChange = useCallback(
    (event:FormEvent<HTMLInputElement>) => {
      // sanitize the input a little
      let target = event.target as HTMLInputElement;

      let filteredValue = target.value;

      if (typeFilterRegex) {
        filteredValue = filteredValue.replace(typeFilterRegex, '');
      }

      setInputValue(DOMPurify.sanitize(filteredValue));
    },
    [setInputValue]
  );

  const handleSpanClick = useCallback(() => {
    // console.log("InlineEdit callback handleSpanClick (" + inputValue + "), setting input active");
    if (editable) {
      setIsInputActive(true)
    }
  }, [setIsInputActive]);

  const handleFocus = (e:any) => {
    e.preventDefault();
    e.target.setSelectionRange(0, e.target.value.length);
  };

  const inputStyle = {
    minWidth: Math.ceil(inputValue.length === 0 ? 1 : inputValue.length) + 'ch',
    width: widthPixels ? widthPixels + 'px' : 'auto'
  }

  return (
    <BoudaToolTip placement="bottom" title={errorMode ? errorText : (isInputActive && !!tip) ? tip : ''} arrow disableInteractive={true}
                  sx={{textAlign: 'center'}}>
      <div className={`inline-text ${errorMode ? 'error' : ''}`} ref={wrapperRef}>
        <span style={{color: color}}
            ref={textRef}
            onClick={handleSpanClick}
            className={`inline-text_copy ${editable ? 'editable' : ''} inline-text_copy--${!isInputActive ? 'active' : 'hidden'} ${errorMode ? 'error' : ''}`}
        >
          {text}
        </span>
        <input
            ref={inputRef}
            // set the width to the input length multiplied by the x height
            // it's not quite right but gets it close
            style={inputStyle}
            value={inputValue}
            maxLength={maxWidth}
            onFocus={handleFocus}
            onChange={handleInputChange}
            // onClick={handleInputClick}
            className={`inline-text_input inline-text_input--${isInputActive ? 'active' : 'hidden'} ${errorMode ? 'error' : ''}`}
        />
      </div>
    </BoudaToolTip>
  );
}

export default InlineEdit;