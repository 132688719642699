import React, {useState, useEffect, ReactElement, useContext} from 'react';
import InlineEdit from "../utils/InlineEdit";
import {
  storeBoutiqueInfo,
  resetBoutique,
  saveBoutique,
  addCollection,
  BoutiqueAllColors,
  moveCollections,
  checkSubdomainAvailability,
  BoutiqueColorToHex,
  IBoutiqueInfos,
  storeBoutiqueColors,
  duplicateBoutique,
  editBoutique, PLAN_MARGINS, PLAN_STANDARD, applyBoutiqueChanges, ICollectionProductColor, IAddonInfos,
} from "./boutiqueSlice";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../app/store";
import {CollectionCard, MAX_PRODUCTS_PER_COLLECTION} from "./CollectionCard";
import {CustomButton} from "../utils/CustomButton";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Popover,
  Grid,
  RadioGroup
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import LaunchIcon from '@mui/icons-material/Launch';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LanguageIcon from '@mui/icons-material/Language';
import RefreshIcon from '@mui/icons-material/Refresh';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import HandymanIcon from '@mui/icons-material/Handyman';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {FormattedMessage, useIntl} from "react-intl";
import Backdrop from '@mui/material/Backdrop';
import BoutiqueLogo from "./BoutiqueLogo";
import ColorPicker from "../utils/ColorPicker";
import {openConfirmDialog, openSnackBar} from "../global/globalSlice";
import Box from "@mui/material/Box";
import PopoverEdit from "../utils/PopoverEdit";
import {SelectFont} from "./SelectFont";
import {colorNameTranslated, SelectPersoColor} from "./SelectPersoColor";
import {useAppSelector} from "../../app/hooks";
import {CollectionProductConfig} from "./CollectionProductConfig";
import {BoudaToolTip} from "../utils/BoudaToolTip";
import Radio from "@mui/material/Radio";
import dayjs, { Dayjs } from 'dayjs';
import {impersonatingUser} from "../sessions/sessionSlice";
import TimeProgressBar from "../utils/TimeProgressBar";
import WarningIcon from "@mui/icons-material/Warning";
// @ts-ignore
import variables from "../../styles/_variables.scss";
import {useHelpTips} from "../../app/helpToolTip";
import {openDocAtAnchor} from "../../app/website";
import HelpIcon from "@mui/icons-material/Help";
import {I18nContext} from "../locale/LocaleWrapper";
import {logosSelector} from "../logos/logosSlice";

// coming from boutique.rb
const TIME_PER_IMAGE = 0.23

const INITIAL_NAME = 'Nom de mon organisation'
const DRAFT_SUBDOMAIN_SEP = '_draft'
const SUBDOMAIN_MIN_WITDH = 4;
const SUBDOMAIN_MAX_WITDH = 50;
// while typing we allow the hyphen at the end, but not at the beginning
const SUBDOMAIN_TYPE_FILTER_REGEX = /[^a-z0-9-]+|^-+/gi;
// upon validation we remove the hyphen at the end
const SUBDOMAIN_VALIDATION_REGEX = /[^a-z0-9-]+|^-+|-+$/gi;

const FONTS_NO_ACCENT = [
  'JerseyM54',
  'Karate',
  'Norwester',
  'PaybAck',
  'PrnStarAcademy'
];

const TEXT1_DEMO_FONT = "FLOCAGE"
const TEXT2_ACCENTS = "Ô ÉCRITURE"
const TEXT2_NO_ACCENT = "O ECRITURE"
const SOCIAL_ICONS_SIZE = 'clamp(0.1rem, 0.5vw, 1.2rem)'

const DATE_MIN_LENGTH = 10;

const maxProductsPerBoutique = (orgaActivity: string)  => {
  switch (orgaActivity) {
    case 'karate':
      return 26;
    default:
      return 22;
  }

  // switch (boudistId) {
  //   case 13: // Julie BOURGEOIS Loire Divatte Roller
  //     return 32;
  //   case 100: // Arnaud Le Bohec Dojo Basse Goulaine
  //     return 38;
  //   case 180: // Juliette AMO MER Athlétisme
  //     return 24;
  //   case 181: // Aude MHBB Montville Houppeville Basket Ball
  //     return 30;
  //   case 222: // Dimitri Magdinier Saint Marcel Judo
  //     return 30;
  //   case 230: // Jennifer Parfond - ASBFR asf-romilly-basketball
  //     return 24;
  //   case 268: // Murat - FDB HOCKEY
  //     return 24;
  //   case 288: // Pierre-Damien AUDIC - Shindozen 35
  //     return 30;
  //   default:
  //     if (boutique.orgaActivity === 'karate') {
  //       return 25;    // special case because of the karate protections
  //     }
  //     return 20;
  // }
}

const nbMinutesToDoBoutique = (nbImages: number) => Math.ceil((10 + nbImages*TIME_PER_IMAGE) / 60);

export const Boutique = (props: { boutique: IBoutiqueInfos}) => {

  const currentUser = useSelector((state : RootState) => state.session.currentUser);
  const impersonating = impersonatingUser(currentUser);
  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();
  const {locale} = useContext(I18nContext);

  const {boutique} = props;
  const editMode = boutique.draftOf !== null;

  // Help tips of this component
  useHelpTips(editMode ? 'draft' : 'boutique', intl, dispatch);

  const maxProducts = currentUser?.productLimit || maxProductsPerBoutique(boutique.orgaActivity);

  // we can deploy either a draft or new boutique whose config has been applied
  const deployable = editMode && (boutique.draftOf !== 0 || boutique.configApplied) && boutique.id && boutique.id > 0;
  const boutiqueHasChanges = useSelector((state : RootState) => state.boutique.hasChanges);
  const boutiqueHasError = useSelector((state : RootState) => state.boutique.hasError);
  const subdomainTaken = useSelector((state : RootState) => state.boutique.subdomainTaken);
  const saving = useSelector((state : RootState) => state.boutique.saving);

  // calculate the total number of different images in the boutique, which is the number of different
  // variants of the products (=product of each collection product) in the collections
  const nbImages = boutique.collections.reduce((acc, collection) =>
    (acc + collection.products.reduce((acc2, cp) =>
      (acc2 + cp.colors.length * (cp.product?.variants[0].sizes.length || 1)), 0)), 0)

  const [nameError, setNameError] = useState(false);
  const [planError, setPlanError] = useState(false);
  const [viewConditions, setViewConditions] = useState(false);
  const [persoFont, setPersoFont] = useState('Norwester');

  // console.log("Render Boutique with id=", boutique.id, " and name=", boutique.name, " and logosUnsynced=", boutique.logosUnsynced);
  useEffect(() => {
    if (boutique) {
      setPersoFont(boutique.persos_font);
    }
  }, [boutique, boutiqueHasChanges]);

  ///////////////////////////////////////////////////////////////////////
  // Help tips of this component
  // const helpItems = useSelector((state : RootState) => state.session.currentUser?.helpItems || []);
  // useEffect(() => {
  //   if (tooltipRef.current && helpItems.length > 0) {
  //     activateHelpTips(helpItems, 'boutique', intl, dispatch);
  //   }
  // }, [tooltipRef, helpItems])
  ///////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  // Boutique colors
  const boutiqueColors = BoutiqueAllColors(boutique)

  const setBoutiqueColors = (colors: string[]) => {
    dispatch(storeBoutiqueColors(colors));
  }

  // this is a replicate of the function in SelectPersoColor, but using intl because it goes into the redux state
  const colorNameI18n = (colorCode: string) => {
    if (colorCode.startsWith('color')) {
      return intl.formatMessage({id: "color.color-number"}, {number: parseInt(colorCode.substring(5))});
    } else if (colorCode === 'new-color') {
      return intl.formatMessage({id: "color.new-color"});
    } else {
      return intl.formatMessage({id: `color.${colorCode}`});
    }
  }

  const defineColor = (colorIndex: number, color: string) => {
    dispatch(openConfirmDialog({
        title: intl.formatMessage({ id: "boutique.change-persos-color-title"}),
        message: intl.formatMessage(
          { id: "boutique.change-persos-color-confirmation"},
          { color: colorNameI18n(color),
            articles: intl.formatMessage({ id: colorIndex === -1 ? "boutique.dark-articles" : "boutique.light-articles" })
          }),
        confirm: intl.formatMessage({ id: "global.yes"}),
        confirmColor: 'primary',
        actionMethod: 'definePersoColor',
        actionParam: `${color}${colorIndex}`
      }
    ));
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  // Moving collections

  const moveCollection = (id: number, direction: string) => {
    // console.log("Moving collection " + id + " " + direction);
    if (boutique) {

      const currentCollectionIndex = boutique.collections.findIndex((c) => c.id === id);
      const updatedCollectionArray = boutique.collections.map(
        (c) => c.id === id ?
          boutique.collections[currentCollectionIndex + (direction === 'up' ? -1 : 1)] :
          c.id === boutique.collections[currentCollectionIndex + (direction === 'up' ? -1 : 1)].id ?
            boutique.collections[currentCollectionIndex] : c
      );

      dispatch(moveCollections({collCollections: updatedCollectionArray}));
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  // Modify boutique infos

  const setInfos = (field: string, value:string) => {
    dispatch(storeBoutiqueInfo({field: field, value: value}))

    if (boutique && field === 'subdomain' && value.length >= SUBDOMAIN_MIN_WITDH) {
      dispatch(checkSubdomainAvailability({id: boutique.id, subdomain: value}));
    }

    if (field === 'name' && value !== INITIAL_NAME) {
      setNameError(false);
    }

    if (field === 'persos_font') {
      setPersoFont(value);
    }

    if (field === 'plan') {
      switch(parseInt(value)) {
        case PLAN_STANDARD:
          setPlanError(false);
          setViewConditions(false);
          break;
        case PLAN_MARGINS:
          if (!boutique.planAcceptedOn || boutique.planAcceptedOn === 'false') {
            setViewConditions(true);
          }
          break;
      }
    }

    if (field === 'planAcceptedOn') {
      if (value === 'true') {
        setPlanError(false);
      } else if (value === 'false' && boutique.plan === PLAN_MARGINS) {
        setPlanError(true);
    }}
  }

  const saveActions = () => {
    return (
      <div className="save-actions">
        {editMode && <>

          {/* DEPLOY BOUTIQUE */}
          {deployable && !boutiqueHasChanges && !errorProductLogos &&
			        <BoudaToolTip title={<FormattedMessage
                id={boutique.draftOf === 0 ? "boutique.finalize-explain" : "boutique.deploy-explain"} />}>
				        <CustomButton backgroundcolor='blue' onClick={() => {
                  if (boutique.id) {
                    dispatch(openConfirmDialog({
                        title: intl.formatMessage({ id: "boutique.deploy-title"}),
                        message: intl.formatMessage({ id: "boutique.deploy-confirmation"}),
                        confirm: intl.formatMessage({ id: "global.yes"}),
                        confirmColor: 'primary',
                        actionMethod: 'deployBoutique',
                        actionParam: boutique.id,
                      }
                    ));
                  }}} >
					        <DoneAllIcon />
				        </CustomButton>
			        </BoudaToolTip>
          }

          {/* DELETE THE DRAFT BOUTIQUE */}
          {!boutiqueHasChanges && !boutique.buildingAt && boutique.id !== null &&
            <BoudaToolTip title={<FormattedMessage id={"boutique.delete-changes"} />}>
	            <CustomButton backgroundcolor='darkred' onClick={() => {
                dispatch(openConfirmDialog({
                    title: intl.formatMessage({ id: "boutique.delete-changes"}),
                    message: intl.formatMessage({ id: "boutique.delete-changes-conf-message"}),
                    confirm: intl.formatMessage({ id: "global.yes"}),
                    actionMethod: 'deleteBoutiqueChanges',
                    actionParam: {boutiqueId: boutique.id, draftOf: boutique.draftOf}
                  }
                ));
              }}><DeleteIcon /></CustomButton>
            </BoudaToolTip>
          }

          {/* APPLY THE CHANGES ON THE ACTUAL DRAFT BOUTIQUE */}
          {!boutiqueHasChanges && !boutique.configApplied && !boutique.buildingAt && boutique.id !== null &&
            <BoudaToolTip title={<FormattedMessage id={"boutique.apply-changes"} />}>
              <CustomButton backgroundcolor='darkgreen' onClick={() => {
                if (boutique.id) {
                  dispatch(openConfirmDialog({
                      title: intl.formatMessage({ id: "boutique.apply-changes-conf-title"}),
                      message: !!boutique.vendorId ?
                        intl.formatMessage({ id: "boutique.apply-changes-conf-message"}) :
                        intl.formatMessage({id: "boutique.apply-changes-conf-message-minutes"},
                          {nbMinutes: nbMinutesToDoBoutique(nbImages)}),
                      confirm: intl.formatMessage({ id: "global.yes"}),
                      confirmColor: 'success',
                      actionMethod: 'applyBoutiqueChanges',
                      actionParam: boutique.id,
                    }
                  ));
                }
              }}><ChangeCircleIcon /></CustomButton>
            </BoudaToolTip>
          }

          {/* CANCEL CHANGES */}
          {boutiqueHasChanges && !boutique.buildingAt && <CustomButton onClick={() => {
            dispatch(resetBoutique())
          }}><FormattedMessage id="global.cancel" /></CustomButton>}

          {/* SAVES CHANGES */}
          {boutiqueHasChanges && !boutique.buildingAt &&
            <BoudaToolTip title={<FormattedMessage id={saveable ? "boutique.save-changes" : "boutique.has-errors"} />}><span>
			        <Button variant="contained" color={hasError ? 'error' : 'success'} disabled={!saveable}
			                onClick={() => {
                        if (boutique) {
                          trySavingBoutique(false);
                        }
                      }}>
				        <FormattedMessage id="global.save" />
                {boutique && boutique.buildingAt && <CircularProgress size={20} sx={{color: 'white', marginLeft: '10px'}}/>}
			        </Button>
            </span></BoudaToolTip>
          }

        </> || <>

          {/*Redo all images*/}
          {(impersonating || process.env.NODE_ENV === 'development') && !boutiqueHasChanges && !boutique.buildingAt &&
            <BoudaToolTip title={`Nb images ${nbImages}`}>
              <CustomButton onClick={() => {if (boutique) { trySavingBoutique(true);}}}>
                <RefreshIcon/>
              </CustomButton>
            </BoudaToolTip>
          }

          {/* DUPLICATE THE BOUTIQUE */}
          {impersonating && <CustomButton backgroundcolor={variables.dagobaBlue} onClick={() => {
            dispatch(duplicateBoutique())
          }}><ContentCopyIcon/></CustomButton>}

          {/* EDIT THE BOUTIQUE */}
          <BoudaToolTip title={<FormattedMessage id={"boutique.modify"} />}>
            <CustomButton id='edit-store' backgroundcolor='darkorange' onClick={() => {
              dispatch(editBoutique())
            }}><HandymanIcon/></CustomButton>
          </BoudaToolTip>
        </>
        }
      </div>
    )
  }

  async function trySavingBoutique(refreshAll: boolean, applyChanges: boolean = false) {

    if (boutique.name === INITIAL_NAME) {
      setNameError(true);
      dispatch(openSnackBar({severity: 'error', message: intl.formatMessage({ id: "boutique.name-error"})}));
      return;
    }

    if (boutique.name === '') {
      setNameError(true);
      setInfos('name', '?');
      dispatch(openSnackBar({severity: 'error', message: intl.formatMessage({ id: "boutique.name-error"})}));
      return;
    }

    if (!boutique.termsException && boutique.plan === PLAN_MARGINS &&
      (!boutique.planAcceptedOn || (boutique.planAcceptedOn.length < DATE_MIN_LENGTH && boutique.planAcceptedOn !== 'true'))) {
      setPlanError(true);
      dispatch(openSnackBar({severity: 'error', message: intl.formatMessage({ id: "boutique.plan-error"})}));
      return;
    }

    const response = await dispatch(saveBoutique(refreshAll)) as any;

    let message = '', severity = '';

    switch(response.type) {

      case 'boutique/saveBoutique/fulfilled':
        message = refreshAll || applyChanges ? "boutique.refreshing" : "boutique.saved";
        severity = "success";
        if (applyChanges) {
          const draftBoutiqueId = response.payload.id;
          dispatch(applyBoutiqueChanges(draftBoutiqueId));
        }
        break;

      case 'boutique/saveBoutique/rejected':
        message = "boutique.not-saved";
        severity = "error";
        break;
    }

    if (message !== '') {
      dispatch(openSnackBar({severity: severity, message: intl.formatMessage({id: message})}));
    }
  }

  function hasNewCollection() {
    return boutique && boutique.collections && boutique.collections.find((c) => c.id === 0);
  }

  const subDomainHasError = subdomainTaken || (boutique && boutique.subdomain.length < SUBDOMAIN_MIN_WITDH) || false;
  const subDomainErrorText = subdomainTaken ?
      intl.formatMessage({id: "boutique.subdomain-taken"}) : intl.formatMessage({id: "boutique.min-4-char"});

  const collectionHasError = boutique.collections.some((c) =>
    c.nbProducts && c.nbProducts > MAX_PRODUCTS_PER_COLLECTION) || false;

  // declare an error if the sum of collections products is greater than the maximum
  const errorNbProducts = boutique.collections.reduce((acc, c) =>
    (acc + (c.nbProducts ? c.nbProducts : 0)), 0) > maxProducts;

  // declare an error if any of the product has invalid logo

  // const notValidLogos = collectionProduct.colors
  //   .flatMap((color: ICollectionProductColor) =>
  //     color.logoAddons
  //       .filter((addon: IAddonInfos) => addon.logoIds.length > 0)
  //       .flatMap((addon: IAddonInfos) =>
  //         addon.logoIds
  //           .map((logoId: number) => logos.find((logo: ILogoInfos) => logo.id === logoId))
  //           .filter((logo: ILogoInfos | undefined) => !logo || !logo.logoUrl)
  //       )
  //   );
  //
  // const notValidLogo = notValidLogos.length > 0;

  const logos = useSelector(logosSelector);
  const errorProductLogos = boutique.collections.some((c) =>
    c.products.some((p) => p.colors.some((color) =>
      color.logoAddons.some((addon) => addon.logoIds
        .map((logoId) => logos?.find((logo) => logo.id === logoId))
          .some((logo) => !logo || !logo.logoUrl)
      )
    ))) || false;

  const hasError = subDomainHasError || boutiqueHasError || planError || nameError || collectionHasError || errorNbProducts || errorProductLogos ;
  const saveable = !saving && !hasError && boutiqueHasChanges && !boutique.buildingAt;

  const fontSupportsAccents= FONTS_NO_ACCENT.indexOf(persoFont) === -1;

  const persoOptions = () => {
    return <> { boutique &&
      <Box id='persos-card' className='persos'>
        <Box className='persos-title'>
	        <FormControlLabel label={<FormattedMessage id={`boutique.allow-persos`} /> } control={
            <Checkbox sx={{'&.MuiCheckbox-root': {color: '#808080'}}} size='medium' checked={boutique.persos_active}
                      onChange={ (event) =>
                        setInfos ('persos_active', event.target.checked ? 'true' : 'false') } />} />
	        <IconButton onClick={() => openDocAtAnchor(locale, 'persos-desc')}>
		        <HelpIcon className="help-icon" sx={{ fontSize: 28 }} />
	        </IconButton>
        </Box>
        <Box className='persos-options' sx={{opacity: boutique.persos_active ? 1 : 0.1}}>
          <SelectFont title={intl.formatMessage({id: "boutique.persos-font"}) }
                      currentFont={boutique.persos_font} focusFont={setPersoFont}
                      selectFont={(font: string) => setInfos('persos_font', font)} />
          <div className='persos-color'>
            <Box className='pick-color'>
              <FormattedMessage id="color.persos_on_dark"/>
	            <SelectPersoColor key='boutique-color-1' colorIndex={-1} editMode={editMode}
	                              color={boutique.color_dark_bg} setColor={defineColor}
	                              boutiqueColors={boutiqueColors} setBoutiqueColors={setBoutiqueColors} />
            </Box>
            <Box className='perso-preview dark'
                 sx={{fontFamily: persoFont, color: BoutiqueColorToHex(BoutiqueAllColors(boutique), boutique.color_dark_bg)}}>
              {TEXT1_DEMO_FONT}
            </Box>
          </div>
          <div className='persos-color'>
            <Box className='pick-color'>
              <FormattedMessage id="color.persos_on_light"/>
	            <SelectPersoColor key='boutique-color-2' colorIndex={-2} editMode={editMode}
	                              color={boutique.color_light_bg} setColor={defineColor}
	                              boutiqueColors={boutiqueColors} setBoutiqueColors={setBoutiqueColors} />

            </Box>
            <Box className='perso-preview light'
                 sx={{fontFamily: persoFont, color: BoutiqueColorToHex(boutiqueColors, boutique.color_light_bg)}}>
              {fontSupportsAccents ? TEXT2_ACCENTS : TEXT2_NO_ACCENT}
            </Box>
            {!fontSupportsAccents && <div className='perso-warning'>
              <FormattedMessage id="boutique.font-no-accents"/>
            </div>}
          </div>
        </Box>
      </Box>
    }</>
  }

  const planOptions = () => {
    const planMarginsColor = planError ? '#ba2d2d' : '#01659c';

    return <> { boutique &&
				<Box id='plans-card' className='markup'>
					<FormControl>
						<RadioGroup defaultValue="standard" name="plan-group" value={boutique.plan}
                        onChange={(e) => setInfos('plan', e.target.value)}>

							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} lg={4}>
									<Box className='plan standard'>
										<Box className={`title`}>
											<FormControlLabel label={intl.formatMessage({id: "boutique.standard"})} value={PLAN_STANDARD}
											                  sx={{'& .MuiFormControlLabel-label': {fontSize: 'var(--fs-normal)', color: '#0f5c2e'}}}
											                  control={
                                          <Radio sx={{
                                            '& .MuiSvgIcon-root': {
                                              fontSize: 24,
                                              color: '#0f5c2e',
                                              '&.Mui-checked': {
                                                color: '#0f5c2e',
                                              }},
                                          }} />}/>
										</Box>
										<Box sx={{display: 'flex', justifyContent: 'space-between', gap: '10px'}}>
                      <span className='plan-subtitle'>
                        <FormattedMessage id='boutique.standard-subtitle'/>
                      </span>
											<Box sx={{fontSize: '0.8em', cursor: 'pointer'}}
											     onClick={() => setViewConditions(!viewConditions)}>
												(<FormattedMessage id={`boutique.${viewConditions ? 'hide' : 'show'}-conditions`}/>)
											</Box>
										</Box>

                    {viewConditions && <Box sx={{mt: 2}}>
											<FormattedMessage id='boutique.standard-terms'/>
											<ul className='plan-terms-list'>
                        {Array.from(Array(2).keys()).map((i) =>
                          <li><FormattedMessage id={`boutique.standard-terms-${i+1}`} /></li>
                        )}
											</ul>
	                    <FormattedMessage id='boutique.standard-end' />
										</Box>}

                  </Box>

                </Grid>
								<Grid item  xs={12} sm={6} lg={8}>
									<Box className={`plan ${planError ? 'error' : 'margins'}`}>
										<Box className={`title`}>
											<FormControlLabel label={intl.formatMessage({id: "boutique.margins"})} value={PLAN_MARGINS}
											                  sx={{'& .MuiFormControlLabel-label': {fontSize: 'var(--fs-normal)', color: planMarginsColor}}}
											                  control={
                                          <Radio sx={{
                                            '& .MuiSvgIcon-root': {
                                              fontSize: 24,
                                              color: planMarginsColor,
                                              '&.Mui-checked': {
                                                color: planMarginsColor,
                                              }},
                                          }} />}/>
										</Box>
										<Box sx={{display: 'flex', justifyContent: 'space-between', gap: '10px'}}>
                      <span className='plan-subtitle'>
                        <FormattedMessage id='boutique.margins-subtitle' />
                      </span>
											<Box sx={{fontSize: '0.8em', cursor: 'pointer'}} onClick={() => setViewConditions(!viewConditions)}>
												(<FormattedMessage id={`boutique.${viewConditions ? 'hide' : 'show'}-conditions`} />)
                      </Box>
                    </Box>

                    {viewConditions && <Box sx={{mt: 2}}>
	                    <ul className='plan-terms-list'>
                        {Array.from(Array(7).keys()).map((i) =>
                          <li><FormattedMessage id={`boutique.margins-terms-${i+1}`} /></li>
                        )}
	                    </ul>

                      {boutique.plan === PLAN_MARGINS &&  <>
                        {!!boutique.planAcceptedOn && boutique.planAcceptedOn.length > DATE_MIN_LENGTH &&
                        <Box sx={{display: 'flex', alignItems: 'center', mt: 1}}>
                          <CheckCircleIcon fontSize={'small'} sx={{color: '#01659c', mr: 1}}/>
                          <FormattedMessage id='boutique.accepted-terms'
                                            values={{date: dayjs(boutique.planAcceptedOn, { utc: true}).format("DD/MM/YYYY")}} />
                        </Box> ||
                        <Box>
                          <FormControlLabel label={<FormattedMessage id='boutique.accept-plan' /> } control={
                            <Checkbox sx={{'&.MuiCheckbox-root': {color: '#808080'}}} size='small'
                                      checked={!!boutique.planAcceptedOn && boutique.planAcceptedOn === 'true'}
                                      onChange={ (event) =>
                                       setInfos ('planAcceptedOn', event.target.checked ? 'true' : 'false') } />} />

                        </Box>
                      }</>}
                    </Box>}
                  </Box>
                </Grid>
							</Grid>


						</RadioGroup>
					</FormControl>
        </Box>
    }</>
  }

  // console.log("%cRender Boutique with ids=", "color: purple", boutique.id, boutique.draftOf, " and name=", boutique.name);

  const productConfigShown = useAppSelector((state) => state.boutique.currentProductConfig)

  const progressColors = boutique.draftOf === null ?
    ['#d7fdff', '#5397e2', '#285c9a'] :
    ['hsl(33,92%,75%)', 'hsl(31,94%,53%)', 'hsl(30,79%,47%)'];

  const workInProgress = boutique.buildingAt && boutique.duration > 0;

  return(<>
    {boutique && <>
      <div key={`boutique ${boutique.id}`} className={`boutique ${editMode ? 'edit' : ''}`}>
        <Box sx={{opacity: workInProgress ? 0.15 : 1}}>
          <div className='boutique-header'>
            <div id='edit-logo' className="boutique-logo">
              <BoutiqueLogo logoId={boutique.logoId} editMode={editMode}/>
            </div>
            <div className="boutique-title">
              <div id='edit-name' className='name'>
                <InlineEdit key={`${boutique.id}-name`} text={boutique.name}  editable={editMode} errorMode={nameError}
                              onSetText={(value: string) => setInfos('name', value)}/>
                {editMode && <> (<FormattedMessage id="global.draft"/>)</>}
              </div>
              <div className='subtitle'>
                <InlineEdit key={`${boutique.id}-subtitle`} text={boutique.subtitle} editable={editMode}
                            onSetText={(value: string) => setInfos('subtitle', value)}/>
              </div>
            </div>
          </div>
          <div className='infos'>
            <div className={`subdomain-link ${editMode ? 'edit' : ''}`}>
              <Box sx={{color: 'black'}}>
                {editMode && <>
                  https://<span id='edit-url'>
                    <InlineEdit key='subdomain' text={boutique.subdomain} color={'blue'}
                                      maxWidth={SUBDOMAIN_MAX_WITDH}
                                      typeFilterRegex={SUBDOMAIN_TYPE_FILTER_REGEX}
                                      validationRegex={SUBDOMAIN_VALIDATION_REGEX}
                                      errorMode={subDomainHasError} errorText={subDomainErrorText}
                              onSetText={(value: string) => setInfos('subdomain', value)}/>
                  </span>
                  <span className='draft'>{DRAFT_SUBDOMAIN_SEP}{boutique.draftCode}</span>.{process.env.REACT_APP_BOUTIQUES_DOMAIN}
                </> || <>https://{boutique.subdomain}.{process.env.REACT_APP_BOUTIQUES_DOMAIN}</>}
              </Box>
              {boutique.id && boutique.id > 0 && boutique.configApplied && <Box className='open-link'
                onClick={() => window.open(
                     `https://${boutique.subdomain}${boutique.draftCode !== '' ? `${DRAFT_SUBDOMAIN_SEP}${boutique.draftCode}` : ``}.${process.env.REACT_APP_BOUTIQUES_DOMAIN}`,
                  '_blank')}>
                <LaunchIcon fontSize='small'/>
              </Box>}
            </div>
            {boutiqueColors.map((color, index) => {
              return (
                <div id={`edit-colour${index + 1}`} className='color' key={index}>
                  <FormattedMessage id="color.color-number" values={{number: index + 1}}/><br/>
                  <ColorPicker key={`bc-color${index + 1}`} propertyName={`color${index + 1}`} displayColor={true}
                               whiteAllowed={index !== 0} color={color} setColor={setInfos} opened={false} editable={editMode} />
                </div>
              )
            })
            }
            <div id='edit-social' className='social'>
              <PopoverEdit key='edit-website' title={intl.formatMessage({id: "boutique.website"})}
                           text={boutique.website} editable={editMode}
                           sx={{padding: SOCIAL_ICONS_SIZE, color: boutique.website ? '#4c934c' : '#A0A0A0'}}
                           onSetText={(value: string) => setInfos('website', value)}>
                <LanguageIcon />
              </PopoverEdit>

              <PopoverEdit key='edit-facebook' title={intl.formatMessage({id: "boutique.facebook"})}
                           text={boutique.facebook} editable={editMode}
                           sx={{padding: SOCIAL_ICONS_SIZE, color: boutique.facebook ? '#0866FF' : '#A0A0A0'}}
                           onSetText={(value: string) => setInfos('facebook', value)}>
                <FacebookIcon />
              </PopoverEdit>

              <PopoverEdit key='edit-instagram' title={intl.formatMessage({id: "boutique.instagram"})}
                           text={boutique.instagram} editable={editMode}
                           sx={{padding: SOCIAL_ICONS_SIZE, color: boutique.instagram ? '#E54859' : '#A0A0A0'}}
                           onSetText={(value: string) => setInfos('instagram', value)}>
                <InstagramIcon />
              </PopoverEdit>

            </div>
          </div>
        </Box>
        {boutique.logosUnsynced && !boutique.buildingAt &&
          <Box className='callout warning strong' sx={{flexDirection: 'row', alignItems: 'center'}}>
            <WarningIcon sx={{fontSize: 32, color: 'orange', mr: 1}} />
            <Box sx={{fontSize: '1.2em'}}>
              <FormattedMessage id={editMode ? 'boutique.edit-unsynced' : 'boutique.warning-unsynced'} />
            </Box>
            {editMode &&
            <Box sx={{flexGrow: 1, textAlign: 'right'}}>
              <BoudaToolTip title={intl.formatMessage({ id: hasError ? 'boutique.has-errors' : 'boutique.sync-logos'})}><span>
	              <CustomButton backgroundcolor='darkorange' disabled={hasError} onClick={() => {
                if (boutique) {
                  trySavingBoutique(false, true);
                }
                }}><RefreshIcon/></CustomButton>
              </span></BoudaToolTip>
            </Box>}
          </Box>
        }
        {boutique.buildingAt && boutique.duration > 0 &&
          <Box sx={{ flexGrow: 1, p: 0, ml: 0, mr: 0 }}>
            <TimeProgressBar startingTime={boutique.buildingAt} duration={boutique.duration}
                             labels={[]} completeProgress={false}
                             startingLabel={''}
                             height={5} backgroundColor={progressColors[0]}
                             barColor={progressColors[1]} borderColor={progressColors[2]}
            />
          </Box>
        }
        {boutique.collections.map((collection, idx) =>
          <CollectionCard key={`b${boutique.id}-ccard-${collection.id}`} collection={collection} editMode={editMode}
                          errorShopNbProducts={errorNbProducts} maxProducts={maxProducts}
                          first={idx === 0} last={idx === boutique.collections.length - 1}
                          move={moveCollection}/>
        )}
	      <Box sx={{opacity: workInProgress ? 0.15 : 1}}>
          {editMode && <>
            {!hasNewCollection() && <div className='add-collection'>
              <Button variant="contained" color='warning' size='small'
                      onClick={() => dispatch(addCollection(intl.formatMessage({id: 'collection.new'})))}>
                <AddIcon/> <FormattedMessage id="collection.new"/>
              </Button>
            </div>}
            {persoOptions()}
            {planOptions()}
          </> || <div className='plan-name'>
            {intl.formatMessage({id: boutique.plan === PLAN_STANDARD ? "boutique.standard" : "boutique.margins"})}
	          <IconButton onClick={() => openDocAtAnchor(locale, 'type-boutique')}>
		          <HelpIcon className="help-icon" sx={{ fontSize: 28 }} />
	          </IconButton>
          </div>
          }
          {saveActions()}
        </Box>
      </div>
      {productConfigShown && <CollectionProductConfig show={!!productConfigShown} editMode={editMode}
                                                       collectionProduct={productConfigShown}
                                                       boutique={boutique} />
      } </> ||
      <div className='empty-boutique'>
        <FormattedMessage id="boutique.loading"/><br/><br/>
        <CircularProgress color="inherit" size={60}/>
      </div>
      }
      {/*<Backdrop sx={{color: '#fff', zIndex: 1}} open={showBackDrop}>*/}
      <Backdrop sx={{color: '#fff', zIndex: 1}} open={saving || !!boutique.buildingAt}>
        <CircularProgress color="inherit"/>
      </Backdrop>
    </>
  )

}
