import React, {useContext, useEffect} from "react";
import {CatalogCategories, ICatalogCategory, ICategoryState, ICatalogState} from "../../app/catalog";
import {useDispatch} from "react-redux";
import {selectCategory} from "./catalogSlice";
import Box from "@mui/material/Box";
import {useIntl} from "react-intl";
import {useNavigate, useParams} from "react-router-dom";
import SEO from "../website/SEO";
import {I18nContext} from "../locale/LocaleWrapper";

export const categorySvgHeight = (height: number) =>
  (height === 0) ? 'clamp(1.6rem, 4vw, 3rem)' : height + 'px';

const Categories = (props: {catalog: ICatalogState}) => {
  const catalog = props.catalog;
  const dispatch = useDispatch();
  const intl = useIntl();
  const { locale } = useContext(I18nContext);
  const navigate = useNavigate();

  ///////////////////////////////////////////////////////
  // Get the category from the URL
  ///////////////////////////////////////////////////////
  const {category} = useParams();

  useEffect(() => {
    if (category !== undefined) {
      dispatch(selectCategory(category));
    }
  }, [category])

  const opacity = (categoryInfo: any) => {
    if (categoryInfo) {
      switch (categoryInfo.status) {
        case "Not Fetched":
          return 0.1;
        case "UpToDate":
          return 1;
        case "Loading":
          return 0.5;
        case "Error":
          return 0.2;
        default:
          return 1;
      }
    } else return 0;
  }

  function categoryClass(category: ICatalogCategory) {
    if (category.code === catalog.currentCategory) {
      return "active";
    } else {
      return "";
    }
  }

  const categoryProductCount = (category: ICatalogCategory) =>
    catalog.filteredProducts.filter((p) => p.category === category.code).length;

  const handleChangeCategory = (category: string) => {
    dispatch(selectCategory(category));
    navigate(`/${locale}/catalog/${category}`, { replace: true });
  }

  const categoryItem = (category: ICatalogCategory) => {
    const productCount = categoryProductCount(category);
    return (
      <li key={category.code} className={`category ${productCount === 0 ? 'empty' : ''} ${categoryClass(category)}`}
          onClick={() => handleChangeCategory(category.code)} >
        {category.icon(categorySvgHeight(0))}
        <Box sx={{display: {xs: 'none', sm: 'flex'}, justifyContent: 'center'}} >
          {`${intl.formatMessage({ id: `category.${category.code}-short`})} (${productCount})`}
        </Box>
      </li>
    )
  }

  return (<>
    <SEO
      title={`DAGOBA - ${intl.formatMessage({id: 'catalog.title'})} - ${intl.formatMessage({id: `category.${category || 'tshirts'}`})}`}
      description={category ? intl.formatMessage({id: 'category.description'},
        {category: intl.formatMessage({ id: `category.${category}`})}) :
        intl.formatMessage({id: 'catalog.description'})}
    />
    <Box>
      <ul className="categories">
        {CatalogCategories.map((category) => categoryItem(category))}
      </ul>
    </Box>
  </>);
}

export default Categories;

