import React, { useEffect, useState } from 'react';
import './logo-grid.scss';

interface LogoGridProps {
  logo: { src: string; url: string };
  size?: number; // If you want to make the size configurable
}

type FlipPhase = 0 | 1 | 2;

const LogoCard: React.FC<LogoGridProps> = ({ logo, size = 64 }) => {
  const [currentLogo, setCurrentLogo] = useState(logo);
  const [flipPhase, setFlipPhase] = useState<FlipPhase>(0);
  const [nextLogo, setNextLogo] = useState(logo);

  useEffect(() => {
    if (logo.src !== currentLogo.src && flipPhase === 0) {
      setNextLogo(logo);
      setFlipPhase(1);
    }
  }, [logo, currentLogo.src, flipPhase]);

  useEffect(() => {
    if (flipPhase === 1) {
      const timeout = setTimeout(() => {
        setCurrentLogo(nextLogo);
        setFlipPhase(2);
      }, 600); // Matches the CSS transition duration

      return () => clearTimeout(timeout); // Clean up on component unmount or phase change
    }
  }, [flipPhase, nextLogo]);

  const handleTransitionEnd = () => {
    if (flipPhase === 1) {
      setCurrentLogo(nextLogo);
      setFlipPhase(2);
    } else if (flipPhase === 2) {
      setFlipPhase(0);
    }
  };

  return (
    <div className="logo-card" style={{ width: size, height: size }}>
      <a href={currentLogo.url} target="_blank" rel="noopener noreferrer">
        <div
          className={`logo-flip-container flip-phase-${flipPhase}`}
          onTransitionEnd={handleTransitionEnd}
        >
          <img
            src={`/logos/${currentLogo.src}`}
            alt="logo"
            className="logo-image"
          />
        </div>
      </a>
    </div>
  );
};

export default LogoCard;

