import React from "react";

const CoatSvg = (height: string) => {

  return (
    <svg version="1.1" viewBox="0 0 64 64" style={{'height': height}}>
      <g className="icon">
        <path d="M25.2,9.4c0.4,0.3,1,0.8,1.7,1.3c1.2,0.8,2.8,1.7,4.6,2.3V8.4c-2.7-0.8-4.4-1.6-5.5-2.3
			c-0.7-0.4-1.1-0.9-1.3-1.3c-0.2,0.7,0,4.2,0,4.2C24.8,9,25,9.2,25.2,9.4z M26.6,5.3c1,0.7,2.6,1.4,5.4,2.2
			c2.7-0.8,4.4-1.6,5.4-2.2c1.1-0.7,1.2-1.3,1.2-1.6c0,0,0-0.1,0-0.1c-0.3-0.3-0.8-0.6-1.5-0.7C34.5,2.1,32,2.1,32,2.1
			s-2.5,0-5.1,0.7c-0.7,0.2-1.2,0.4-1.5,0.7c0,0,0,0.1,0,0.1C25.4,4,25.5,4.6,26.6,5.3z M17.3,25c-0.5-6.5-2.6-10.4-3.8-12.1
			c-2,3.2-6.7,17.9-7.3,21.3C5.8,36.6,4.8,47.5,5.1,50c0.3,0.1,0.7,0.2,1.1,0.2c0.5,0.1,1,0.2,1.7,0.3c0.2-1.3,0.5-3.9,0.9-6.6
			c0.6-4,1.2-8.3,1.8-9.9c1.4-3.8,6.7-8.9,6.7-8.9h0L17.3,25z M46.8,25.1c0,0,5.3,5.1,6.7,8.9c0.6,1.6,1.3,6,1.8,9.9
			c0.4,2.7,0.7,5.3,0.9,6.6c0.6-0.1,1.2-0.2,1.7-0.3c0.4-0.1,0.8-0.2,1.1-0.2c0.3-2.5-0.7-13.4-1.2-15.8c-0.7-3.5-5.4-18.1-7.3-21.3
			c-1.2,1.7-3.3,5.6-3.8,12.1L46.8,25.1z M4.6,51.9c-0.1,0.6-0.3,3.2,1.2,3.7c2.1,0.7,4.4,0.9,4.8-0.7c0.3-1.7,0.5-2.9,0.5-2.9
			s0,0,0,0c-2.9-0.3-5.1-0.7-6.3-1C4.7,51.2,4.7,51.6,4.6,51.9z M34.2,18.7c0,1.8-0.1,3.9-0.1,6.3c0.4,0,0.9,0,1.3,0
			c2.2,0,4.9-0.1,7-0.3c1.5-0.1,2.7-0.2,3.3-0.3c0.6-6.6,2.8-10.6,4.1-12.3c-2.1-1.6-7-3.1-9.4-3.8l-0.1,1l-0.1,0.1
			c0,0-2.4,2.3-5.8,3.7C34.3,14,34.2,15.9,34.2,18.7z M32.5,8.4v4.5c1.8-0.6,3.4-1.5,4.6-2.3c0.7-0.5,1.3-0.9,1.7-1.3
			C39,9.2,39.2,9,39.3,8.9c0,0,0.1-3.5,0-4.2c-0.3,0.4-0.7,0.9-1.3,1.3C36.9,6.8,35.2,7.6,32.5,8.4z M21.5,24.7
			c2.1,0.1,4.8,0.3,7,0.3c0.5,0,0.9,0,1.3,0c0-2.4-0.1-4.5-0.1-6.3c0-2.8-0.1-4.7-0.1-5.5c-3.4-1.5-5.8-3.7-5.8-3.7l-0.1-0.1l-0.1-1
			c-2.4,0.7-7.3,2.2-9.4,3.8c1.2,1.7,3.5,5.7,4.1,12.3C18.9,24.5,20.1,24.6,21.5,24.7z M46,32.9c-0.2-0.7-0.4-3.6-0.4-5.1
			c0-0.8,0-1.6,0.1-2.4C44,25.6,39.1,26,35.5,26c-0.5,0-0.9,0-1.4,0c0,2.1,0,4.3,0,6.6c0,8.1,0.1,17.1,0.6,24.3c4.8,0,8-0.4,9.9-0.8
			c1-0.2,1.7-0.4,2.2-0.5c0.1,0,0.2-0.1,0.3-0.1c0.4-2.1-0.3-4.7-0.8-7.6C45.8,45.1,45.8,36.3,46,32.9z M54.3,44.1
			c-0.6-3.9-1.3-8.3-1.8-9.7c-0.6-1.7-2.2-3.8-3.7-5.6c-0.7-0.9-1.4-1.6-1.9-2.1c-0.1-0.1-0.2-0.2-0.3-0.3c0,0.5,0,1,0,1.5
			c0,1.3,0.1,2.7,0.2,4.2c0.6,0.7,1.4,2,1.8,2.9c0.5,1.3,2.4,9.9,2.8,11.6c0.3,1.1,0.9,3.2,1.3,4.5c1-0.1,1.8-0.2,2.6-0.3
			C55.1,49.4,54.7,46.8,54.3,44.1z M35,60.8c4-0.2,7.3-0.7,9.4-1.1c2.5-0.5,2.2-2.2,2.4-3c0,0,0,0,0,0c-1.6,0.5-5.3,1.2-12,1.3
			C34.8,58.9,34.9,59.8,35,60.8z M59.4,51.9c-0.1-0.4-0.1-0.7-0.2-1c-1.2,0.3-3.3,0.7-6.3,1c0,0,0,0,0,0s0.2,1.2,0.5,2.9
			c0.3,1.5,2.7,1.4,4.8,0.7C59.7,55.1,59.5,52.5,59.4,51.9z M17.3,56.6c0.1,0.9-0.2,2.5,2.4,3c2.1,0.5,5.4,1,9.4,1.1
			c0.1-0.9,0.2-1.9,0.2-2.9C22.5,57.8,18.9,57.1,17.3,56.6C17.2,56.6,17.2,56.6,17.3,56.6z M17.4,27.8c0-0.5,0-1,0-1.5
			c-0.1,0.1-0.2,0.2-0.3,0.3c-0.5,0.5-1.2,1.3-1.9,2.1c-1.5,1.7-3.1,3.9-3.7,5.6c-0.5,1.4-1.2,5.8-1.8,9.7c-0.4,2.8-0.7,5.3-0.9,6.6
			c0.8,0.1,1.7,0.2,2.6,0.3c0.4-1.2,1-3.3,1.3-4.5c0.4-1.7,2.3-10.3,2.8-11.6c0.3-0.9,1.2-2.2,1.8-2.9
			C17.4,30.5,17.4,29.1,17.4,27.8z M33.3,13.6c-0.4,0.2-0.9,0.3-1.3,0.4c-0.5-0.1-0.9-0.3-1.3-0.4c0.1,2.3,0.2,10,0.2,19
			c0,9.6-0.2,20.6-0.9,28.2c0.7,0,1.3,0,2,0c0.7,0,1.4,0,2,0c-0.7-7.6-0.9-18.6-0.9-28.2C33.1,23.6,33.3,16,33.3,13.6z M28.5,26
			c-3.6,0-8.5-0.4-10.2-0.5c0,0.8,0.1,1.5,0.1,2.4c0,1.5-0.2,4.4-0.4,5.1c0.2,3.4,0.2,12.2-0.3,15c-0.5,3-1.2,5.5-0.8,7.6
			c0.1,0,0.2,0.1,0.3,0.1c0.4,0.1,1.2,0.3,2.2,0.5c2,0.4,5.2,0.8,9.9,0.8c0.5-7.2,0.6-16.3,0.6-24.3c0-2.3,0-4.5,0-6.6
			C29.5,26,29,26,28.5,26z"/>
      </g>
    </svg>
  );
}


export default CoatSvg;

