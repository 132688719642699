import React, {useEffect} from 'react';
import {useAppSelector} from "../../app/hooks";
import {
  getSelection, ISelectedProduct,
  UniqueProducts
} from "./selectionSlice";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../app/store";
import Box from "@mui/material/Box";
import {CircularProgress, Grid} from "@mui/material";
import SelectedProductCard from "./SelectedProductCard";
import {FormattedMessage, useIntl} from "react-intl";
import {getAccessToken} from "../../app/sessionAPI";
import DeleteIcon from "@mui/icons-material/Delete";
import {hideBackDrop, openConfirmDialog, openSnackBar, showBackDrop} from "../global/globalSlice";
import {cable} from "../../app/cable";

export const ProductSelection = (props:{
  title:boolean,
  catalogView: boolean,
  draggable: boolean,
  nbProducts?: number
}) => {

  const intl = useIntl();

  const { title, catalogView, draggable, nbProducts} = props;

  const products = useAppSelector(UniqueProducts);
  const selectionLoaded = useAppSelector((state: RootState) => state.selection.loaded);
  const dispatch = useDispatch<AppDispatch>();
  const accessToken = getAccessToken();

  useEffect(() => {
    if (accessToken && accessToken !== 'undefined' && !selectionLoaded) {
      // console.log("ProductSelection useEffect... dispatch getSelection");
      dispatch(getSelection());
      // console.log("ProductSelection useEffect... dispatch getBoutiques");
      // dispatch(getBoutiques());
    }
  }, [dispatch, accessToken]);


  // useEffect(() => {
  //   if (accessToken && accessToken !== 'undefined' && currentUser && currentUser?.id !== undefined) {
  //     subscribeToSelection(currentUser.id);
  //   }
  // }, [currentUser]);
  ////////////////////////////////////////////////////////////////////////////////////

  const emptySelection = () => {
    dispatch(openConfirmDialog({
      title: intl.formatMessage({ id: "selection.empty-title"}, {name: 'toto'}),
      message: intl.formatMessage({ id: "selection.empty-question"}),
      confirm: intl.formatMessage({ id: "selection.empty-confirm"}),
      actionMethod: 'emptySelection'
    }));
  }

  // console.log("ProductSelection rendering... with selection = ", products);
  return(
    <Box
      sx={{maxWidth: '180px'}}
    >
      <Grid container spacing={1} className='selection'>
        {title &&
          <Grid item xs={12} className='selection-header'>
            <div className='title'>
	          <FormattedMessage id="selection.my-selection" /> ({!!nbProducts && nbProducts})
            </div>
            {products && products.length > 0 &&  <DeleteIcon className='empty-button' fontSize='small' onClick={emptySelection} />}
          </Grid>
        }
        <>
          {accessToken && !selectionLoaded &&
	          <div className='empty-selection'>
		          <FormattedMessage id="selection.loading" /><br/><br/>
		          <CircularProgress color="inherit" size={60} />
            </div>
            ||
            (accessToken && products && products.length > 0 && products
              .filter((p) => p.product).map((p: ISelectedProduct, i: number) =>
              <SelectedProductCard key={p.product.id} selectedProduct={p} index={i}
                                   catalogView={catalogView} draggable={draggable} />
            )) ||
              <div className='empty-selection'>
                <FormattedMessage id="selection.selection-explanation" />
              </div>
          }
        </>
      </Grid>
    </Box>

  )
}
