import React from "react";

const JacketSvg = (height: string) => {

  return (
    <svg version="1.1" viewBox="0 0 64 64" style={{'height': height}}>
      <g className="icon">
        <path d="M11.6,23.1c0.5-1.3,0.7-2.5,0.6-3.4c-1,1.9-2,4-2.9,6.4C10.1,25.5,11,24.6,11.6,23.1L11.6,23.1z"/>
        <path d="M52.6,22.4c0.7,2,2,3.1,2.8,3.7c-1-2.5-2.1-4.7-3.2-6.8C52.2,20.1,52.2,21.2,52.6,22.4L52.6,22.4z"/>
        <path d="M20.9,48.1c0.7-0.3,2.5-1.3,3.5-3.6c0.8-2,0.7-3.8,0.5-4.8c-0.1-0.5,0.2-0.9,0.6-1c0.4-0.1,0.8,0.2,0.9,0.7
					c0.2,1.1,0.4,3.4-0.6,5.8c-1.3,2.9-3.5,4.1-4.4,4.5c-0.1,0-0.1,0-0.2,0.1c-0.3,0-0.7-0.2-0.8-0.6C20.3,48.8,20.5,48.3,20.9,48.1
					L20.9,48.1z M10.9,50l0.5-1.6c0.1-0.4,0-0.8-0.3-1L17,33.2c0.1-0.3,0.5-0.2,0.5,0.1L19.4,57c0.1,0.8,0.7,1.5,1.4,1.5
					c0,0,0,0.1,0,0.1v1.7c0,0.4,0.3,0.8,0.7,0.8h9.9V3.4c-2.4-0.1-4.5-0.6-6-1.1c-0.6-0.2-1.2,0.3-1.2,0.9L24,8h-1.8
					c-1.1,0-2.2,0.4-3.1,1c-0.8,0.6-1.3,1.3-1.6,1.9c-0.5,0.8-0.9,1.6-1.5,2.4c-0.9,1.3-1.8,2.7-2.7,4.3c0.1,0.1,0.2,0.3,0.3,0.5
					c0.2,1.1,0.4,3.4-0.6,5.8c-1.3,2.9-3.5,4.1-4.4,4.5c-0.1,0-0.1,0-0.2,0.1c0,0,0,0,0,0c-0.1,0.3-0.2,0.6-0.3,0.8
					c-2,5.9-3,11.2-3.5,15.5c-0.4-0.1-0.8,0.2-1,0.6L3.1,47c-0.1,0.4,0.1,0.8,0.4,1l6.4,2.5C10.4,50.6,10.8,50.4,10.9,50L10.9,50z"
        />
        <path d="M43.6,49.5C43.5,49.8,43.2,50,43,50c-0.1,0-0.3,0-0.4-0.1c-0.9-0.5-3-1.8-4.1-4.9c-0.9-2.5-0.5-4.7-0.2-5.9
					c0.1-0.5,0.6-0.7,1-0.6c0.4,0.1,0.7,0.6,0.5,1.1c-0.2,0.9-0.5,2.7,0.2,4.8c0.8,2.5,2.6,3.6,3.3,3.9
					C43.6,48.6,43.8,49.1,43.6,49.5L43.6,49.5z M61,44.9c-0.1-0.3-0.3-0.5-0.6-0.6v0c-0.6-4.4-1.8-10.1-4.2-16.2
					c0-0.1-0.1-0.2-0.1-0.4c-0.1,0.1-0.3,0.2-0.5,0.2c-0.1,0-0.3,0-0.4-0.1c-0.9-0.5-3-1.8-4.1-4.9c-0.9-2.5-0.5-4.7-0.2-5.9
					c0,0,0,0,0-0.1c-0.6-1.1-1.3-2-1.9-3c-0.7-1.1-1.5-2.2-2.1-3.3C46,9,44.2,8,42.3,8H41L40.5,3c-0.1-0.6-0.7-1-1.2-0.8
					c-1.4,0.5-3.3,1.1-5.6,1.2v57.6h9.9c0.4,0,0.7-0.3,0.7-0.8v-1.7c0,0,0,0,0,0c0.6-0.1,1.1-0.7,1.2-1.4l2.2-23.9
					c0-0.3,0.4-0.4,0.5-0.1l5.5,13.7l0,0c-0.3,0.2-0.5,0.7-0.3,1.1l0.5,1.6c0.1,0.4,0.5,0.6,0.9,0.5l6.4-2.6c0.4-0.1,0.5-0.6,0.4-1
					L61,44.9L61,44.9z"/>
      </g>
    </svg>
  );
}


export default JacketSvg;

