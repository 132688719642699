import {Testimonial} from "../features/utils/TestimonialCard";

export const BOUTIQUE_LOGOS = [
  { src: 'Archers.png', url: 'https://archers-de-coueron.dagoba.app/' },
  { src: 'Astre.png', url: 'https://astre.dagoba.app/' },
  { src: 'Belley.png', url: 'https://fjbelley-basket.dagoba.app/' },
  { src: 'Bitume.png', url: 'https://fdb-hockey.dagoba.app/' },
  { src: 'Blizzards.png', url: 'https://les-blizzards.dagoba.app/' },
  { src: 'Chouettes.png', url: 'https://les-chouettes.dagoba.app/' },
  { src: 'Dizier.png', url: 'https://cosd-volley-ball.dagoba.app/' },
  { src: 'GreenTeam.png', url: 'https://greenteam57.dagoba.app/' },
  { src: 'Handball.png', url: 'https://csvv-hand-ball.dagoba.app/' },
  { src: 'Hyenes-Aubergenville.png', url: 'https://hyenes.dagoba.app/' },
  { src: 'Peyrehorade.png', url: 'https://psf.dagoba.app/' },
  { src: 'Royas.png', url: 'https://usbrfoot.dagoba.app/' },
  { src: 'Shindozen.png', url: 'https://shindozenkarate.dagoba.app/' },
  { src: 'SSEJM.png', url: 'https://ssejm.dagoba.app/' },
  { src: 'StreetSharks.png', url: 'https://street-sharks-de-antony.dagoba.app/' },
  { src: 'Urban Arts.png', url: 'https://urban-arts-academy.dagoba.app/' },
  { src: 'VCHettange.png', url: 'https://vc-hettange-grande.dagoba.app/' },
  { src: 'Viguie.png', url: 'https://vs-a.dagoba.app/' },
  { src: 'Vivonnes-Volley.png', url: 'https://union-sportive-vivonne-volley-ball.dagoba.app/' },
  { src: 'WadoryuKaraté.png', url: 'https://cl-wadoryu-kara-sud-atlantique.dagoba.app/' },
  { src: 'Ailes.png', url: 'https://boutique.adm-avion.com/' },
  { src: 'AMOMER.png', url: 'https://amo-mer-athletisme.dagoba.app/' },
  { src: 'Atlantes.png', url: 'https://atlantes-cote-basque.dagoba.app/' },
  { src: 'Bouffere.png', url: 'https://bouffere-volleyball.dagoba.app/' },
  { src: 'Chiefs.png', url: 'https://loire-divatte-roller.dagoba.app/' },
  { src: 'Clisson.png', url: 'https://volley-club-clisson.dagoba.app/' },
  { src: 'Coleyrac.png', url: 'https://jil-colayrac-basket.dagoba.app/' },
  { src: 'Cosne.png', url: 'https://ucs-cosne-triathlon.dagoba.app/' },
  { src: 'Dinan.png', url: 'https://dinan-basket-samsonnais.dagoba.app/' },
  { src: 'Dojo.png', url: 'https://dojobg.dagoba.app/' },
  { src: 'Guimgamp.png', url: 'https://guingamp-volley-ball.dagoba.app/' },
  { src: 'Houpeville.png', url: 'https://montville-houppeville-basket-ball.dagoba.app/' },
  { src: 'ISPA.png', url: 'https://ispa.dagoba.app/' },
  { src: 'JAC.png', url: 'https://jac-nantes.dagoba.app/' },
  { src: 'JCPithiviers.png', url: 'https://jc-pithiviers.dagoba.app/' },
  { src: 'KBCM.png', url: 'https://kbcm.dagoba.app/' },
  { src: 'KM43.png', url: 'https://krav-maga-43.dagoba.app/' },
  { src: 'Lavaur.png', url: 'https://lavaur-judo.dagoba.app/' },
  { src: 'Menival.png', url: 'https://csmenival.dagoba.app/' },
  { src: 'Motards.png', url: 'https://motards-du-bas-bugey.dagoba.app' },
  { src: 'Nérondes.png', url: 'https://bc-nerondes.dagoba.app' },
  { src: 'Randriam.png', url: 'https://taekwondo-basse-goulaine.dagoba.app/' },
  { src: 'Royan.png', url: 'https://regatesderoyan.dagoba.app/' },
  { src: 'SaintJulienRosiers.png', url: 'https://saintjulienlesrosiersbasket.dagoba.app/' },
  { src: 'saintmarceljudo.png', url: 'https://judo-club-saint-marcellois.dagoba.app/' },
  { src: 'SaintMaur.png', url: 'https://cie-arc-saint-maur.dagoba.app/' },
  { src: 'Smarves.png', url: 'https://smarves-basket.dagoba.app/' },
  { src: 'Tina.png', url: 'https://studio-tina.dagoba.app/' },
  { src: 'USMA.png', url: 'https://usma-tennis-86.dagoba.app/' }
];

export const TESTIMONIALS: Testimonial[] = [
  {
    logo: 'KBCM.png',
    fullName: 'Corentin Letellier',
    title: 'Responsable boutique',
    organization: 'Kangous Basket Club',
  },
  {
    logo: 'Belley.png',
    fullName: 'Frédéric Charre',
    title: 'Responsable boutique',
    organization: 'FJ Belley Basket',
  },
  {
    logo: 'Cosne.png',
    fullName: 'Michel Lutignier',
    title: 'Président',
    organization: 'Cosne Triathlon',
  },
  {
    logo: 'Clisson.png',
    fullName: 'Nadège Roguet',
    title: 'Responsable sponsoring',
    organization: 'Volley Club Clisson',
  },
  {
    logo: 'SaintJulienRosiers.png',
    fullName: 'Arnaud Saintperre',
    title: 'Co-président',
    organization: 'Saint Julien Les Rosiers Basket Ball',
  },
  {
    logo: 'JAC.png',
    fullName: 'Christophe Bouard',
    title: 'Secrétaire',
    organization: 'Judo Atlantic Club',
  },
  {
    logo: 'Moliere.png',
    fullName: 'Merlin Gouesse',
    title: 'Délégué des étudiants',
    organization: 'Prépa du lycée Molière, Paris XVI',
  },
  {
    logo: 'AVG.png',
    fullName: 'Joris Rougier',
    title: 'Directeur Administratif',
    organization: 'AVG Omnisports',
  },
  {
    logo: 'fleches.png',
    fullName: 'Loïc Meneghetti',
    title: 'Responsable boutique',
    organization: 'Les Flèches du Vuache',
  }
];

export const openDocAtAnchor = (locale:string, anchor: string) => {
  window.open(process.env.REACT_APP_PUBLIC_URL + `/${locale}/help` + '#' + anchor, '_blank');
  // window.open(process.env.REACT_APP_PUBLIC_URL + `/${locale}/help` + '#' + anchor, '_self');
}