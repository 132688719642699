import React, {useState} from 'react';
import {ILogoInfos, ILogoVersion} from "../logos/logosSlice";
import LogoVersionSelect from "../logos/LogoVersionSelect";
import {DefaultLogoVersion, IAddonInfos, ICollectionProduct, IImageAddon} from "./boutiqueSlice";
import {FormattedMessage} from "react-intl";
import {ToggleButton, ToggleButtonGroup, useMediaQuery} from "@mui/material";

export const SelectAddonLogo = (props: {
  collectionProduct: ICollectionProduct,
  logos: ILogoInfos[],
  imageAddon: IImageAddon,
  addonInfo: IAddonInfos,
  selectLogoVersion: (logoIds: number[], logoVersionIds: number[]) => void,
  designateLogoVersion: (logoId: number, logoVersionId: number) => void,
  noneAllowed: boolean,
  editMode: boolean,
  multiLogos: boolean
}) => {

  const matches = useMediaQuery("(min-width:800px)");

  const {collectionProduct, logos, imageAddon, addonInfo, selectLogoVersion, designateLogoVersion,
    noneAllowed, editMode, multiLogos} = props;

  const category = `${collectionProduct.product.category}${collectionProduct.product.subCategory}`;
  const junior = collectionProduct.product.gender === 3;

  // for each logo available in the boutique, we store the current version id
  // (either the one already saved or the default one, or the newly selected one)
  const [logosCurrentVersionIds, setLogosCurrentVersionIds] = useState<number[]>(logos.map(logo => {
    if (addonInfo.logoIds.includes(logo.id)) {
      return addonInfo.logoVersionIds[addonInfo.logoIds.indexOf(logo.id)];
    } else {
    return DefaultLogoVersion(imageAddon, logo, junior, category)?.id || logo.versions[0]?.id || 0;
    }
  }));

  const handleSelectLogo = (
    event: React.MouseEvent<HTMLElement>,
    newValue: number | number[] | null
  ) => {

    // if (multiLogos) {
    //   // In non-exclusive mode, newValue is an array of numbers (or null)
    //
    // } else {
    //   // In exclusive mode, newValue is a single number (or null)
    //   const newLogoId = newValue as number | null;
    //   console.log("handleSelectLogo, new logo " + newLogoId);
    //
    //   const currentVersionId = logosCurrentVersionIds[logos.findIndex(logo => logo.id === newLogoId)];
    //   if (newLogoId && newLogoId !== addonInfo.logoId) {
    //     selectLogoVersion([newLogoId], [currentVersionId]);
    //   }
    // }

    let newLogoIds: number[];
    if (multiLogos) {
      // In non-exclusive mode, newValue is an array of numbers (or null)
      newLogoIds = (newValue as number[] | null) || [];

      // do not allow to remove all logos if not first color
      if (!noneAllowed && (newLogoIds.includes(0) || newLogoIds.length === 0)) {
        return;
      }
    } else {
      newLogoIds = newValue ? [newValue as number] : [];
    }

    // console.log("handleSelectMultiLogo, new logos " + newLogoIds);
    selectLogoVersion(newLogoIds, newLogoIds
      .map(logoId => logosCurrentVersionIds[logos.findIndex(logo => logo.id === logoId)]));

  };

  const handleSelectLogoVersion = (newLogoId: number, logoVersionId: number) => {

    // update in the state the current logoVersionId for the selected logo, other logos are not changed
    const newCurrentVersions = logosCurrentVersionIds.map((currentVersionId, index) => {
      if (logos[index].id === newLogoId) {
        return logoVersionId;   // update the version for the selected logo
      } else {
        return currentVersionId;  // keep the version for the other logos
      }
    });
    // update the state
    setLogosCurrentVersionIds(newCurrentVersions);

    // we need to tell the parent component that the logo version has changed
    // 2 cases : logo is already selected and its version is changing, or a new logo is selected
    designateLogoVersion(newLogoId, logoVersionId);
  }

  // const toogleButtonGroupValue = multiLogos ? (addonInfo.logoIds || []) : addonInfo.logoId;
  let toggleButtonGroupValue = addonInfo.logoIds;
  if (toggleButtonGroupValue.length === 0) {
    toggleButtonGroupValue = [0];
  }

  // if (imageAddon.name === 'big-logo-chest') {
  //   console.log("%cRender SelectAddonLogo for addon " + imageAddon.name + " toogleButtonGroupValue " + toggleButtonGroupValue, 'color: #ff0000');
  // }

  return (
    <ToggleButtonGroup orientation={`${matches ? `horizontal` : `vertical`}`} size="small" disabled={!editMode}
                       value={toggleButtonGroupValue}
                       exclusive={!multiLogos} onChange={handleSelectLogo}>

      <ToggleButton key={`${imageAddon.id}-0`} value={0} size="small" className='select-logo-none' disabled={!noneAllowed}>
        <span><FormattedMessage id="global.none" /></span>
      </ToggleButton>

      {logos.map((logo: ILogoInfos, version_idx: number) => (
        <ToggleButton key={`${imageAddon.id}-${version_idx}`} value={logo.id} size="small" className='select-logo-button'>
          <LogoVersionSelect collectionProduct={collectionProduct} imageAddon={imageAddon} logo={logo}
             versionId={logosCurrentVersionIds[version_idx]} setVersionId={handleSelectLogoVersion}
                             displayNumber={(multiLogos && addonInfo.logoIds.length > 1) ? (addonInfo.logoIds.indexOf(logo.id) + 1) : 0}
          />
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}
