import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Alert, AlertTitle,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  FormControl,
  FormGroup,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from "@mui/material";
import React, {ReactElement, useContext, useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link, useNavigate, useParams} from "react-router-dom";
import {
  loginUserByEmail,
  loginUserByToken,
  resetErrorState,
  sendMagicLink,
  sendPasswordLink,
  UserLoginData
} from "./sessionSlice";
import {RootState} from "../../app/store";
import {FormattedMessage, useIntl} from "react-intl";
import {CustomButton} from "../utils/CustomButton";
import {openSnackBar} from "../global/globalSlice";
import {I18nContext} from "../locale/LocaleWrapper";


function Login() {

  const intl = useIntl();
  const { locale } = useContext(I18nContext);
  const waiting = useSelector((state: RootState) => state.session.waiting);
  const emailRef = useRef<HTMLInputElement>();
  const passwordRef = useRef<HTMLInputElement>();
  const errorMessages = useSelector((state: RootState) => state.session.errorMessages);
  const [errors, setErrors] = useState<Array<string>>([])
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [successMessageId, setSuccessMessageId] = useState<string>("")

  const {magic_token } = useParams();

  async function signinByToken(token:string) {
    setErrors([]);

    const response = await dispatch(loginUserByToken(token)) as any;

    if (response.type === 'session/loginUserByToken/fulfilled') {
      doLogin();
    }
  }

  useEffect(() => {
    if (magic_token !== undefined) {
      signinByToken(magic_token);
    }
  }, [magic_token])

  useEffect(() => {
    emailRef?.current?.focus();
    if (errorMessages.length > 0) {
      setErrors(errorMessages.map((message: string) => intl.formatMessage({id: 'error.' + message}, {
        email: emailRef?.current?.value,
        b: ((chunks : ReactElement[]) => {
          return(<b>{chunks}</b>)
        }) as any
      })));
      dispatch(resetErrorState());
    }
  }, [errorMessages])

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setErrors([]);
    setSuccessMessageId("")

    if (emailRef?.current === undefined
      || emailRef.current.value === ""
      || passwordRef?.current === undefined
      || passwordRef.current.value === "") {
      return setErrors([intl.formatMessage({id: "global.fill-out-fields"})])
    }

    const payload = {
      email: emailRef.current.value,
      password: passwordRef.current.value
    }
    const response = await dispatch(loginUserByEmail(payload)) as any;

    if (response.type === 'session/loginUserByEmail/fulfilled') {
      doLogin();
    }
  }

  const doLogin = () => {
    navigate(`/${locale}`);
    dispatch(openSnackBar({
      severity: "success",
      message: intl.formatMessage({id: "session.signin-success"})
    }));
  }

  async function handleMagicLink() {
    setSuccessMessageId("")
    if (emailRef?.current === undefined || emailRef.current.value === "") {
      return setErrors([intl.formatMessage({id: "session.type-email"})])
    }

    const response = await dispatch(sendMagicLink({email: emailRef.current.value, locale: locale})) as any;

    if (response.type === 'session/sendMagicLink/fulfilled') {
      setSuccessMessageId("session.magic-link-sent")
      setErrors([]);
    }
  }

  async function handlePasswordLink() {
    setSuccessMessageId("")
    if (emailRef?.current === undefined || emailRef.current.value === "") {
      return setErrors([intl.formatMessage({id: "session.type-email"})])
    }

    const response = await dispatch(sendPasswordLink({email: emailRef.current.value, locale: locale})) as any;

    if (response.type === 'session/sendPasswordLink/fulfilled') {
      setSuccessMessageId("session.reset-password-sent")
      setErrors([]);
    }
  }

  const emailLabel = intl.formatMessage({id: "session.email"})
  const passwordLabel = intl.formatMessage({id: "session.password"})

  const passwordInput = <OutlinedInput
    id="password" type={showPassword ? 'text' : 'password'} label={passwordLabel} inputRef={passwordRef}
    endAdornment={
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => setShowPassword(!showPassword)}
          onMouseDown={() => setShowPassword(!showPassword)}
          edge="end">
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    } />;

  return (
    <section style={{marginTop:"2em"}}>
      <Container maxWidth="md">
        <Card sx={{boxShadow:1, maxWidth: 'md'}}>
          <CardContent>
            <Container maxWidth="sm">
              <Typography variant="h4" color="text.primary" gutterBottom>
                {intl.formatMessage({id: "session.login"})}
              </Typography>
              {!waiting && errors.length > 0 ?
                <Alert severity="error" aria-live="assertive" className='alert'>
                  <AlertTitle>{intl.formatMessage({id: "global.error"})}</AlertTitle>
                  {errors.map((error, index) => {
                    return <p key={`alert-${index}`}>
                      {error}
                    </p>
                  })}
                </Alert>
              : <></>}
              <form onSubmit={handleSubmit} className='session-form'>
                <FormGroup row={true} id="email-group" sx={{marginTop: "1em"}}>
                  <FormControl fullWidth>
                    <InputLabel required htmlFor="email" id="email-label">{emailLabel}</InputLabel>
                    <OutlinedInput id="email" type="email" label={emailLabel} inputRef={emailRef}/>
                  </FormControl>
                </FormGroup>
                <FormGroup row={true} id="password-group" sx={{marginTop: "1em"}}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="password" id="password-label">{intl.formatMessage({id: "session.password"})}</InputLabel>
                    {passwordInput}
                  </FormControl>
                </FormGroup>
                <FormGroup row={true} sx={{marginTop: "1em"}}>
                  <FormControl fullWidth>
                    <Button 
                      disabled={waiting}
                      variant="contained" 
                      color="primary" 
                      type="submit" 
                      id="submit-button">{intl.formatMessage({id: "session.login"})}</Button>
                  </FormControl>
                </FormGroup>
                <FormGroup row={true} sx={{marginTop: "1em"}}>
                  <FormControl fullWidth>
                    <CustomButton
                      disabled={waiting}
                      variant="contained"
                      backgroundcolor='#D0F0E0'
                      textcolor='#000000'
                      hoverbackgroundcolor='#B0B0B0'
                      onClick={handleMagicLink}
                      id="magic-button">{intl.formatMessage({id: "session.send-magic"})}</CustomButton>
                  </FormControl>
                </FormGroup>
                <FormGroup row={true} sx={{marginTop: "1em"}}>
                  <FormControl fullWidth>
                    <CustomButton
                      disabled={waiting}
                      variant="contained"
                      backgroundcolor='#E0D0E0'
                      textcolor='#000000'
                      hoverbackgroundcolor='#B0B0B0'
                      onClick={handlePasswordLink}
                      id="send-password">{intl.formatMessage({id: "session.send-password"})}</CustomButton>
                  </FormControl>
                </FormGroup>
                { !waiting && successMessageId && <>
			              <br />
                    <Alert severity="success">
				              <AlertTitle>{intl.formatMessage({id: "global.success"})}</AlertTitle>
				              <FormattedMessage id={successMessageId} values={{
                        b: chunks => <b>{chunks}</b>,
                        email: emailRef?.current?.value }}
				              />
			              </Alert>
                </>
                }
              </form>
            </Container>
          </CardContent>
          <Divider light={false} />
          <CardActions sx={{marginTop: "1em", justifyContent: 'center', fontSize: "1.3em" }} disableSpacing >
            <Box>
              <Link to="/signup">{intl.formatMessage({id: "session.signup"})}</Link>
            </Box>
          </CardActions>
        </Card>
      </Container>

    </section>
  )
}

export default Login