import React from 'react';
import { Box, Typography } from '@mui/material';
import {IntlShape} from "react-intl";

export interface Testimonial {
  logo: string;       // URL to the logo image
  fullName: string;   // Person's name
  title: string;      // Person's title
  organization: string;
}

interface TestimonialCardProps {
  testimonial: Testimonial;
  intl: IntlShape
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({ testimonial, intl }) => {
  const textkey = `testimonials.text-${testimonial.logo.toLowerCase().split('.')[0]}`;

  return (
    <Box className='testimonial-container'>
      <Box className='testimonial-card'>
        <Box className='testimonial-content'>
          <Box className='testimonial-logo'>
            <img src={`/logos/${testimonial.logo}`} alt={`${testimonial.fullName}-logo`} />
          </Box>
          <Box className='testimonial-text'>
            « {intl.formatMessage({id: textkey})} »
          </Box>
        </Box>
        <Box className='testimonial-signature'>
          <Box className='name'>
            {testimonial.fullName}
          </Box>
          <Box className='role'>
            {testimonial.title}
          </Box>
          <Box className='organization'>
            {testimonial.organization}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TestimonialCard;
