import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";
import {deleteLogo, getBoudistLogos, updateLogo} from "../../app/logosAPI";

export interface LogosState {
  allLogos: ILogoInfos[];
  loaded: boolean;
  editedLogo: ILogoInfos | null;
  hasChanges: boolean;
  changesList: ILogoVersionChange[];
  imageChanged: boolean;
  saving: boolean;
  error: boolean;

  searchedVendor: string;
}

export const MAX_LOGO_FILE_SIZE = 5000000; // 10 MB

// the logo name will become a file name, so filter out characters that are not allowed
// while typing we allow the hyphen at the end, but not at the beginning
export const NAME_FILTER_REGEX = /[^a-z0-9- ]+|^-+|^ +/gi;
// upon validation we remove the hyphen at the end
export const NAME_VALIDATION_REGEX = /[^a-z0-9- ]+|-+| +$/gi;


// Logo techniques (from logo.rb)
export const TECHNIQUE_DTF = 0
export const TECHNIQUE_DTG = 1
export const TECHNIQUE_EBD = 2
export const TECHNIQUE_STC = 3

export const anchorNameForTechnique = (technique: number) => {
  switch (technique) {
    case TECHNIQUE_DTF:
      return 'dtf-description';
    case TECHNIQUE_EBD:
      return 'emb-description';
    default:
      return '';
  }
}

const MINIMUM_LEAST_DIMENSION = 10
const MAXIMUM_AREA = 600
const MAXIMUM_WIDTH = 280

export const SIZE_CODE_CAP = 7

export const LOGO_LIMITS = [
  { code: 1, area: 30, name: 'S0', price: 445, width: 70, height: 0 },
  { code: 2, area: 0, width: 90, height: 50 },
  { code: 3, area: 75, name: 'S1', price: 495, width: 90, height: 0 },
  { code: 4, area: 150, name: 'S2', price: 645, width: 250, height: 0 },
  { code: 5, area: 300, name: 'S3', price: 795, width: 260, height: 0 },
  { code: 6, area: MAXIMUM_AREA, name: 'S4', price: 945, width: MAXIMUM_WIDTH, height: 0 },
  { code: SIZE_CODE_CAP, area: 0, width: 90, height: 50 },
]

export const FOR_LIGHT_BG = 0
export const FOR_DARK_BG = 1
export const FOR_ALL_BG = 2

export interface ILogoInfos {
  id: number;
  name:string;
  automated: boolean;
  forDarkBg: number;
  technique: number;
  logoUrl: string;
  file?: File | string | null;
  versions: ILogoVersion[];
  ratio: number;
  usage: IBoutiqueLogoUsage[];
  boutiques: number[];
  vendorId: number | null;
  pixelsWidth: number;
  pixelsHeight: number;
  resolution: string;
  lowestResolution: number;
  pdfFile: File | string;
  pdfFileName: string;
  embFile: File | string;
  embFileName: string;
  dstFile: File | string;
  dstFileName: string;
  redoPrintVersions?: boolean;
}

export interface ILogoVersion {
  id: number;
  name: string;
  width: number;
  height: number;
  sizeCode: number;
  areaCode: string;
  priceCents: number;
  boutiqueLogoId: number | null;
  boutiqueLogoPrintFiles: string;
  deleted?: boolean;
}

export interface ILogoVersionChange {
  logoVersionId: number;
  field: string;
  // value: string | number | null;
}

export interface IColorLogoUsage {
  color: string;
  logoVersionId: number;
}
export interface IProductLogoUsage {
  product: string;
  colors: IColorLogoUsage[];
}

export interface ICollectionLogoUsage {
  collection: string;
  products: IProductLogoUsage[];
}
export interface IBoutiqueLogoUsage {
  boutique: string;
  collections: ICollectionLogoUsage[];
}

export const logoUsageExplanation = (logo: ILogoInfos) =>
  logo.usage.map((boutiqueUsage) =>
    `<h3>${boutiqueUsage.boutique}</h3>${boutiqueUsage.collections
      .map((collectionUsage) =>
        `<h4>${collectionUsage.collection}</h4>${collectionUsage.products
          .map((productUsage) =>
            `<h5>${productUsage.product}</h5>${Array.from(new Set(productUsage.colors // remove duplicates
              .map((colorUsage) =>
                `<h6>${colorUsage.color}</h6>`
              ))).join('')}`
          ).join('')}`
      ).join('')}`
  ).join('');

export const logoUsageExplanationHTML = (logo: ILogoInfos) =>
    logoUsageExplanation(logo).replace(/\n/g, '<br/>')

// returns the list of product and colors that use that logo version with the logo version id
export const logoVersionUsageProducts = (logo: ILogoInfos, logoVersionId: number) =>
    logo.usage.flatMap((boutiqueUsage) => boutiqueUsage.collections
        .flatMap((collectionUsage) => collectionUsage.products
            .filter((productUsage) => productUsage.colors
                .some((colorUsage) => colorUsage.logoVersionId === logoVersionId))
            .flatMap((productUsage) => (
              {
                product: productUsage.product,
                colors: productUsage.colors
                    .filter((colorUsage) => colorUsage.logoVersionId === logoVersionId)
                    .map((colorUsage) => colorUsage.color)
              })
            ))).map((pc) => `<li>${pc.product} (${pc.colors.join(', ')})</li>`).join('')

// const resulat = [
//   {"product": "T-Shirt Action", "colors": ["Rouge/Blanc", "Noir/Jaune Fluo"]},
//   {"product": "Veste BR11", "colors": ["Rouge/Blanc"]},
//   {"product": "Débardeur Racing", "colors": ["Rouge", "Émeraude"]},
//   {"product": "Ensemble Atlanta","colors": ["Rouge/Blanc"]
// }]

export const NEW_LOGO_NAME = '???';

export const newLogo = {
  id: 0,
  name: NEW_LOGO_NAME,
  automated: false,
  forDarkBg: 0,
  technique: 0,
  logoUrl: '',
  file: null,
  versions: [],
  usage: [],
  boutiques: [],
  vendorId: null,
};

const initialState: LogosState = {
  allLogos: [],
  loaded: false,
  editedLogo: null,
  hasChanges: false,
  changesList: [],
  imageChanged: false,
  saving: false,
  error: false,
  searchedVendor: ''
};


export const getlogos = createAsyncThunk(
  "logos/getLogos",
  async (searchVendor: string, {rejectWithValue}) => {

    const response = await getBoudistLogos(searchVendor);
    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return rejectWithValue(response.data);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const saveLogo = createAsyncThunk(
  "logos/saveLogo",
  async (payload: ILogoInfos, thunkAPI) => {

    const response = await updateLogo(payload);

    if (response.error) {
      return thunkAPI.rejectWithValue(response);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }

);

export const removeLogo = createAsyncThunk(
  "logos/removeLogo",
  async (payload: number, thunkAPI) => {

    const response = await deleteLogo(payload);

    if (response.error) {
      return thunkAPI.rejectWithValue(response);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

const update_area_code = (version: ILogoVersion) => {
  const area_cm2 = Math.round(version.width * version.height / 100);

  const limit = LOGO_LIMITS.find(l => area_cm2 <= l.area);

  // update the version in editedLogo with the new area code and price
  if (limit && limit.name ) {
    version.areaCode = limit.name;
    version.priceCents = limit.price;
  }
}

const updateHasChanges = (state: LogosState) => {

  if (state.editedLogo !== null && state.editedLogo.id !== null) {
    const currentLogoId = state.editedLogo.id;
    const logoIndex = state.allLogos.findIndex((l) => l.id === currentLogoId);

    if (logoIndex !== -1) {
      // console.log('%cComparing edited logo to logo index ' + logoIndex, 'color:white; background-color:black');
      state.hasChanges = JSON.stringify(state.editedLogo) !== JSON.stringify(state.allLogos[logoIndex]);
      state.imageChanged = state.editedLogo.logoUrl !== state.allLogos[logoIndex].logoUrl;

      // go over each logo version and check what has changed, and save it to the changesList
      state.changesList = state.editedLogo.versions.map((version) => {
        const originalVersion = state.allLogos[logoIndex].versions.find((v) => v.id === version.id);
        if (originalVersion) {
          const changes = Object.keys(version).filter((key) => version[key as keyof ILogoVersion] !== originalVersion[key as keyof ILogoVersion]);
          return changes.map((field) => {
            return {
              logoVersionId: version.id,
              field: field,
//              value: version[field as keyof ILogoVersion]
            }
          });
        } else {
          if (version.id < 0) {
            // return all fields
            return Object.keys(version)
              .map((fieldName) => ({logoVersionId: version.id, field: fieldName}));
          }
        }
        return [];
      }).flat();

    } else {
      // console.log('%cSetting hasChanges to false because ' + JSON.stringify(state.editedLogo), 'color:white; background-color:black');
      state.hasChanges = true;
      state.imageChanged = true;
    }
  } else {
    // console.log('%cSetting hasChanges to false because ' + JSON.stringify(state.editedLogo), 'color:white; background-color:black');
    state.hasChanges = false;
    state.imageChanged = false;
    state.changesList = [];
  }
}

const storeLogo = (state: LogosState, logo: ILogoInfos) => {

  // console.log('In logosSlice, store logo', JSON.stringify(logo));
  if (logo.id !== null) {

    state.editedLogo = logo;

    const logoIndex = state.allLogos.findIndex((l) => l.id === logo.id);
    if (logoIndex !== -1) {
      state.allLogos[logoIndex] = logo;
    } else {
      state.allLogos.push(logo);
    }
  }
  state.hasChanges = false;
  state.imageChanged = false;
  state.changesList = [];

}

const constraintDimensions = (ratio: number, version: ILogoVersion) => {

  // constraint the smallest side to MINIMUM_LEAST_DIMENSION
  // and the maximum area to be MAXIMUM_AREA
  // and the maximum width to be MAXIMUM_WIDTH
  if (ratio >= 1) {
    if (version.width < MINIMUM_LEAST_DIMENSION) {
      version.width = MINIMUM_LEAST_DIMENSION;
      version.height = Math.round(version.width * ratio);
    }
    // check if we are over the maximum area
    const area_cm2 = Math.round(version.width * version.height / 100);
    if (area_cm2 > MAXIMUM_AREA) {
      version.width = Math.round(Math.sqrt(MAXIMUM_AREA * 100 / ratio));
      version.height = Math.round(version.width * ratio);
    }
  } else {
    if (version.height < MINIMUM_LEAST_DIMENSION) {
      version.height = MINIMUM_LEAST_DIMENSION;
      version.width = Math.round(version.height * 1.0 / ratio);
    }

    if (version.width > MAXIMUM_WIDTH) {
      version.width = MAXIMUM_WIDTH;
      version.height = Math.round(version.width * ratio);
    }

    // check if we are over the maximum area
    const area_cm2 = Math.round(version.width * version.height / 100);
    if (area_cm2 > MAXIMUM_AREA) {
      version.height = Math.round(Math.sqrt(MAXIMUM_AREA * 100 * ratio));
      version.width = Math.round(version.height * 1.0 / ratio);
    }
  }
}

export const logosSlice = createSlice({
  name: "logos",
  initialState,
  reducers: {
    setLogos: (state, action) => {
      // console.log('setLogos', JSON.stringify(action.payload));
      const currentEditedLogoId = state.editedLogo?.id;
      state.allLogos = action.payload.logos;
      state.searchedVendor = action.payload.vendor;
      if (currentEditedLogoId !== undefined) {
          state.editedLogo = state.allLogos.find((logo) => logo.id === currentEditedLogoId) || null;
      }
    },
    editLogo: (state, action) => {
      // console.log('editLogo', JSON.stringify(action.payload));
      const logoInfos = {
        ...action.payload,
        vendorId: state.searchedVendor === '' ? null : parseInt(state.searchedVendor, 10)
      };
      state.editedLogo = action.payload;
      state.hasChanges = false;
      state.imageChanged = false;
      state.changesList = [];
    },
    resizeLogoVersion: (state, action: {payload: {id: number, heightChange: number}}) => {
      if (state.editedLogo !== null) {
        const heightChange = action.payload.heightChange;
        const version = state.editedLogo.versions.find((v) => v.id === action.payload.id);

        if (version) {
          version.height += heightChange;
          // update width to keep the same ratio, using the logo ratio
          version.width = Math.round(version.height * 1.0 / state.editedLogo.ratio);

          constraintDimensions(state.editedLogo.ratio, version)

          if (state.editedLogo.technique === TECHNIQUE_DTF) {
            // update the area code and price
            update_area_code(version);
          }
          updateHasChanges(state);
        }

      }
    },
    defineSizeLogoVersion: (state, action: {payload: {id: number, sizeText: string}}) => {
      if (state.editedLogo !== null) {

        const version = state.editedLogo.versions.find((v) => v.id === action.payload.id);

        if (version) {
          // sizeText will be formatted as widthxheight, with either width or height being blank
          const newWidth = parseInt(action.payload.sizeText.split('x')[0], 10);
          // check that sizeText as a x in it before trying to split
          const newHeight = action.payload.sizeText.indexOf('x') === -1 ? 0 :
            parseInt(action.payload.sizeText.split('x')[1], 10);

          // we prioritize the new height if it is not 0, otherwise we use the new width
          if (newHeight !== 0) {
            version.height = newHeight;
            version.width = state.editedLogo.ratio === 0 ? 0 : Math.round(newHeight / state.editedLogo.ratio);
          } else if (newWidth !== 0) {
            version.width = newWidth;
            version.height = state.editedLogo.ratio === 0 ? 0 : Math.round(newWidth * state.editedLogo.ratio);
          }

          if (state.editedLogo.ratio !== 0) {
            constraintDimensions(state.editedLogo.ratio, version)
          }


          if (state.editedLogo.technique === TECHNIQUE_DTF) {
            // update the area code and price
            update_area_code(version);
          }
          updateHasChanges(state);
        }

      }
    },

    defineNameLogoVersion: (state, action: {payload: {id: number, newName: string}}) => {
      if (state.editedLogo !== null) {
        const version = state.editedLogo.versions.find((v) => v.id === action.payload.id);

        if (version) {
          version.name = action.payload.newName;
          updateHasChanges(state);
        }

      }
    },

    addLogoVersion: (state, action: {payload: {newName: string}}) => {
      if (state.editedLogo !== null) {

        // Find the min value of all the versions negative ids only, then add 1 to get a new id
        const newId = Math.min(...state.editedLogo.versions
          .filter((v) => v.id < 0)
          .map((v) => v.id), 0) - 1;

        // Start with the minimum size which is MINIMUM_LEAST_DIMENSION is the shortest side
        // determine the width and height based on the ratio of the logo
        let newWidth;
        let newHeight;
        if (state.editedLogo.ratio >= 1) {
          // the width is the smallest side
          newWidth = MINIMUM_LEAST_DIMENSION;
          newHeight = Math.round(newWidth * state.editedLogo.ratio);

        } else {
          // the height is the smallest side
          newHeight = MINIMUM_LEAST_DIMENSION;
          newWidth = Math.round(newHeight / state.editedLogo.ratio);
        }

        const areaCode = LOGO_LIMITS[0].name as string;
        const priceCents = LOGO_LIMITS[0].price as number;

        state.editedLogo.versions.push({
          id: newId,
          name: `${action.payload.newName} ${-newId}`,
          width: newWidth,
          height: newHeight,
          sizeCode: 1,
          areaCode: areaCode,
          priceCents: priceCents,
          boutiqueLogoId: null,
          boutiqueLogoPrintFiles: ''
        });
      }
      updateHasChanges(state);
    },

    removeLogoVersion: (state, action: {payload: {id: number}}) => {
      if (state.editedLogo !== null) {

        // set the LogoVersion field deleted to true, so that we can remove it from the list of versions
        const version = state.editedLogo.versions.find((v) => v.id === action.payload.id);
        if (version) {
          version.deleted = true;
        }

        updateHasChanges(state);
      }
    },

    storeLogoInfo: (state, action) => {
      if (state.editedLogo !== null) {
        // special case for value, if null put back to original value
        if (action.payload.value === null) {
          const logoID = state.editedLogo.id;
          action.payload.value = state.allLogos
            .find((l) => l.id === logoID)?.[action.payload.field as keyof ILogoInfos];
        }
        (state.editedLogo[action.payload.field as keyof ILogoInfos] as any) = action.payload.value;
        updateHasChanges(state);
      }
    },

    clearLogos: (state) => {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getlogos.pending, (state) => {
        // console.log("getSelection pending");
        // state.saving = true;
      })
      .addCase(getlogos.fulfilled, (state, action: any) => {
        // console.log("getSelection fulfilled with " + action.payload.length + " products");
        state.allLogos = action.payload.logos;
        state.searchedVendor = action.payload.vendor;
        state.loaded = true;
        state.error = false;
      })
      .addCase(getlogos.rejected, (state, action: any) => {
        // console.log("getSelection rejected");
        state.loaded = false;
        state.error = true;
      })
      .addCase(saveLogo.fulfilled, (state, action: any) => {
        // console.log("%cSaveLogo fulfilled with " + JSON.stringify(action.payload), 'color:white; background-color:darkgreen');
        state.saving = false;
        storeLogo(state, action.payload);
      })
      .addCase(saveLogo.pending, (state, action: any) => {
        // console.log("%cSaveLogo pending", 'color:white; background-color:orange');
        state.saving = true;
      })
      .addCase(saveLogo.rejected, (state, action: any) => {
        state.saving = false;
        state.error = true;
        // console.log("saveLogo rejected");
      })
      .addCase(removeLogo.fulfilled, (state, action: any) => {
        state.allLogos = action.payload.logos;
        state.error = false;
        state.editedLogo = null;
        state.hasChanges = false;
        state.changesList = [];
        state.imageChanged = false;
      })
      .addCase(removeLogo.rejected, (state, action: any) => {
        // console.log("removeLogo rejected");
      })
      .addCase(removeLogo.pending, (state, action: any) => {
        // console.log("removeLogo pending");
      })

  }
});

export const {
  setLogos,
  editLogo,
  resizeLogoVersion,
  defineSizeLogoVersion,
  defineNameLogoVersion,
  addLogoVersion,
  removeLogoVersion,
  storeLogoInfo,
  clearLogos
} = logosSlice.actions;

export default logosSlice.reducer;

export const hasAutomaticLogoSelector = ((state: RootState) => state.logos.allLogos.some((logo) => logo.automated));
export const editedLogoSelector = ((state: RootState) => state.logos.editedLogo);


export const searchedVendorSelector = ((state: RootState) => state.logos.searchedVendor);

// export const editingLogoSelector = createSelector((state: RootState) => state.logos.editedLogo,
//   (editedLogo) => editedLogo !== null);


// the logo is deletable if there is at least another logo that is not automated
export const logoDeletableSelector = ((state: RootState) => state.logos.allLogos
    .filter((logo) => logo.id !== state.logos.editedLogo?.id)
    .some((logo) => !logo.automated));


export const editedLogoHasChangesSelector = ((state: RootState) => state.logos.hasChanges);
export const editedLogoImageChangedSelector = ((state: RootState) => state.logos.imageChanged);
export const logoVersionsChangesSelector = ((state: RootState) => state.logos.changesList);

export const editedLogoSavingSelector = ((state: RootState) => state.logos.saving);
export const logosSelector = ((state: RootState) => state.logos.allLogos);
export const logosLoadedSelector = ((state: RootState) => state.logos.loaded);
// export const logosSelector = createSelector((state: RootState) => state.logos.logos, (logos) => logos);

