import React from 'react';
import {CircularProgress, Grid} from "@mui/material";
import ColorTag from "../catalog/ColorTag";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../app/store";
import {
  CP_CHANGE_IMAGES,
  CP_CHANGE_NONE,
  IAddonInfos,
  ICollectionProduct, ICollectionProductColor,
  openProductConfig,
  removeProductFromCollection
} from "./boutiqueSlice";
import {ILogoInfos, logosSelector} from "../logos/logosSlice";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import {useDroppable} from "@dnd-kit/core";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import {BoudaToolTip} from "../utils/BoudaToolTip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {GenderDisplayHash} from "../../app/product";
import {useIntl} from "react-intl";
import Box from "@mui/material/Box";

function CollectionProductCard(props: {
  id: string,
  index: number,
  collectionId: number,
  collectionProduct: ICollectionProduct,
  attachedLeft: boolean,
  attachedRight: boolean,
  editMode: boolean
}) {

  const intl = useIntl();

  const boutiqueHasChanges = useSelector((state : RootState) => state.boutique.hasChanges);
  const boutiqueBuilding = useSelector((state : RootState) => !!state.boutique.currentBoutique?.buildingAt);

  const {id, index, collectionId, collectionProduct, attachedLeft, attachedRight, editMode} = props;
  const {product, version, customTitle, colors, changesLevel, productId} = collectionProduct;

  const uniqId = 'cpcui' + collectionId + '-' + (productId || '') + '-' + version;

  const logos = useSelector(logosSelector);
  const dispatch = useDispatch<AppDispatch>();

  // check if some product colors are discontinued in the selected product
  const selectedProduct = useSelector((state : RootState) => state.selection.products.find(p => p.product.id === props.collectionProduct.productId));
  const discontinuedColors = selectedProduct?.colors.filter(c => c.discontinued).map(c => c.friendlyColor) || [];
  const discontinued = collectionProduct.colors.filter(c => discontinuedColors.includes(c.colorImages.friendlyColor)).map(c => c.colorImages.friendlyColor);
  const obsolete = selectedProduct?.obsolete || false;

  const firstColor = colors[0]?.colorImages;
  const imgScaleDown = product.imgScaleDown

  const {isOver: isOverDropProduct, setNodeRef: setNodeRefDropProduct} = useDroppable({
    id: 'drop-product-' + product.id,
    data: {
      product: product,
      collectionId: collectionId,
      accepts: 'logo'
    }
  });

  const styleDropProduct = {
    border: isOverDropProduct ? '1px #00A000 solid' : '',
  };

  // const {attributes, listeners, setNodeRef: setNodeRefSortProduct, transform, transition} = useSortable(
  //   {
  //     id: id,
  //     data: {
  //       type: 'collectionProduct',
  //       collectionId: collectionId,
  //       index: index,
  //     }
  //   });

  // const styleSortProduct = {
  //   transform: CSS.Transform.toString(transform),
  //   transition
  // };

  if (collectionProduct.colors.length == 0) return null;

  const handleRemove = () => {
    dispatch(removeProductFromCollection({
      collectionId: collectionId,
      id: product.id,
      productId: productId,
      version: version}))
  }


  const productLogo = (logo: ILogoInfos | undefined, i:number) => {
    if (logo == undefined) return null;

    return (
      <div key={`${uniqId}-L${i}`} className={`logo ${logo.forDarkBg >= 1 ? 'dark-bg' : ''}`}>
        {
          ((logo.logoUrl !== null && logo.logoUrl !== '') &&
				      <img src={logo.logoUrl} alt={logo.name} width='16'/>) ||
			      <div className="no-logo">
				      <div className="icon">
					      <ImageSearchIcon/>
				      </div>
			      </div>
        }
      </div>
    );
  }

  // console.log('render in collection ' + collectionId + ' product ' + product.title + ' with editMode ' + editMode);

  // le premier div id={`P${product.id.toString()}`}

  const notValidLogos = collectionProduct.colors
    .flatMap((color: ICollectionProductColor) =>
      color.logoAddons
        .filter((addon: IAddonInfos) => addon.logoIds.length > 0)
        .flatMap((addon: IAddonInfos) =>
          addon.logoIds
            .map((logoId: number) => logos.find((logo: ILogoInfos) => logo.id === logoId))
            .filter((logo: ILogoInfos | undefined) => !logo || !logo.logoUrl)
        )
    );

  const notValidLogo = notValidLogos.length > 0;
  const notValidLogoNames = notValidLogos.map((logo: ILogoInfos | undefined) => logo?.name).join(', ');

  const productError = obsolete || collectionProduct.hasError || discontinued.length > 0 || notValidLogo;
  const productTitle = collectionProduct.hasError ? collectionProduct
      .errors.map((e) => intl.formatMessage({id: `product.error-${e}`})).join(', ') :
    obsolete ? intl.formatMessage({id: 'product.obsolete'}) :
    notValidLogo ? intl.formatMessage({id: 'product.not-valid-logos'}, {logos: notValidLogoNames}) :
    discontinued.length > 0 ? discontinued.map(c => intl.formatMessage({id: 'product.discontinued-color'}, {color: c})).join(', ') :
    ((customTitle || product.title) + ((version > 1) ? ` (v${version})` : ''));

  return (
    // <div className="col-product" ref={setNodeRefSortProduct} style={styleSortProduct} >

    <BoudaToolTip title={productTitle} placement="top" arrow>
      <div className="col-product" >
        {/*<IconButton {...attributes} {...listeners}*/}
        {/*<IconButton sx={{position: 'absolute', left: '-10px', top: '-7px', color: '#A0A0A0', cursor:'move'}}>*/}
        {/*  <DragIndicatorIcon sx={{width: '0.8em', height: '0.8em'}} />*/}
        {/*</IconButton>*/}
        {(attachedLeft || attachedRight) &&
          <Box className="gender-icon" sx={{left: attachedLeft ? '-7px' : '5px'}}>
            <FontAwesomeIcon icon={GenderDisplayHash[product.gender]} size='1x' className='adult gender' />
          </Box>
        }
        {editMode && <IconButton aria-label="close" onClick={() => handleRemove()}
                    sx={{
                      position: 'absolute',
                      right: attachedRight ? '-16px' : '-2px',
                      top: '-5px', size:'small', color: '#f68f8f', zIndex:2}}>
          <DeleteIcon sx={{width: '0.8em', height: '0.8em',}} />
        </IconButton>}
        <div className={`product-tbn ${productError ? 'error ' : ''} 
                        ${changesLevel > CP_CHANGE_NONE ? 'has-changes ' : ''} 
                        ${changesLevel == CP_CHANGE_IMAGES ? 'hard-changes ' : ''} 
                        ${attachedRight ? 'stick-right ' :'' } 
                        ${attachedLeft ? 'stick-left ' :'' } `}
             ref={setNodeRefDropProduct} style={styleDropProduct}>
          <div className='first-img' onClick={() => dispatch(openProductConfig(collectionProduct))}>
            {firstColor && firstColor.images.length > 0 &&
            <img src={firstColor.images[0].boutiqueUrl || firstColor.images[0].url}
                 style={{width:`${100 - imgScaleDown}%`,marginTop:`${imgScaleDown/2}%`,marginBottom:`${imgScaleDown/2}%`}} />
            }
            {boutiqueBuilding && !boutiqueHasChanges && changesLevel == CP_CHANGE_IMAGES && <div className="product-overlay">
              <div className="spinner">
                <CircularProgress color="inherit" size={16} />
              </div>
            </div>
            }
          </div>
          <div className="product-footer">
            <div className='logos'>
              {collectionProduct.colors.map((color: ICollectionProductColor) =>
                color.logoAddons.map((addon: IAddonInfos) => addon.logoIds)
              ).flat(2).filter((v,i,a)=>a.indexOf(v)==i). // unique
              map((logoId: number, i) =>
                productLogo(logos.find((logo: ILogoInfos) => logo.id === logoId), i)
              )}
            </div>
            <div className='all-colors'>
              {colors.map((collectionProductColor: ICollectionProductColor, idx: number) => {
                const otherColor = collectionProductColor.colorImages;
                return (otherColor &&
                    <ColorTag key={`${uniqId}-h1${otherColor.hexColor1}-h2${otherColor.hexColor2}-${idx}`}
                              color1={otherColor.hexColor1} color2={otherColor.hexColor2}
                              friendlyColor={otherColor.friendlyColor} active={false} size='small' />
                )
              })}
            </div>
          </div>
          {attachedLeft && <div className="attached-left">
	          <LinkIcon sx={{width: '1.1em', height: '1.1em'}} />
	        </div>}
          {attachedRight && <div className="attached-right">
	          <LinkIcon sx={{width: '1.1em', height: '1.1em'}} />
          </div>}
        </div>
      </div>
    </BoudaToolTip>
  )

}

export default CollectionProductCard;