import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../../app/store";
import {
  getDBProducts,
  getProductionItems,
  getProductionMarkings,
  getProductionMarkingSeries, getWorkshopStocks, requestAddStock, requestDeleteStock,
  requestExport, requestItemsIntegration, saveDpdDelivery,
  updateDeliveryOrder,
  updateMarkingDone,
  updateProductionStatus, updateStockItem,
  updateWorkshopDelivered
} from "../../../app/productionAPI";
import {colorFromNumber} from "../../../app/utils";
import {IFoundProduct} from "./SelectStockVariant";

const DAGOBA_SELLER_ID = 2
const ATE_LOGO_ID = 4

export const SELLER_NAMES = [
  {id: 0, name: 'Tous'},
  {id: ATE_LOGO_ID, name: 'ATE LOGO'},
  {id: DAGOBA_SELLER_ID, name: 'DAGOBA'},
]

export const PRODUCTION_TO_START = 0
export const PRODUCTION_ORDER_ONGOING = 50
export const PRODUCTION_PRODUCTS_ORDERED = 100
export const PRODUCTION_IN_CSV_EXPORT = 150
export const PRODUCTION_ORDER_ERROR = 200
export const PRODUCTION_STARTED = 300

export const PRODUCTION_PROBLEM = 330
export const PRODUCTION_DELIVERED = 340
export const PRODUCTION_IN_BOX = 360
export const PRODUCTION_ORDER_COMPLETE = 370
export const PRODUCTION_WITH_LOGOS = 400
export const PRODUCTION_MARKINGS_DONE = 450
export const PRODUCTION_DELIVERY_START = 500
export const PRODUCTION_DELIVERY_IN_PROGRESS = 700
export const PRODUCTION_DELIVERY_WAIT_RETRIEVE = 800
export const PRODUCTION_DELIVERY_FINAL_RETRIEVE = 810
export const PRODUCTION_RETURNING_TO_SENDER = 850
export const PRODUCTION_IS_BACK_TO_SENDER = 890
export const PRODUCTION_DELIVERY_ERROR = 900
export const PRODUCTION_OVER = 2000

const PARAMS_FOR_ORDER_STATUS = [
  {orderStatus: PRODUCTION_ORDER_ERROR, bgColor: '#A0A0A0', fgColor: '#FFF', operator: false},
  {orderStatus: PRODUCTION_TO_START, bgColor: '#9975C0', fgColor: '#FFF', operator: false},
  {orderStatus: PRODUCTION_STARTED, bgColor: '#27357A', fgColor: '#ffffff', operator: false},
  {orderStatus: PRODUCTION_DELIVERED, bgColor: '#FBFF00', fgColor: '#000', operator: true},
  {orderStatus: PRODUCTION_PROBLEM, bgColor: '#D23636', fgColor: '#FFF', operator: false},
  {orderStatus: PRODUCTION_IN_BOX, bgColor: '#A4DEFB', fgColor: '#000', operator: false},
  {orderStatus: PRODUCTION_ORDER_COMPLETE, bgColor: '#b5ffe9', fgColor: '#000', operator: false},
  {orderStatus: PRODUCTION_WITH_LOGOS, bgColor: '#FF7400', fgColor: '#000', operator: true},
  {orderStatus: PRODUCTION_MARKINGS_DONE, bgColor: '#0e4b2a', fgColor: '#FFF', operator: true},
  {orderStatus: PRODUCTION_DELIVERY_START, bgColor: '#4B75D3', fgColor: '#FFF', operator: true},
  {orderStatus: PRODUCTION_DELIVERY_IN_PROGRESS, bgColor: '#C8BDFF', fgColor: '#000', operator: false},
  {orderStatus: PRODUCTION_DELIVERY_WAIT_RETRIEVE, bgColor: '#FF8FD8', fgColor: '#000', operator: false},
  {orderStatus: PRODUCTION_DELIVERY_FINAL_RETRIEVE, bgColor: '#FF00A6', fgColor: '#FFF', operator: false},
  {orderStatus: PRODUCTION_RETURNING_TO_SENDER, bgColor: '#F44336', fgColor: '#FFF', operator: false},
  {orderStatus: PRODUCTION_IS_BACK_TO_SENDER, bgColor: '#80201A', fgColor: '#FFF', operator: false},
  {orderStatus: PRODUCTION_DELIVERY_ERROR, bgColor: '#800080', fgColor: '#FFF', operator: false},
  {orderStatus: PRODUCTION_OVER, bgColor: '#696969', fgColor: '#FFF', operator: true}
]

// special colors for delivery page
export const BEFORE_INTEGRATION_COLOR = '#dba9f8';
export const BEFORE_DELIVERY_COLOR = '#E0E0E0';

export const CHECKED_ITEM_COLOR = '#80FF80';

export const DeliveryColours = (orderStatus: number) => {
  if (orderStatus < PRODUCTION_STARTED) {
    return {
      bgColor: BEFORE_INTEGRATION_COLOR,
      fgColor: '#000'
    }
  } else if (orderStatus === PRODUCTION_STARTED) {
    return {
      bgColor: BEFORE_DELIVERY_COLOR,
      fgColor: '#000'
    }
  } else {
    return ColorsForOrderStatus(orderStatus);
  }
}


// this is also in production controller
const DELIVERED_STATUS = 'PASSÉ EN LIVRÉ'
export const COLISSIMO_URL = 'http://www.colissimo.fr/portail_colissimo/suivre.do?colispart='
export const DPD_TRACKING_URL = 'https://trace.dpd.fr/fr/trace/'

export const ColorForTrackingNumber = (tn: string) => `#${colorFromNumber(tn.replace(/\s/g, "").slice(-6))}`

const MARKINGS_ALL = 0;

const TRANSFER_A4 = 540
const TRANSFER_A5 = 550
const TRANSFER_A6 = 560
const TRANSFER_A7 = 570
const TRANSFER_A8 = 580
const DIGITAL_TRANSFER = 310
export const EMBROIDERY = 320
const STICKER = 360
const BADGE_STITCHING = 375

const MARKINGS_NAMES = [
  {code: TRANSFER_A8, name: 'DTF A8'},
  {code: TRANSFER_A7, name: 'DTF A7'},
  {code: TRANSFER_A6, name: 'DTF A6'},
  {code: TRANSFER_A5, name: 'DTF A5'},
  {code: TRANSFER_A4, name: 'DTF A4'},
  {code: STICKER, name: 'Autocollant'},
  {code: DIGITAL_TRANSFER, name: 'Transfert'},
  {code: EMBROIDERY, name: 'Broderie'},
  {code: BADGE_STITCHING, name: 'Couture'},
]

export const nameForMarkingTechnique = (technique: number | undefined) => technique ? MARKINGS_NAMES
  .find((mn) => mn.code === technique)?.name || 'INCONNU' : 'INCONNU'


export const MARKINGS_TYPES = [
  {code: MARKINGS_ALL, name: 'Tous'},
  {code: TRANSFER_A8, name: 'DTF'},
  {code: EMBROIDERY, name: 'Broderie'},
  {code: BADGE_STITCHING, name: 'Couture'},
]

const isTransfer = (technique: number) => technique === TRANSFER_A8 || technique === TRANSFER_A7 ||
  technique === TRANSFER_A6 || technique === TRANSFER_A5 || technique === TRANSFER_A4 ||
  technique === DIGITAL_TRANSFER || technique === STICKER

export interface ICustomer {
  id: number;
  email: string;
}

export interface IDeliveryInfos {
  deliveryOk: boolean;
  address: string;
  mode: string;
  onHold: boolean;
}

export interface IShippingInfos {
  mode: string;
  date: Date;
  tracking: string;
  comment: string;
  labelUrl: string;
  status: string;
  costCents: number;
  manyDeliveries: boolean;
}

export interface IPrintInfos {
  name: string;
  file: string;
  raster?: boolean;
  stats?: string;
}

export interface ILogoInfos {
  key: number;
  image: string;
  technique: number;
  description: string;
  print: IPrintInfos;
  print2: IPrintInfos;
  print3: IPrintInfos;
  print4: IPrintInfos;
  print5: IPrintInfos;
  print_bouda: IPrintInfos;
}

export interface IPersoInfos {
  fontName: string;
  fontColor: string;
  fontFile: string;
}

export interface IArticleLogo {
  name: string;
  logoInfos: ILogoInfos;
  bgColor: string;
}

export interface IArticlePerso {
  key: string;
  type: string;
  name: string;
  value?: string;
  textInfos?: IPersoInfos;
  logoInfos?: ILogoInfos;
  bgColor?: string;
  perso_png_url?: string;
}

export interface IArticle {
  ref: string;
  variant: string;
  dagobaTitle: string;
  brand: string;
  family: string;
  preview: string;
  supplierTitle: string;
  supplierColor: string;
  supplierSize: string;
  supplierPhoto: string;
  productionNotes: string;
  replacementBrand: string;
  replacementRef: string;
  logos: IArticleLogo[];
  persos: IArticlePerso[]
}

export interface ISingleItem {
  prodItemId: number;
  num: number;
  qrCode: string;
  orderStatus: number;
  workshopBoxId: number | null;
}

// coming from app/models/dagoba/cart_item.rb
export const SOURCE_DAGOBA = 10;
export const SOURCE_ORIFLAM = 20;
export const SOURCE_SUPPLIER = 30;

export const NO_DATE_LIMIT = new Date('2049-01-01')

export interface IProductionItem {
  id: number;
  qr_code: string;
  orderNumber: number;
  orderDate: string;
  priority: number;
  boutique: IProductionBoutique;
  article: IArticle;
  quantity: number;
  singleItems: ISingleItem[];
  partial: boolean;
  procureSource: number;
  deliveryCode: string;
  procureDate: string;
  procureOrder: string;
  customer: ICustomer;
  sellerId: number;
  delivery: IDeliveryInfos;
  shipping: IShippingInfos;
}

export interface IWorkshopStatus {
  // statusId: number;
  statusName: string;
  statusAction: string;
  statusCode: string;
  orderStatus: number;
  bgColor: string;
  fgColor: string;
  nbSingleItems: number;
}

export interface IWorkshopBox {
  id: number;
  name: string;
  qrCode: string;
  position: number;
  items: IDeliveryItem[];
  complete: boolean;
}

export interface IWorkshopDelivery {
  procureSource: number;
  deliveryCode: string;
  supplier: string;
  procureDate: Date;
  procureOrder: string;
  carrier: string;
  status: string;
  deliveredAt: Date;
  nbItems: number;
}

export interface IStockBox {
  id: number;
  name: string;
  qrCode: string;
  position: number;
}

export interface IStockItem {
  id: number;
  name: string;
  qrCode: string;
  stockBoxId: number;
  prodItemId: number;
  orderNumber: number;
  stockBoxName: string;
  variantId: number;
  ref: string;
  dagobaTitle: string;
  brand: string;
  preview: string;
  supplierTitle: string;
  supplierColor: string;
  supplierSize: string;
  supplierPhoto: string;
}

export interface IStockBrand {
  name: string;
  count: number;
}

export interface IWorkshop {
  id: number;
  name: string;
  statuses: IWorkshopStatus[];
  prodItems: IProductionItem[];
  boxes: IWorkshopBox[];
  deliveries: IWorkshopDelivery[];
}

export interface IProductionStatusColors {
  bgColor: string;
  fgColor: string;
}

export interface IProductionBoutique {
  id: number;
  name: string;
}

export interface IMarkingPrintFile {
  name: string;
  file: string;
}

export interface IMarkingArticle {
  id: number;
  place: string;
  quantity: number;
  lowestOrderStatus: number;
  ref: string;
  variant: string;
  dagobaTitle: string;
  preview: string;
  supplierTitle: string;
  supplierColor: string;
  supplierSize: string;
  bgColor: string;
  productionNotes: string;
}

export interface IMarkingsAggregate {
  doneAt: string;
  key: string;
  type: string;
  name: string;
  image: string;
  technique: number;
  description: string;
  printFiles: IMarkingPrintFile[];
  value: string;
  fontName: string;
  fontColor: string;
  fontFile: string;
  pngFile: string;
  places: string[];
  articles: IMarkingArticle[];
}

export interface IOrderMarkings {
  orderNumber: number;
  sellerId: number;
  orderDate: string;
  boutique: string;
  workshopBoxId: number | null;
  workshopBoxName: string | null;
  markingsAggregrates: IMarkingsAggregate[];  // all markings for this workshop, sorted by code
}

export interface IMarkingsDayProduction {
  date: string;
  nbMarkings: number;
}

export interface IMarkings {
  id: number;     // workshop id
  name: string;   // workshop name
  markings: IOrderMarkings[];  // all markings for this workshop, sorted by code
  markingsHistory: IMarkingsDayProduction[];  // number of markings per day
}

export interface IMarkingItem {
  itemId: number;
  orderNumber: number;
  sellerId: number;
  quantity: number;
  date: string;
  mode: string;
  // shippingCost: number;
}

export interface IMarkingSerie {
  code: string;
  technique: number;
  price: number;
  items: IMarkingItem[];
}

export interface IWorkshopShipping {
  shippingDate: string;
  orderNumber: number;
  trackingNumber: string;
  comment: string;
  price: number;
}

export interface IMarkingSeries {
  workshopId: number;     // workshop id
  workshopName: string;   // workshop name
  series: IMarkingSerie[];  // all markings for this workshop, sorted by code
  shippings: IWorkshopShipping[];
}

export interface ProductionState {
  workshops: IWorkshop[];
  currentWorkshop: string;
  filterKey: string;
  currentSeller: number;
  currentOrderStatus: number;
  currentOrder: number | null;
  startDate: string | null;
  endDate: string | null;
  boxesSortBy: string;
  currentStocksView: number;
  selectedOrders: ISelectedOrder[];
  loadedBoutiques: IProductionBoutique[];
  selectedBoutiques: IProductionBoutique[];
  currentDelivery: string;
  selectedOrdersDeliveries: ISelectedDelivery[]
  loaded: boolean;
  hasChanges: boolean;
  saving: boolean;
  error: boolean;

  // Compilations of all marking per workshop and then marking code
  markingsLoaded: boolean;
  markingSaving: boolean;
  markingBeingSaved: string;
  workshopMarkings: IMarkings[];
  markingsProductionDay: IMarkingsDayProduction;
  currentMarkingsType: number;
  filterShippedMarkings: boolean;
  selectedMarkings: IOrderMarkings[];

  // Compilations of all marking per workshop and then marking code
  markingSeriesLoaded: boolean;
  workshopMarkingSeries: IMarkingSeries[];
  // current workshop compilation filtered by order number + dates
  selectedMarkingSeries: IMarkingSerie[];
  selectedShippings: IWorkshopShipping[];

  workshopStockBoxes: IStockBox[],
  workshopStockItems: IStockItem[],
  brands: IStockBrand[],
  stocksLoaded: boolean,
  selectedStockBrand: string,
  filterStockNoBox: boolean,
  searchStockRef: string,
  selectedStockItems: IStockItem[],

  foundProducts: IFoundProduct[],
  productsLoading: boolean

}


const initialState: ProductionState = {
  workshops: [],
  currentWorkshop: '',
  filterKey: "",
  currentSeller: 0,
  currentOrderStatus: 0,
  currentOrder: null,
  startDate: null,
  endDate: null,
  boxesSortBy: 'priority',
  currentStocksView: 0,
  selectedOrders: [],
  loadedBoutiques: [],
  selectedBoutiques: [],
  currentDelivery: '',
  selectedOrdersDeliveries: [],
  loaded: false,
  hasChanges: false,
  saving: false,
  error: false,
  markingsLoaded: false,
  markingSaving: false,
  markingBeingSaved: '',
  workshopMarkings: [],
  markingsProductionDay: {date: 'none', nbMarkings: 0},
  currentMarkingsType: MARKINGS_ALL,
  filterShippedMarkings: true,
  selectedMarkings: [],
  markingSeriesLoaded: false,
  workshopMarkingSeries: [],
  selectedMarkingSeries: [],
  selectedShippings: [],

  workshopStockBoxes: [],
  workshopStockItems: [],
  brands:[],
  stocksLoaded: false,
  selectedStockBrand: '*',
  filterStockNoBox: false,
  searchStockRef: '',
  selectedStockItems: [],

  foundProducts: [],
  productsLoading: false,
};

export interface ISelectedItem {
  id: number;
  num: number;
  article: IArticle;
  quantity: number;
  singleItems: ISingleItem[];
  procureSource: number;
  procureDate: string;
  procureOrder: string;
  supplier: string;
  shippingMode: string;
  shippingNumber: string;
  shippingComment: string;
  shippingLabelUrl: string;
  shippingCost: number;
  manyDeliveries: boolean;
  checked?: boolean;
}

export interface ISelectedOrder {
  orderNumber: number;
  orderDate: string;
  priority: number;
  boutique: IProductionBoutique;
  customer: ICustomer;
  sellerId: number;
  delivery: IDeliveryInfos;
  nbItems: number;
  items: ISelectedItem[];
  partial: boolean;
}

export interface IDeliveryItem {
  prodItemId: number;
  num: number;
  orderStatus: number;
  workshopBoxId: number | null;
  qrCode: string;
  itemId: number;
  orderNumber: number;
  orderDate: string;
  priority: number;
  sku: string;
  title: string;
  color: string
  size: string;
  brand: string;
  family: string;
  photo: string;
  replacementBrand: string;
  replacementRef: string;
}

export interface ISelectedDelivery {
  code: string;
  nbItems: number;
  items: IDeliveryItem[];
}

const nbSingleItemsForStatus = (state: ProductionState, orderStatus: number) => state.selectedOrders
  .reduce((nb, order) => (nb + order.items
    .reduce((nb, item) => (nb + item.singleItems
        .filter((item) => (item.orderStatus === orderStatus)).length), 0)
    ), 0)

const statusForOrderStatus = (state: ProductionState, orderStatus: number) => {
  const workshop = state.workshops
    .find((workshop) => workshop.name === state.currentWorkshop)

  return workshop?.statuses.find((status) => status.orderStatus === orderStatus);
}

const setCurrentWorkshop = (state: ProductionState, workshopName: string) => {
  // console.log("Setting workshop to " + workshopName)

  state.currentWorkshop = workshopName;

  const workshop = state.workshops
    .find((workshop) => workshop.name === workshopName);

  if (workshop) {
    // console.log("Found workshop " + workshop.name + " with " + workshop.prodItems.length + " items");

    filterItems(state, workshop);

    const status = workshop.statuses
      .find((status) => nbSingleItemsForStatus(state, status.orderStatus) > 0);

    if (status) {
      // console.log("Setting status to " + status.statusName);

      state.currentOrderStatus = status.orderStatus;
    }
  }
}

const deliveryItemFromProductionItem = (item: IProductionItem, singleItem: ISingleItem): IDeliveryItem => (
  {
    prodItemId: singleItem.prodItemId,
    num: singleItem.num,
    orderStatus: singleItem.orderStatus,
    workshopBoxId: singleItem.workshopBoxId,
    qrCode: singleItem.qrCode,
    itemId: item.id,
    orderNumber: item.orderNumber,
    orderDate: item.orderDate,
    priority: item.priority,
    sku: item.article.ref,
    title: item.article.supplierTitle,
    color: item.article.supplierColor,
    size: item.article.supplierSize,
    brand: item.article.brand,
    family: item.article.family,
    photo: item.article.supplierPhoto,
    replacementBrand: item.article.replacementBrand,
    replacementRef: item.article.replacementRef
  })

export const DeliveryItemsFromProductionItem = (item: IProductionItem): IDeliveryItem[] =>
  item.singleItems.map((singleItem) => deliveryItemFromProductionItem(item, singleItem))


const selectedItemFromProductionItem = (item: IProductionItem, num:number): ISelectedItem => (
  {
    id: item.id,
    num: num,
    article: item.article,
    quantity: item.quantity,
    singleItems: item.singleItems,
    procureSource: item.procureSource,
    procureDate: item.procureDate,
    procureOrder: item.procureOrder,
    supplier: item.deliveryCode?.substring(0, 3) || '',
    shippingMode: item.shipping.mode || '',
    shippingNumber: item.shipping.tracking || '',
    shippingComment: item.shipping.comment || '',
    shippingLabelUrl: item.shipping.labelUrl || '',
    shippingCost: item.shipping.costCents || 0,
    manyDeliveries: item.shipping.manyDeliveries,
    checked: false
  })

const updateAutomatedStatuses = (state: ProductionState, workshop: IWorkshop) => {

  const workShopUpdatedItems = workshop.prodItems.map((item) => (
    {
      ...item,
      orderStatus: item.singleItems
        .reduce((status, singleItem) => Math.max(status, singleItem.orderStatus), 0)
    }
  ))

  workshop.prodItems = workShopUpdatedItems;
}

export const BOXES_SORT_BY = [
  {code: 'priority', name: 'Priorité'},
  {code: 'number', name: 'Numéro'},
  {code: 'empty', name: 'Remplissage'},
]

const updateWorkshopBoxes = (state: ProductionState) => {

  state.workshops = state.workshops.map((workshop) => (
    {
      ...workshop,
      boxes: workshop.boxes.map((box) => (
        {
          ...box,
          items: (workshop.prodItems?.
          filter((item) => item.singleItems
            .some((singleItem) => singleItem.workshopBoxId === box.id))
          .flatMap((item) => DeliveryItemsFromProductionItem(item))
          .filter((item) => item.workshopBoxId === box.id))
          || []
        }
      )).map((box) => (
        {
          ...box,
          complete: box.items.every((item) => item.orderStatus === PRODUCTION_ORDER_COMPLETE)
        }
      )).sort((a, b) => {
        switch (state.boxesSortBy) {
          case 'number':
            return a.position - b.position

          case 'empty':
            return a.items.length - b.items.length

          case 'priority':

            const aOrderPriority = a.items[0]?.priority || 0
            const bOrderPriority = b.items[0]?.priority || 0
            if (aOrderPriority !== 0 || bOrderPriority !== 0) {
              if (aOrderPriority < bOrderPriority) return 1
              if (aOrderPriority > bOrderPriority) return -1
            }

            const aOrderDate = a.items[0]?.orderDate || '9999-01-01'
            const bOrderDate = b.items[0]?.orderDate || '9999-01-01'
            if (aOrderDate < bOrderDate) return -1
            if (aOrderDate > bOrderDate) return 1

            return 0

          default:
            return 0
        }
      })
    }
  ))
}


const filterArticleByKey = (article: IArticle, key: string) => (
  article.ref.toLowerCase().includes(key) ||
  article.variant.toLowerCase().includes(key) ||
  article.dagobaTitle.toLowerCase().includes(key) ||
  article.brand.toLowerCase().includes(key)
)

// find orders with status 'En production'
// for each order, find items with status 'En production'
const filterItems = (state: ProductionState, workshop?: IWorkshop) => {

  // console.log("Filtering orders for workshop " + (workshop ? workshop.name : state.currentWorkshop));

  let filteredWorkshop = workshop || state.workshops
    .find((workshop) => workshop.name === state.currentWorkshop)

  if (filteredWorkshop === undefined) {
    // console.log("No workshop found for " + state.currentWorkshop);
    return []
  }

  // Filter orders with search bar values
  const filteredOrders = filteredWorkshop.prodItems
    .filter((item) => !state.currentSeller || item.sellerId === state.currentSeller)
    .filter((item) => !state.currentOrder || item.orderNumber === state.currentOrder)
    .filter((item) => !state.filterKey || filterArticleByKey(item.article, state.filterKey))
    .filter((item) => !state.startDate || item.orderDate >= state.startDate)
    .filter((item) => !state.endDate || item.orderDate <= state.endDate)
    .filter((item) => state.selectedBoutiques.length === 0 ||
      state.selectedBoutiques.find((boutique) => boutique.id === item.boutique.id))

  // We don't filter orders with markings anymore
    // .filter((item) => state.markingsProductionDay.date === 'none' ||
    //     state.selectedMarkings.some((orderMarkings) =>
    //         orderMarkings.markingsAggregrates.some((markingsAggregate) =>
    //             markingsAggregate.articles.some((article) => article.id === item.id))
    //     ))

  // Reduce orders with items
  const ordersWithItems = filteredOrders.reduce((ordersWithItems, item) => {
    const order = ordersWithItems
      .find((order) => order.orderNumber === item.orderNumber);
    if (order) {
      order.nbItems += item.quantity;
      order.items.push(selectedItemFromProductionItem(item, order.items.length + 1));
      order.partial = order.partial || item.partial;
    } else {
      ordersWithItems.push(
        {
          orderNumber: item.orderNumber,
          orderDate: item.orderDate,
          priority: item.priority,
          boutique: item.boutique,
          customer: item.customer,
          sellerId: item.sellerId,
          delivery: item.delivery,
          nbItems: item.quantity,
          items: [selectedItemFromProductionItem(item, 1)],
          partial: item.partial
        });
    }
    return ordersWithItems;
  }, [] as ISelectedOrder[]);

  state.selectedOrders = ordersWithItems || [];


  //////////////////////////////////////////////////////////////////////////////
  // update currentOrderStatus in case it is not a status of the selected orders

  // first make an ordered list of all different order statuses in the selected orders
  const selectedOrderStatuses = state.selectedOrders
       .flatMap((order) => order.items)
          .flatMap((item) => item.singleItems)
            .map((singleItem) => singleItem.orderStatus)
                .filter((status, index, self) => self.indexOf(status) === index)
                    .sort((a, b) => a - b)

  // if the currentStatus is not in the list, set it to the first one
  if (!selectedOrderStatuses.includes(state.currentOrderStatus)) {
      state.currentOrderStatus = selectedOrderStatuses[0]
  }
  //////////////////////////////////////////////////////////////////////////////


  const selectedDeliveryItems = filteredOrders
    .reduce((deliveryWithItems, item) => {
      const delivery = deliveryWithItems
        .find((delivery) => {
          // special case for DAGOBA STOCK, only items with order status <= PRODUCTION_STARTED are shown
          if (delivery.code === item.deliveryCode) {
            if (item.deliveryCode === 'DAGOBA' || item.deliveryCode === 'ORIFLAM') {

              if (item.orderNumber < 21300) return false;

              return item.singleItems.some((si) => si.orderStatus <= PRODUCTION_STARTED)

            } else return true
          }
        });

      if (delivery) {
        delivery.nbItems += item.quantity;
        delivery.items = [...delivery.items, ...DeliveryItemsFromProductionItem(item)];

      } else {
        if (item.deliveryCode === 'DAGOBA' || item.deliveryCode === 'ORIFLAM') {

          if (item.orderNumber >= 21300) {
            const hasUnpickedItems = item.singleItems.some((si) => si.orderStatus <= PRODUCTION_STARTED)

            if (hasUnpickedItems) {
              deliveryWithItems.push({
                code: item.deliveryCode,
                nbItems: item.quantity,
                items: DeliveryItemsFromProductionItem(item)
              });
            }
          }
        } else {
          deliveryWithItems.push({
            code: item.deliveryCode,
            nbItems: item.quantity,
            items: DeliveryItemsFromProductionItem(item)
          });
        }
      }
      return deliveryWithItems;
    }, [] as ISelectedDelivery[]).map((delivery) => (
        {
          ...delivery,
          items: delivery.items
            .sort((a, b) => a.family > b.family ? -1 : 1)
        }
      )
    );

  state.selectedOrdersDeliveries = selectedDeliveryItems || [];

  // update the number of singleItems per status for the current workshop
  filteredWorkshop.statuses = filteredWorkshop.statuses.map((workshopStatus: IWorkshopStatus) => (
    {
      ...workshopStatus,
      nbSingleItems: nbSingleItemsForStatus(state, workshopStatus.orderStatus)
    }
  ))
}

const filterStockProductByKey = (item: IStockItem, key: string) => (
  item.dagobaTitle.toLowerCase().includes(key) || item.ref.toLowerCase().includes(key)
  || item.supplierTitle.toLowerCase().includes(key)
)

const filterStockItems = (state: ProductionState) => {
  state.selectedStockItems = state.workshopStockItems
    .filter((item) => !state.filterStockNoBox || !item.stockBoxName)
    .filter((item) => state.selectedStockBrand === '*' || item.brand === state.selectedStockBrand)
    .filter((item) => !state.searchStockRef || filterStockProductByKey(item, state.searchStockRef.toLowerCase())
  )
}

const filterMarkings = (state: ProductionState) => {

  // if markings not loaded yet, do nothing
  if (state.workshopMarkings.length === 0) {
    // console.log("%cNo filteredWorkshopMarkings", "color: red")
    state.selectedMarkings = []
    return
  }

  // May be later we will have markings for another workshop than Oriflam (the first one)
  // current workshop index
  const workshopIndex = state.currentWorkshop ? state.workshopMarkings.findIndex((w) => w.name === state.currentWorkshop) : 0;

  const filteredWorkshopMarkings = (state.workshopMarkings[workshopIndex]?.markings || [])
    .filter((orderMarkings) => {
      if (!!state.currentOrder && orderMarkings.orderNumber !== state.currentOrder) return false;
      if (!!state.currentSeller && orderMarkings.sellerId !== state.currentSeller) return false;

      // if (!!state.filterKey && !orderMarkings.markingsAggregrates
      //   .some((markingsAggregate) => markingsAggregate.articles
      //     .some((article) => filterArticleByKey(article, state.filterKey)))) return false;

      return true;
    })

    // reject the markings that are not of the selected type
    .map((orderMarkings) => (
      {
        ...orderMarkings,
        markingsAggregrates: orderMarkings.markingsAggregrates.filter((markingsAggregate) => {
          if (state.currentMarkingsType === MARKINGS_ALL) return true;

          switch (state.currentMarkingsType) {
            // we take TRANSFER_A8 to identify all transfer markings
            case TRANSFER_A8: return isTransfer(markingsAggregate.technique)
            default: return markingsAggregate.technique === state.currentMarkingsType
          }

        })}))

    // reject the markings that belong to shipped articles
    .map((orderMarkings) => (
      {
        ...orderMarkings,
        markingsAggregrates: orderMarkings.markingsAggregrates.filter((markingsAggregate) => {
          if (!state.filterShippedMarkings) return true;

          return markingsAggregate.articles
            .some((article) => article.lowestOrderStatus < PRODUCTION_DELIVERY_START)

        })}))
    .filter((orderMarkings) => orderMarkings.markingsAggregrates.length > 0)

      // .filter((orderMarkings) => orderMarkings.articles.length > 0)


  // We only display the markings of the selected day
  state.selectedMarkings = filteredWorkshopMarkings.filter((orderMarkings) => {
    const markingsDoneDates = orderMarkings.markingsAggregrates.map(
      (markingsAggregate) => markingsAggregate.doneAt?.substring(0, 10) || 'none'
    );

    return (markingsDoneDates.includes(state.markingsProductionDay.date))
  })


  // We make the history of all markings
  state.workshopMarkings[workshopIndex].markingsHistory = filteredWorkshopMarkings
    .flatMap((orderMarkings) => orderMarkings.markingsAggregrates)
    .reduce((markingsHistory, markingsAggregate) => {
      const date = markingsAggregate.doneAt?.substring(0, 10) || 'none';
      const day = markingsHistory.find((day) => day.date === date);
      if (day) {
        day.nbMarkings += markingsAggregate.articles.reduce((nb, article) => nb + article.quantity, 0);
      } else {
        markingsHistory.push({date: date,
          nbMarkings: markingsAggregate.articles.reduce((nb, article) => nb + article.quantity, 0)})
      }
      return markingsHistory;
    }, [] as IMarkingsDayProduction[])
    .sort((a, b) =>
      (a.date === 'none' ? '2050-01-01' : a.date) > (b.date === 'none' ? '2050-01-01' : b.date) ? -1 : 1)

  // console.log("%cFiltering markings of day " + state.markingsProductionDay, 'color:red');

}

const filterMarkingSeries = (state: ProductionState) => {

  // console.log("%cFiltering marking series for workshop " + state.currentWorkshop, "color: blue")

  // May be later we will have markings for another workshop than Oriflam (the first one)
  const filteredWorkshopMarkingSeries = (state.currentWorkshop ?
    state.workshopMarkingSeries.find((w) => w.workshopName === state.currentWorkshop) :
    state.workshopMarkingSeries[0])?.series

  if (filteredWorkshopMarkingSeries) {
    // console.log("%cFound " + filteredWorkshopMarkingSeries.length + " markings", "color: darkgreen")
    state.selectedMarkingSeries = filteredWorkshopMarkingSeries.map((serie) => {

      const newItems = serie.items
        .filter((item) => !state.currentOrder || item.orderNumber === state.currentOrder)
        .filter((item) => !state.currentSeller || item.sellerId === state.currentSeller)
        .filter((item) => !state.startDate || item.date >= state.startDate)
        .filter((item) => !state.endDate || item.date <= state.endDate)
      // console.log("%cFound " + newItems.length + " items for marking " + serie.code, "color: purple")

      return {
        ...serie,
        items: newItems,
        };
      }
    ).filter((serie) => serie.items.length > 0)

  } else {
    // console.log("%cNo filteredWorkshopMarkingSeries", "color: red")
    state.selectedMarkingSeries = []
  }


  const filteredWorkshopShippings = (state.currentWorkshop ?
    state.workshopMarkingSeries.find((w) => w.workshopName === state.currentWorkshop) :
    state.workshopMarkingSeries[0])?.shippings

  if (filteredWorkshopShippings) {
    if (state.currentSeller !== ATE_LOGO_ID) {

    state.selectedShippings = filteredWorkshopShippings.filter((shipping) =>
          (!state.currentOrder || shipping.orderNumber === state.currentOrder)
          && (!state.startDate || shipping.shippingDate >= state.startDate)
          && (!state.endDate || shipping.shippingDate <= state.endDate))
    } else {
      state.selectedShippings = []
    }

  } else {
    state.selectedShippings = []
  }

}

export const getItems = createAsyncThunk(
  "production/getItems",
  async (_, {rejectWithValue}) => {

    console.log("Getting items...")
    const response = await getProductionItems();
    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return rejectWithValue(response.data);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);


export interface IUpdateMarkingDone {
  key: string;
  done: boolean;
  order: number
}

export const setMarkingDone = createAsyncThunk(
  "production/setMarkingDone",
  async (markingUpdate: IUpdateMarkingDone, thunkAPI) => {

    // const state = thunkAPI.getState() as RootState;
    const response = await updateMarkingDone(markingUpdate);

    if (response.error) {
      return thunkAPI.rejectWithValue(response);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export interface IResultMarkingDone {
  key: string;
  doneAt: string;
  order: number
}

// after marking done is saved on the backend, reflect the change in the state
// to avoid reloading all the markings from the backend
const applyMarkingDone = (state: ProductionState, markingUpdate: IResultMarkingDone) => {

  state.workshopMarkings = state.workshopMarkings.map((workshop) => (
    {
      ...workshop,
      markings: workshop.markings.map((orderMarkings) => (
        {
          ...orderMarkings,
            markingsAggregrates: orderMarkings.markingsAggregrates.map((markingAggregate) => (
                {
                ...markingAggregate,
                doneAt: (markingUpdate.key === markingAggregate.key && orderMarkings.orderNumber === markingUpdate.order)
                    ? markingUpdate.doneAt : markingAggregate.doneAt
                }
            ))
        }
      ))
    }
  ))
}

export interface IProdExportParameters {
  view: string;
  startDate: string | null
  endDate: string | null;
  sellerId: number;
  orderNumber: number | null;
  markingType: number;
}

export const exportProduction = createAsyncThunk(
    "production/exportProduction",
    async (params: IProdExportParameters, thunkAPI) => {

      // const state = thunkAPI.getState() as RootState;
      const response = await requestExport(params);

      if (response.error) {
        return thunkAPI.rejectWithValue(response);
      }

      // The value we return becomes the `fulfilled` action payload
      return response;
    }
);

export interface IUpdateProductionDelivery {
  deliveryCode: string;
  deliveredAt: Date;
}

export const setWorkshopDelivered = createAsyncThunk(
  "production/setWorkshopDelivered",
  async (deliveryUpdate: IUpdateProductionDelivery, thunkAPI) => {

    // const state = thunkAPI.getState() as RootState;
    // console.log("setWorkshopDelivered with " + JSON.stringify(deliveryUpdate))

    const response = await updateWorkshopDelivered(deliveryUpdate);

    if (response.error) {
      return thunkAPI.rejectWithValue(response);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export interface IUpdateDeliveryOrder {
  deliveryCode: string;
  deliveryOrder: string;
}

export const setDeliveryOrder = createAsyncThunk(
    "production/setDeliveryOrder",
    async (deliveryUpdate: IUpdateDeliveryOrder, thunkAPI) => {

      const response = await updateDeliveryOrder(deliveryUpdate);

      if (response.error) {
        return thunkAPI.rejectWithValue(response);
      }

      // The value we return becomes the `fulfilled` action payload
      return response;
    }
);

export interface IUpdateProductionStatus {
  newOrderStatus: number;
  newWorkshopBoxId: number | null;
  prodItemIds: number[];
}

export const setItemsProductionStatus = createAsyncThunk(
  "production/setItemsProductionStatus",
  async (statusChanges: IUpdateProductionStatus, thunkAPI) => {

    // const state = thunkAPI.getState() as RootState;
    const response = await updateProductionStatus(statusChanges);

    if (response.error) {
      return thunkAPI.rejectWithValue(response);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const sendForIntegration = createAsyncThunk(
  "production/sendForIntegration",
  async (itemIds: number[], thunkAPI) => {

    // const state = thunkAPI.getState() as RootState;
    const response = await requestItemsIntegration(itemIds);

    if (response.error) {
      return thunkAPI.rejectWithValue(response);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);


export interface ISetDpdDelivery {
  orderNumber: number;
  oldTracking: string | null;
  dpdTracking: string;
  dpdComment: string;
  dpdPrice: number;
  prodItemIds: number[];
}

export const setDpdDelivery= createAsyncThunk(
  "production/setDpdDelivery",
  async (dpdDelivery: ISetDpdDelivery, thunkAPI) => {

    // const state = thunkAPI.getState() as RootState;
    const response = await saveDpdDelivery(dpdDelivery);

    if (response.error) {
      return thunkAPI.rejectWithValue(response);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getStocks = createAsyncThunk(
  "production/getStocks",
  async (_, {rejectWithValue}) => {

    const response = await getWorkshopStocks();
    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return rejectWithValue(response.data);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const addStockForVariant = createAsyncThunk(
  "production/addStockForVariant",
  async (variantId: number, thunkAPI) => {

    const state = thunkAPI.getState() as RootState;
    const response = await requestAddStock(state.production.currentWorkshop, variantId);
    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return thunkAPI.rejectWithValue(response.data);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);


export const deleteStockItem = createAsyncThunk(
  "production/deleteStockItem",
  async (stockItemId: number, thunkAPI) => {

    const state = thunkAPI.getState() as RootState;
    const response = await requestDeleteStock(state.production.currentWorkshop, stockItemId);
    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return thunkAPI.rejectWithValue(response.data);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export interface IUpdateStockItem {
  id: number;
  stock_box_id: number | null;
  prod_item_id?: number;
}

export const recordStockItem = createAsyncThunk(
  "production/recordStockItem",
  async (changes: IUpdateStockItem, thunkAPI) => {

    // const state = thunkAPI.getState() as RootState;
    const response = await updateStockItem(changes);

    if (response.error) {
      return thunkAPI.rejectWithValue(response);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getProducts = createAsyncThunk(
  "production/getProducts",
  async (_, thunkAPI) => {

    const state = thunkAPI.getState() as RootState;
    const response = await getDBProducts(state.production.selectedStockBrand, state.production.searchStockRef);
    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return thunkAPI.rejectWithValue(response.data);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);
export const getMarkings = createAsyncThunk(
  "production/getMarkings",
  async (_, {rejectWithValue}) => {

    const response = await getProductionMarkings();
    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return rejectWithValue(response.data);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getMarkingSeries = createAsyncThunk(
  "production/getMarkingSeries",
  async (_, {rejectWithValue}) => {

    // console.log("%cgetMarkingSeries ", "color: blue")
    const response = await getProductionMarkingSeries();
    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return rejectWithValue(response.data);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const productionSlice = createSlice({
  name: "production",
  initialState,
  reducers: {
    filterProduction: (state, action) => {
      // console.log("filterProduction with payload " + JSON.stringify(action.payload))
      state.filterKey = action.payload.toLowerCase();
      filterItems(state);
    },
    selectWorkshop: (state, action) => {
      setCurrentWorkshop(state, action.payload);
    },
    selectSeller: (state, action) => {
      state.currentSeller = action.payload;
      filterItems(state);
      filterMarkings(state);
      filterMarkingSeries(state);
    },
    selectOrder: (state, action) => {
      state.currentOrder = parseInt(action.payload);
      filterItems(state);
      filterMarkings(state);
      filterMarkingSeries(state);
    },
    selectItem: (state, action) => {
      const item = state.selectedOrders
        .flatMap((order) => order.items)
        .find((item) => item.id === action.payload);

      if (item) {
        item.checked = !item.checked;
      }
    },
    selectStartDate: (state, action) => {
      state.startDate = action.payload;
      filterItems(state);
      filterMarkingSeries(state);
    },
    selectEndDate: (state, action) => {
      state.endDate = action.payload;
      filterItems(state);
      filterMarkingSeries(state);
    },
    selectBoxesSortBy: (state, action) => {
      state.boxesSortBy = action.payload;
      updateWorkshopBoxes(state)
    },
    selectStocksView: (state, action) => {
      state.currentStocksView = action.payload;
    },
    selectStatus: (state, action) => {
      state.currentOrderStatus = action.payload;
    },
    selectBoutiques: (state, action) => {
      state.selectedBoutiques = action.payload;
      filterItems(state);
    },
    selectBrand: (state, action) => {
      state.selectedStockBrand = action.payload;
      filterStockItems(state);
    },
    searchStockRef: (state, action) => {
      state.searchStockRef = action.payload;
      filterStockItems(state);
    },
    toggleFilterStockNoBox: (state) => {
      state.filterStockNoBox = !state.filterStockNoBox;
      filterStockItems(state)
    },
    selectDelivery: (state, action) => {
      state.currentDelivery = action.payload;
    },
    selectMarkingsProductionDay: (state, action) => {
      // console.log("%cselectMarkingsStatus with payload " + JSON.stringify(action.payload), "color:blue")
      state.markingsProductionDay = action.payload;
      filterMarkings(state)
    },
    selectMarkingType: (state, action) => {
      // console.log("%cselectMarkingsStatus with payload " + JSON.stringify(action.payload), "color:blue")
      state.currentMarkingsType = action.payload;
      filterMarkings(state)
      filterMarkingSeries(state);
    },
    toggleFilterShippedMarkings: (state) => {
      state.filterShippedMarkings = !state.filterShippedMarkings;
      filterMarkings(state)
    },
    clearItems: (state) => {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder

/////////////////////////////////////////////////////////////////////////////
// getItems
/////////////////////////////////////////////////////////////////////////////

      .addCase(getItems.pending, (state) => {
        state.loaded = false;
        console.log("Pending getting items...")
      })
      .addCase(getItems.fulfilled, (state, action: any) => {
        console.log("Fulfilled getting items...")
        state.workshops = action.payload.workshops;
        updateWorkshopBoxes(state);
        state.loadedBoutiques = action.payload.boutiques;
        setCurrentWorkshop(state, action.payload.workshops[0].name);
        state.loaded = true;
        state.error = false;
      })
      .addCase(getItems.rejected, (state, action: any) => {
        console.log("Rejected getting items...")
        state.loaded = false;
        state.error = true;
      })

/////////////////////////////////////////////////////////////////////////////
// setWorkshopDelivered
/////////////////////////////////////////////////////////////////////////////
      .addCase(setWorkshopDelivered.pending, (state, action: any) => {
        state.saving = true;
      })
      .addCase(setWorkshopDelivered.fulfilled, (state, action: any) => {
        const workshop = stateCurrentWorkshop(state)
        const delivery = workshop?.deliveries
          .find((delivery) => delivery.deliveryCode === action.meta.arg.deliveryCode);

        if (workshop && delivery) {
          delivery.status = DELIVERED_STATUS;
          delivery.deliveredAt = action.meta.arg.deliveredAt;

          workshop.prodItems.forEach((item) => {
            if (item.deliveryCode === action.meta.arg.deliveryCode) {
              item.singleItems.forEach((singleItem) => {

                // protect against setting delivered status on items that are not integrated yet
                if (singleItem.orderStatus >= PRODUCTION_STARTED) {
                  singleItem.orderStatus = PRODUCTION_DELIVERED;
                }
              })
            }
          })

          filterItems(state);
        }
        state.saving = false;
        state.error = false;
      })
      .addCase(setWorkshopDelivered.rejected, (state, action: any) => {
        state.saving = false;
        state.error = true;
      })

/////////////////////////////////////////////////////////////////////////////
// setDeliveryOrder
/////////////////////////////////////////////////////////////////////////////
      .addCase(setDeliveryOrder.pending, (state, action: any) => {
        state.saving = true;
      })
      .addCase(setDeliveryOrder.fulfilled, (state, action: any) => {
        const workshop = state.workshops
            .find((workshop) => workshop.name === state.currentWorkshop)
        const delivery = workshop?.deliveries
            .find((delivery) => delivery.deliveryCode === action.meta.arg.deliveryCode);

        if (workshop && delivery) {
          delivery.procureOrder = action.meta.arg.deliveryOrder;
        }
        state.saving = false;
        state.error = false;
      })
      .addCase(setDeliveryOrder.rejected, (state, action: any) => {
        state.saving = false;
        state.error = true;
      })

/////////////////////////////////////////////////////////////////////////////
// setItemsProductionStatus
/////////////////////////////////////////////////////////////////////////////
      .addCase(setItemsProductionStatus.pending, (state, action: any) => {
        // console.log("setItemsProductionStatus pending with data " + JSON.stringify(action.meta.arg));
        state.saving = true;
      })
      .addCase(setItemsProductionStatus.fulfilled, (state, action: any) => {

        // update the state directly without reloading the whole production data from server cache
        const workshop = stateCurrentWorkshop(state)

        if (workshop) {

          workshop.prodItems.forEach((item) => {
            item.singleItems.forEach((singleItem) => {
              if (action.meta.arg.prodItemIds.includes(singleItem.prodItemId)) {
                singleItem.orderStatus = action.meta.arg.newOrderStatus;
                singleItem.workshopBoxId = action.meta.arg.newWorkshopBoxId;
              }
            })})

          updateAutomatedStatuses(state, workshop);
          filterItems(state);
          updateWorkshopBoxes(state)
        }

        state.saving = false;
        state.error = false;
      })
      .addCase(setItemsProductionStatus.rejected, (state, action: any) => {
        state.saving = false;
        state.error = true;
      })

/////////////////////////////////////////////////////////////////////////////
// sendForIntegration
/////////////////////////////////////////////////////////////////////////////
      .addCase(sendForIntegration.pending, (state, action: any) => {
        // console.log("sendForIntegration pending with data " + JSON.stringify(action.meta.arg));
        state.saving = true;
      })
      .addCase(sendForIntegration.fulfilled, (state, action: any) => {
        state.saving = false;
        state.error = false;
      })
      .addCase(sendForIntegration.rejected, (state, action: any) => {
        state.saving = false;
        state.error = true;
      })

/////////////////////////////////////////////////////////////////////////////
// setDpdDelivery
/////////////////////////////////////////////////////////////////////////////
      .addCase(setDpdDelivery.pending, (state, action: any) => {
        state.saving = true;
      })
      .addCase(setDpdDelivery.fulfilled, (state, action: any) => {

        // update the state directly without reloading the whole production data from server cache
        const workshop = stateCurrentWorkshop(state)

        if (workshop) {

          workshop.prodItems.forEach((item) => {

            // only update items that are in the order
            // plus only the items that have the old tracking number if it is specified,
            // if not, all items listed in the view where we assign the DPD delivery
            // (=which are in the list through their prodItemIds)
            if (item.orderNumber === action.meta.arg.orderNumber &&
              ((!action.meta.arg.oldTracking && item.singleItems
                  .some((singleItem) => action.meta.arg.prodItemIds.includes(singleItem.prodItemId)))
                || (!!item.shipping.tracking && item.shipping.tracking === action.meta.arg.oldTracking)))
            {
              // console.log("Updating Item " + item.id + " with tracking " + item.shipping.tracking + " and oldTracking is " + action.meta.arg.oldTracking + " (!action.meta.arg.oldTracking =" + !action.meta.arg.oldTracking + ") and (!item.shipping.tracking = " + !item.shipping.tracking + ")");
              item.singleItems.forEach((singleItem) => {
                if (action.meta.arg.prodItemIds.includes(singleItem.prodItemId)) {
                  singleItem.orderStatus = PRODUCTION_DELIVERY_START;
                }
              })
              item.shipping.mode = 'DPD';
              item.shipping.tracking = action.meta.arg.dpdTracking;
              item.shipping.comment = action.meta.arg.dpdComment;
              item.shipping.costCents = action.meta.arg.dpdPrice;
            }
          })

          updateAutomatedStatuses(state, workshop);
          filterItems(state);
          updateWorkshopBoxes(state)
        }

        state.saving = false;
        state.error = false;
      })
      .addCase(setDpdDelivery.rejected, (state, action: any) => {
        state.saving = false;
        state.error = true;
      })

/////////////////////////////////////////////////////////////////////////////
// getStocks
/////////////////////////////////////////////////////////////////////////////
      .addCase(getStocks.pending, (state) => {
        state.stocksLoaded = false;
      })
      .addCase(getStocks.fulfilled, (state, action: any) => {
        // console.log("getStocks.fulfilled with " + JSON.stringify(action.payload));
        state.workshopStockBoxes = action.payload.stockBoxes;
        state.workshopStockItems = action.payload.stockItems;
        state.brands = action.payload.brands;
        state.stocksLoaded = true;
        filterStockItems(state);
      })
      .addCase(getStocks.rejected, (state, action: any) => {
        // console.log("getMarkings.rejected");
        state.stocksLoaded = false;
        state.error = true;
      })

/////////////////////////////////////////////////////////////////////////////
// getProducts
/////////////////////////////////////////////////////////////////////////////
      .addCase(getProducts.pending, (state) => {
        state.productsLoading = true;
      })
      .addCase(getProducts.fulfilled, (state, action: any) => {
        // console.log("getStocks.fulfilled with " + JSON.stringify(action.payload));
        state.foundProducts = action.payload;
        state.productsLoading = false;
      })
      .addCase(getProducts.rejected, (state, action: any) => {
        // console.log("getMarkings.rejected");
        state.productsLoading = false;
        state.error = true;
      })

/////////////////////////////////////////////////////////////////////////////
// addStockForVariant
/////////////////////////////////////////////////////////////////////////////
      .addCase(addStockForVariant.pending, (state, action: any) => {
        state.saving = true;
      })
      .addCase(addStockForVariant.fulfilled, (state, action: any) => {
        const stockItem = action.payload as IStockItem;
        state.workshopStockItems.unshift(stockItem);
        state.searchStockRef = '';
        state.foundProducts = [];
        state.saving = false;
        state.error = false;
        filterStockItems(state);
      })
      .addCase(addStockForVariant.rejected, (state, action: any) => {
        state.saving = false;
        state.error = true;
      })

  /////////////////////////////////////////////////////////////////////////////
  // deleteStockItem
  /////////////////////////////////////////////////////////////////////////////
      .addCase(deleteStockItem.pending, (state, action: any) => {
        state.saving = true;
      })
      .addCase(deleteStockItem.fulfilled, (state, action: any) => {
        const stockItemId = action.payload as number;
        state.workshopStockItems = state.workshopStockItems.filter((item) => item.id !== stockItemId);
        state.saving = false;
        state.error = false;
        filterStockItems(state);
      })
      .addCase(deleteStockItem.rejected, (state, action: any) => {
        state.saving = false;
        state.error = true;
      })

/////////////////////////////////////////////////////////////////////////////
// recordStockItem
/////////////////////////////////////////////////////////////////////////////
      .addCase(recordStockItem.pending, (state, action: any) => {
        state.saving = true;
      })
      .addCase(recordStockItem.fulfilled, (state, action: any) => {
        const stockItemId = action.payload.id;
        const stockBoxId = action.payload.stockBoxId;
        const stockBoxName = action.payload.stockBoxName;
        const prodItemId = action.payload.prodItemId;
        const orderNumber = action.payload.orderNumber;

        state.workshopStockItems.forEach((stockItem) => {
          if (stockItem.id === stockItemId) {
            stockItem.stockBoxId = stockBoxId;
            stockItem.stockBoxName = stockBoxName;
            stockItem.prodItemId = prodItemId;
            stockItem.orderNumber = orderNumber;
          }
        })

        state.saving = false;
        state.error = false;
        filterStockItems(state);
      })
      .addCase(recordStockItem.rejected, (state, action: any) => {
        state.saving = false;
        state.error = true;
      })

/////////////////////////////////////////////////////////////////////////////
// getMarkings
/////////////////////////////////////////////////////////////////////////////
      .addCase(getMarkings.pending, (state) => {
        // console.log("getMarkings.pending");
        // state.markingsLoaded = false;
      })
      .addCase(getMarkings.fulfilled, (state, action: any) => {
        // console.log("getMarkings.fulfilled");
        state.workshopMarkings[0] = action.payload;
        state.markingsLoaded = true;
        filterMarkings(state);
      })
      .addCase(getMarkings.rejected, (state, action: any) => {
        // console.log("getMarkings.rejected");
        state.markingsLoaded = false;
        state.error = true;
      })

/////////////////////////////////////////////////////////////////////////////
// setMarkingDone
/////////////////////////////////////////////////////////////////////////////
      .addCase(setMarkingDone.pending, (state, action: any) => {
        state.markingSaving = true;
        state.markingBeingSaved = action.meta.arg.key;
      })
      .addCase(setMarkingDone.fulfilled, (state, action: any) => {
        applyMarkingDone(state, action.payload);
        filterMarkings(state);
        state.markingSaving = false;
        state.markingBeingSaved = '';
        state.error = false;
      })
      .addCase(setMarkingDone.rejected, (state, action: any) => {
        state.markingSaving = false;
        state.markingBeingSaved = '';
        state.error = true;
      })

/////////////////////////////////////////////////////////////////////////////
// export production
/////////////////////////////////////////////////////////////////////////////
      .addCase(exportProduction.pending, (state, action: any) => {
        state.markingSaving = true;
      })
      .addCase(exportProduction.fulfilled, (state, action: any) => {
        state.markingSaving = false;
        state.error = false;
      })
      .addCase(exportProduction.rejected, (state, action: any) => {
        state.markingSaving = false;
        state.error = true;
      })

/////////////////////////////////////////////////////////////////////////////
// getMarkingSeries
/////////////////////////////////////////////////////////////////////////////
      .addCase(getMarkingSeries.pending, (state) => {
        // console.log("getMarkingSeries.pending");
        state.markingSeriesLoaded = false;
      })
      .addCase(getMarkingSeries.fulfilled, (state, action: any) => {
        state.workshopMarkingSeries[0] = action.payload;
        state.markingSeriesLoaded = true;
        filterMarkingSeries(state);
      })
      .addCase(getMarkingSeries.rejected, (state, action: any) => {
        // console.log("getMarkingSeries.rejected");
        state.markingSeriesLoaded = false;
        state.error = true;
      })

  }
});

export const {
  filterProduction,
  selectWorkshop,
  selectSeller,
  selectOrder,
  selectItem,
  selectStartDate,
  selectEndDate,
  selectBoxesSortBy,
  selectStocksView,
  toggleFilterStockNoBox,
  selectStatus,
  selectBoutiques,
  selectBrand,
  searchStockRef,
  selectMarkingsProductionDay,
  selectMarkingType,
  toggleFilterShippedMarkings,
  clearItems
} = productionSlice.actions;


const stateCurrentWorkshop = (state: ProductionState) => state.workshops
    .find((workshop) => workshop.name === state.currentWorkshop)

export default productionSlice.reducer;

export const savingSelector = ((state: RootState) => state.production.saving);

export const productionStateSelector = ((state: RootState) => state.production);

export const workshopListSelector = ((state: RootState) => state.production
  .workshops.map(workshop => workshop.name));

export const currentWorkshopSelector = ((state: RootState) => stateCurrentWorkshop(state.production));

export const currentOrderSelector = ((state: RootState) => state.production.currentOrder);
export const currentSellerSelector = ((state: RootState) => state.production.currentSeller);
export const currentStockViewSelector = ((state: RootState) => state.production.currentStocksView);
export const currentOrderStatusSelector = ((state: RootState) => state.production.currentOrderStatus);

export const productionOrdersSelector = ((state: RootState) => state.production.selectedOrders);

export const itemsLoadedSelector = ((state: RootState) => state.production.loaded);

export const markingsLoadedSelector = ((state: RootState) => state.production.markingsLoaded);
export const markingSavingSelector = ((state: RootState) => state.production.markingSaving);
export const markingBeingSavedSelector = ((state: RootState) => state.production.markingBeingSaved);

export const markingsProductionDaySelector = ((state: RootState) => state.production.markingsProductionDay);
export const markingsTypeSelector = ((state: RootState) => state.production.currentMarkingsType);
export const filterShippedMarkingsSelector = ((state: RootState) => state.production.filterShippedMarkings);

export const markingsHistorySelector = ((state: RootState) => state.production
    .workshopMarkings.find((w) => w.name === state.production.currentWorkshop)?.markingsHistory);

export const markingsSelector = ((state: RootState) => state.production.selectedMarkings);

export const markingSeriesLoadedSelector = ((state: RootState) => state.production.markingSeriesLoaded);
export const markingSeriesSelector = ((state: RootState) => state.production.selectedMarkingSeries);
export const shippingsSelector = ((state: RootState) => state.production.selectedShippings);

export const dateRangeSelector = ((state: RootState) => ({
  startDate: state.production.startDate,
  endDate: state.production.endDate
}));

export const boxesSortBySelector = ((state: RootState) => state.production.boxesSortBy);

export const boutiquesSelector = ((state: RootState) => state.production.loadedBoutiques);

export const currentWorkshopBoxesSelector = ((state: RootState) => state.production.workshops
  .find(workshop => workshop.name === state.production.currentWorkshop)?.boxes);

export const StockBoxesSelector = ((state: RootState) => state.production.workshopStockBoxes);

export const SelectedStockItemsSelector = ((state: RootState) => state.production.selectedStockItems);
export const AllStockItemsSelector = ((state: RootState) => state.production.workshopStockItems);

export const StockBrandsSelector = ((state: RootState) => state.production.brands);

export const stocksLoadedSelector = ((state: RootState) => state.production.stocksLoaded);
export const SelectedBrandSelector = ((state: RootState) => state.production.selectedStockBrand);
export const SearchStockRefSelector = ((state: RootState) => state.production.searchStockRef);
export const FilterStockNoBoxSelector = ((state: RootState) => state.production.filterStockNoBox);

export const FoundProductsSelector = ((state: RootState) => state.production.foundProducts);
export const ProductsLoadingSelector = ((state: RootState) => state.production.productsLoading);

export const selectedOrdersDeliveriesSelector = ((state: RootState) => state.production.selectedOrdersDeliveries);

export const DagobaStockDeliveryItemsSelector = ((state: RootState) => state.production.selectedOrdersDeliveries
  .filter((delivery: ISelectedDelivery) => delivery.code === 'DAGOBA')[0]?.items || []);

const firstSingleItemInBox = (order: ISelectedOrder | undefined) => order ? order.items
    .map((item) => item.singleItems)
    .flatMap((singleItems) => singleItems
        .find((singleItem) => (
            singleItem.orderStatus === PRODUCTION_IN_BOX || singleItem.orderStatus === PRODUCTION_ORDER_COMPLETE
        )))
    .filter((o) => o)[0] : undefined

const orderWorkshopBox = ((state: ProductionState, order: ISelectedOrder | number | undefined) => {

  let orderObject: ISelectedOrder | undefined;

  // if order is just the order number, find the order object based on the number
  if (typeof order === 'number') {
    orderObject = state.selectedOrders.find((o) => o.orderNumber === order);
  } else {
    orderObject = order as ISelectedOrder;
  }

  const anySingleItemInBox = firstSingleItemInBox(orderObject)

  if (anySingleItemInBox) {
    const workShopBoxes = state.workshops
        .find(workshop => workshop.name === state.currentWorkshop)?.boxes;

    if (anySingleItemInBox && workShopBoxes) {
      return workShopBoxes.find((box) => box.id === anySingleItemInBox.workshopBoxId);
    }
  }

  return undefined;
});

export const orderWorkshopBoxSelector = createSelector(
    [
      state => state.production,
      (state: RootState, order: ISelectedOrder | number | undefined) => order
    ],
    (productionState, order) => orderWorkshopBox(productionState, order)
);

const prodItemForOrderNumberAndId = (state: ProductionState,
                                     params: {orderNumber: number | undefined, prodItemId: number | undefined}) => {
  if (!params.orderNumber || !params.prodItemId) {
    return undefined;
  }

  return state.workshops
    .find((workshop) => workshop.name === state.currentWorkshop)?.prodItems
    .filter((item) => item.orderNumber === params.orderNumber)
    .flatMap((item) => item.singleItems)
    .find((singleItem) => singleItem.prodItemId === params.prodItemId)
}

export const prodItemSelector = createSelector(
  [
    state => state.production,
    (state: RootState, params: {orderNumber: number | undefined, prodItemId: number | undefined}) => params
  ],
  (productionState, params) =>
    prodItemForOrderNumberAndId(productionState, params)
);


export interface INextProductionStatus {
  status: IWorkshopStatus | undefined;
  action: string;
}

const nextOrderStatuses = (state: ProductionState, currentOrderStatus: number, procureSource: number) => {
  const nextPossibleStatuses: INextProductionStatus[] = []

  switch (currentOrderStatus) {

      // item identified, can go to box or to production
    case PRODUCTION_DELIVERED:
      nextPossibleStatuses.push({
        status: statusForOrderStatus(state, PRODUCTION_PROBLEM),
        action: 'changeStatus',
      })
      nextPossibleStatuses.push({
        status: statusForOrderStatus(state, PRODUCTION_IN_BOX),
        action: 'selectWorkshopBox',
      })
      nextPossibleStatuses.push({
        status: statusForOrderStatus(state, PRODUCTION_IN_BOX),
        action: 'confirmStoreInBox',
      })
      nextPossibleStatuses.push({
        status: statusForOrderStatus(state, PRODUCTION_MARKINGS_DONE),
        action: 'changeStatus',
      })
      break;

    case PRODUCTION_PROBLEM:
      nextPossibleStatuses.push({
        status: statusForOrderStatus(state, PRODUCTION_IN_BOX),
        action: 'selectWorkshopBox',
      })
      nextPossibleStatuses.push({
        status: statusForOrderStatus(state, PRODUCTION_MARKINGS_DONE),
        action: 'changeStatus',
      })
      break;

    case PRODUCTION_IN_BOX:
    case PRODUCTION_ORDER_COMPLETE:
      nextPossibleStatuses.push({
        status: statusForOrderStatus(state, PRODUCTION_PROBLEM),
        action: 'changeStatus',
      })
      nextPossibleStatuses.push({
        status: statusForOrderStatus(state, PRODUCTION_MARKINGS_DONE),
        action: 'changeStatus',
      })
      break;

    case PRODUCTION_WITH_LOGOS:
      nextPossibleStatuses.push({
        status: statusForOrderStatus(state, PRODUCTION_PROBLEM),
        action: 'changeStatus',
      })
      nextPossibleStatuses.push({
        status: statusForOrderStatus(state, PRODUCTION_IN_BOX),
        action: 'selectWorkshopBox',
      })
      nextPossibleStatuses.push({
        status: statusForOrderStatus(state, PRODUCTION_IN_BOX),
        action: 'confirmStoreInBox',
      })
      nextPossibleStatuses.push({
        status: statusForOrderStatus(state, PRODUCTION_MARKINGS_DONE),
        action: 'changeStatus',
      })
      break;

    case PRODUCTION_MARKINGS_DONE:
      nextPossibleStatuses.push({
        status: statusForOrderStatus(state, PRODUCTION_PROBLEM),
        action: 'changeStatus',
      })
      nextPossibleStatuses.push({
        status: statusForOrderStatus(state, PRODUCTION_IN_BOX),
        action: 'selectWorkshopBox',
      })
      nextPossibleStatuses.push({
        status: statusForOrderStatus(state, PRODUCTION_IN_BOX),
        action: 'confirmStoreInBox',
      })
      break;

    default:
      if (currentOrderStatus <= PRODUCTION_STARTED) {

        // only if Dagoba stock or ORIFLAM
        if (procureSource === SOURCE_DAGOBA || procureSource === SOURCE_ORIFLAM) {
          nextPossibleStatuses.push({
            status: statusForOrderStatus(state, PRODUCTION_PROBLEM),
            action: 'changeStatus',
          })
          nextPossibleStatuses.push({
            status: statusForOrderStatus(state, PRODUCTION_IN_BOX),
            action: 'selectWorkshopBox',
          })
          nextPossibleStatuses.push({
            status: statusForOrderStatus(state, PRODUCTION_IN_BOX),
            action: 'confirmStoreInBox',
          })
          nextPossibleStatuses.push({
            status: statusForOrderStatus(state, PRODUCTION_MARKINGS_DONE),
            action: 'changeStatus',
          })
        } else {
          nextPossibleStatuses.push({
            status: undefined,
            action: "En attente de livraison"
          })
        }
      }
  }

  // console.log("Returning next possible statuses for " + currentOrderStatus + ": " + JSON.stringify(nextPossibleStatuses) )

  return nextPossibleStatuses
}

export const nextOrderStatusesSelector = createSelector(
  [
    state => state.production,
    (state: RootState, payload: {orderStatus: number, procureSource:number}) => payload
  ],
  (productionState, payload) => nextOrderStatuses(productionState, payload.orderStatus, payload.procureSource)
);




export const ColorsForOrderStatus = (orderStatus: number) => {
  const colorDef = PARAMS_FOR_ORDER_STATUS
    .find((status) => status.orderStatus === orderStatus);

  if (colorDef) {
    return {bgColor: colorDef.bgColor, fgColor: colorDef.fgColor};
  } else {
    return {bgColor: 'white', fgColor: 'black'};
  }
}

export const operatorForOrderStatus = (orderStatus: number) => {
  const colorDef = PARAMS_FOR_ORDER_STATUS
    .find((status) => status.orderStatus === orderStatus);

  if (colorDef) {
    return colorDef.operator;
  } else {
    return false;
  }
}

