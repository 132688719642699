import React, { useEffect, useState } from 'react';
import './logo-grid.scss';
import LogoCard from './LogoCard';

interface LogoGridProps {
  images: { src: string; url: string }[];
  vertical: number;
}

const NbLogosPerRow = [
  {maxWidth: 512, horizontal: 4},
  {maxWidth: 600, horizontal: 5},
  {maxWidth: 768, horizontal: 6},
  {maxWidth: 960, horizontal: 8},
  {maxWidth: 1280, horizontal: 10},
  {maxWidth: 1920, horizontal: 12}
  ]

const LogoGrid: React.FC<LogoGridProps> = ({ images, vertical}) => {
  const horizontal = NbLogosPerRow.find((nbLogos) =>
    window.innerWidth < nbLogos.maxWidth)?.horizontal || 12;

  const [grid, setGrid] = useState<{ src: string; url: string }[]>([]);
  const [currentIndex, setCurrentIndex] = useState(horizontal * vertical);
  const [lastFlippedIndex, setLastFlippedIndex] = useState<number | null>(null);

  const initializeGrid = () => {
    const shuffledImages = images.sort(() => 0.5 - Math.random());
    const initialGrid = shuffledImages.slice(0, horizontal * vertical);
    setGrid(initialGrid);
  };

  useEffect(() => {
    initializeGrid();
    const interval = setInterval(initializeGrid, 1800000); // 15 minutes in milliseconds
    return () => clearInterval(interval);
  }, [images, horizontal, vertical]);

  // Periodically replace a random cell with a "new" logo
  useEffect(() => {
    const interval = setInterval(() => {
      setGrid((prevGrid) => {
        const newGrid = [...prevGrid];
        let nextLogoIndex = currentIndex;

        // Find the next logo not in the grid
        do {
          nextLogoIndex = (nextLogoIndex + 1) % images.length;
        } while (newGrid.some((l) => l.src === images[nextLogoIndex].src));

        setCurrentIndex(nextLogoIndex);

        // Generate a random index different from the last flipped one
        let randomIndex;
        do {
          randomIndex = Math.floor(Math.random() * newGrid.length);
        } while (randomIndex === lastFlippedIndex);

        setLastFlippedIndex(randomIndex);

        // Replace the selected card
        newGrid[randomIndex] = images[nextLogoIndex];

        // Optionally reset all cards in the grid to phase 0
        document
          .querySelectorAll('.logo-flip-container')
          .forEach((el) => {
            el.classList.remove('flip-phase-1');
            el.classList.remove('flip-phase-2');
          });

        return newGrid;
      });
    }, 1600);

    return () => clearInterval(interval);
  }, [images, currentIndex, lastFlippedIndex]);

  return (
    <div className="logo-grid">
      {Array.from({ length: vertical }).map((_, rowIndex) => (
        <div key={`lgcrow-${rowIndex}`} className="logo-row">
          {grid
            .slice(rowIndex * horizontal, (rowIndex + 1) * horizontal)
            .map((logo, colIndex) => (
              <LogoCard key={`lgcrd-${rowIndex}-${colIndex}`} logo={logo} />
            ))}
        </div>
      ))}
    </div>
  );
};

export default LogoGrid;
