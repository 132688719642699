import React from "react";

const KeikoSvg = (height: string) => {

  return (
    <svg version="1.1" viewBox="0 0 64 64" style={{'height': height}}>
      <g className="icon">
        <path d="M22.5,18.7l0.6,8.8l8.4-13.6c-1.2-0.1-2.2-0.8-2.8-1.9L25.2,6c-0.5-0.8-0.6-1.8-0.4-2.7l-6.1,1.4
					c-0.8,0.2-1.5,0.8-1.6,1.7l-5.6,27.6c-0.2,1.2,0.6,2.3,1.8,2.5l2.3,0.4c1.1,0.2,2.2-0.5,2.4-1.6l4-16.5
					C22,18.3,22.4,18.4,22.5,18.7L22.5,18.7z"/>
        <path d="M30.5,32.8h-7.4c0,0.3-0.1,0.7-0.1,1l-2.5,25.6c-0.1,1.3,0.9,2.4,2.1,2.4h18.7c1.3,0,2.3-1.1,2.1-2.4
					l-2.6-26.3c0-0.1,0-0.2,0-0.3H35v6.7c0,0.6-0.5,1.1-1.1,1.1c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8v-6.6l-3.3,12.5
					c-0.2,0.6-0.8,0.9-1.3,0.8c-0.5-0.1-0.8-0.6-0.8-1.1c0-0.1,0-0.2,0-0.3L30.5,32.8L30.5,32.8z"/>
        <path d="M47,6.3c-0.2-0.8-0.8-1.5-1.6-1.7l-6.1-1.4c0.2,0.9,0.1,1.8-0.4,2.7L36,10.8l-0.5,0.8L35,12.5L23.7,30.6
					h17.1c0-0.7,0-1.4,0.1-2l0.7-9.9c0-0.3,0.5-0.4,0.6,0l4,16.5c0.3,1.1,1.3,1.8,2.4,1.6l2.3-0.4c1.2-0.2,2-1.3,1.8-2.5L47,6.3
					L47,6.3z"/>
        <path d="M23.2,31.2c0.2-0.1,0.3-0.2,0.4-0.4l0.1-0.2h-0.5C23.2,30.8,23.2,31,23.2,31.2L23.2,31.2z"/>
        <polygon points="32.8,32.8 32.8,32.9 32.8,32.8 32.8,32.8 				"/>
        <path d="M33.2,11.2c0.1-0.1,0.1-0.2,0.2-0.2l0.3-0.5l3.2-5.6c0.6-1-0.2-2.4-1.4-2.4h-7c-1.2,0-2,1.3-1.4,2.4
					l3.5,6.1C31.2,11.9,32.5,12,33.2,11.2L33.2,11.2z"/>
      </g>
    </svg>
  );
}


export default KeikoSvg;

