import ColorTag from "../catalog/ColorTag";
import React, {useContext, useEffect, useState} from "react";
import {ProductPricesString} from "../../app/product";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../app/store";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  Grid, Tabs, Tab, FormGroup, FormControlLabel, Checkbox, CircularProgress,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import {CustomButton} from "../utils/CustomButton";
import {
  AllPersoAddonsActivatedToDefault,
  IAddonInfos,
  ICollectionProduct,
  openProductConfig,
  IPersoInfos,
  saveProductConfig,
  BoutiqueColorToHex,
  DetectBoutiqueColors,
  IBoutiqueInfos,
  ICollectionProductColor,
  BoutiqueAllColors,
  RequestPreviewData,
  RequestPreviewColor,
  ProductAllAddons,
  ColProductAddonExcludedAddons, ColProductExcludedAddonsForLogoAddons, PLAN_STANDARD, IProductImage
} from "./boutiqueSlice";
import Box from "@mui/material/Box";
import {logosSelector, TECHNIQUE_EBD} from "../logos/logosSlice";
import {LogoAddonsList} from "./LogoAddonsList";
import {FormattedMessage, useIntl} from "react-intl";
import {PersoAddonsList} from "./PersoAddonsList";
import {SelectProductMargin} from "./SelectProductMargin";
import InlineEdit from "../utils/InlineEdit";
import {arrayMoveImmutable, arrEq} from "../../app/utils";
import {previewBoutiqueProduct} from "../../app/boutiqueAPI";
import {useHelpTips} from "../../app/helpToolTip";
import {BoudaToolTip} from "../utils/BoudaToolTip";
import {openProduct} from "../productDetails/productDetailsSlice";
import {openDocAtAnchor} from "../../app/website";
import HelpIcon from "@mui/icons-material/Help";
import {I18nContext} from "../locale/LocaleWrapper";

const MAX_COLORS = 3;

export const CollectionProductConfig = (props: {
  collectionProduct: ICollectionProduct,
  boutique: IBoutiqueInfos,
  show: boolean,
  editMode: boolean
}) => {

  const {collectionProduct, boutique, show, editMode} = props;
  const {product, collectionId, version, productId} = collectionProduct

  const selectedProduct = useSelector((state : RootState) => state.selection.products.find(p => p.product.id === props.collectionProduct.productId));
  const discontinuedColors = selectedProduct?.colors.filter(c => c.discontinued).map(c => c.friendlyColor) || [];
  const discontinued = collectionProduct.colors.filter(c => discontinuedColors.includes(c.colorImages.friendlyColor)).map(c => c.colorImages.friendlyColor);
  const obsolete = selectedProduct?.obsolete || false;

  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();
  const {locale} = useContext(I18nContext);

  // Help tips of this component
  useHelpTips(editMode ? 'product-config' : 'none', intl, dispatch);

  const logos = useSelector(logosSelector);

  // We only show logos that are compatible with the product category
  // Martial products can only have embroidery logos
  const productLogos = logos.filter((logo) => product.category !== 'martial' || logo.technique === TECHNIQUE_EBD);

  const currentboutiqueColors = BoutiqueAllColors(boutique)

  const [tabShown, setTabShown] = useState(0);

  const [colors, setColors] = useState<ICollectionProductColor[]>(collectionProduct.colors);

  const firstColorName = colors[0].colorImages.friendlyColor;

  //----------------------------------------------------
  //------------------- MARGIN -------------------
  const [margin, setMargin] = useState(collectionProduct.margin);

  //----------------------------------------------------
  //------------------- MARGIN -------------------
  const [customTitle, setCustomTitle] = useState(collectionProduct.customTitle);

  //----------------------------------------------------
  //------------------- MULTI LOGOS -------------------
  //
  const [multiLogos, setMultiLogos] = useState<boolean>(collectionProduct.multiLogos);

  //----------------------------------------------------
  //------------------- PERSO ADDONS -------------------
  //
  const [persoAllowed, setPersoAllowed] = useState<boolean>(collectionProduct.persoAllowed);

  const handlePersoAllowedChange = (allowed:boolean) => {
    if (allowed) {
      setPersoAllowed(true);
      setPersoAddons(allPersoAddonsActivated());
    } else {
      setPersoAllowed(false);
      setPersoAddons([]);
    }
  }
  
  const [persoExpanded, setPersoExpanded] = useState<boolean>(false);

  const initialPersoAddons = collectionProduct.persos.map((perso) => ({
    ...perso,
      colorList: perso.colorList.map((color) => DetectBoutiqueColors(currentboutiqueColors, color))
    }));
  const [persoAddons, setPersoAddons] = useState<IPersoInfos[]>(initialPersoAddons);

  const setPersoInfos = (persoInfos: IPersoInfos, colorIndex: number) => {

    // all perso addons of this product color images
    const imagePersoAddons = colors[colorIndex].colorImages.images.map((image) => image.persoAddons).flat()

    // we need to know which background has the changed addon, so we can apply the same color to all addons with the same background
    const persoAddon = imagePersoAddons.find((adInf) => adInf.id === persoInfos.addonId);
    // console.log("Setting persoAddon", JSON.stringify(persoAddon));

    // Just for debug
    const persoAddonName = persoAddon?.name || '';
    if (persoAddon === undefined || persoAddonName !== persoInfos.name) {
      console.log("%cERROR: persoAddon is undefined or persoAddonName " + persoAddonName + " !== persoInfos.name " + persoInfos.name, "color: red");
      return;
    } else {
      // console.log("%cOK: Setting perso addon " + persoAddonName + " with lightBg=" + persoAddon.lightBg + " to " + JSON.stringify(persoInfos), "color: green");
    }

    let newPersoAddons = [...persoAddons];

    // Search if the addon is already present in the list
    // by addon name (not addon id, because the id is not the same for each color)
    let persoAddonInfosIndex = newPersoAddons.findIndex((adInf) => adInf.name === persoInfos.name);

    if (persoAddonInfosIndex === -1) {
      console.log("\tAdding...");
      newPersoAddons.push(persoInfos);

      // After adding a logo, deactivate all the addons that are excluded by the new logo
      const excludedAddons = ColProductAddonExcludedAddons(collectionProduct, persoInfos.name);
      newPersoAddons = newPersoAddons.filter((adInf) => !excludedAddons.includes(adInf.name));

    } else {
      // console.log("\tUpdating at index " + persoAddonInfosIndex + " value " + JSON.stringify(persoInfos));
      newPersoAddons[persoAddonInfosIndex] = persoInfos;
    }

    // console.log("Should update perso addons colors of all addons of color " + colorIndex);
    newPersoAddons.forEach((perso, index  ) => {

      // find the addon with the same name on this product color images
      const originalColorAddon = imagePersoAddons.find((adInf) => adInf.name === perso.name);
      // console.log("In color " + colorIndex + " perso addon " + index + " " + perso.name + " lightBg=" + (originalColorAddon ? originalColorAddon.lightBg : '?') + " " + JSON.stringify(perso));
      if (!originalColorAddon) {
        console.log("%c\tCannot find originalColorAddon for ", "color: red", perso.name);
      } else {
        if (originalColorAddon.lightBg == persoAddon.lightBg) {
          // console.log("%c\tUpdating persoAddon color " + perso.colorList[colorIndex] + " to " + persoInfos.colorList[colorIndex], "color: green");
          newPersoAddons[index].colorList[colorIndex] = persoInfos.colorList[colorIndex];
        }
      }
    })

    setPersoAddons(newPersoAddons);
  }

  const persoAddonsWithoutLogoExcluded = (logoAddonName: string, newPersoAddons: IPersoInfos[]) => {

    const excludedAddons = ColProductAddonExcludedAddons(collectionProduct, logoAddonName);

    return(newPersoAddons.filter((adInf) => !excludedAddons.includes(adInf.name)));
  }

  const allPersoAddonsActivated = () => AllPersoAddonsActivatedToDefault(
    colors.map((color) => color.colorImages),
    boutique.color_dark_bg, boutique.color_light_bg);

  //----------------------------------------------------
  //------------------- LOGO ADDONS -------------------
  //
  const initialColorLogoAddons = colors.map((color) => color.logoAddons);
  const [colorLogoAddons, setColorLogoAddons] = useState<IAddonInfos[][]>(initialColorLogoAddons);

  // const allExcludedAddons = colorLogoAddons.map((addons) => addons
  //   .map(a => ColProductAddonExcludedAddons(collectionProduct, a.name))).flat(2)
  //   .filter((v,i,a)=>a.indexOf(v)==i); // unique values

  const allExcludedAddons = ColProductExcludedAddonsForLogoAddons(collectionProduct, colorLogoAddons)

  // console.log('Rendering with colorLogoAddons ', JSON.stringify(colorLogoAddons));

  const setAddonInfos = (colorIndex: number, addonInfos: IAddonInfos) => {
    let newColorLogoAddons = [...colorLogoAddons];
    let newColorLogoAddonsForColor = [...newColorLogoAddons[colorIndex]];

    // We will redefine allowed and activated perso addons after changing the logo addons
    let newPersoAddons = persoAllowed ? allPersoAddonsActivated() : persoAddons;

    // Setting one of the logoIds to 0 means that the logo on the addon is removed
    // only the first color is allowed to remove the addon logo
    if ((addonInfos.logoIds.includes(0) || addonInfos.logoIds.length === 0) && colorIndex === 0) {
      newColorLogoAddonsForColor = newColorLogoAddonsForColor.filter((adInf) => adInf.addonId !== addonInfos.addonId);
    } else {

      // Search if the addon is already present in the list
      let logoAddonInfosIndex = newColorLogoAddonsForColor.findIndex((adInf) => adInf.addonId === addonInfos.addonId);

      if (logoAddonInfosIndex === -1) {
        newColorLogoAddonsForColor.push(addonInfos);

        // After adding a logo, deactivate all the addons that are excluded by the new logo
        const excludedAddons = ColProductAddonExcludedAddons(collectionProduct, addonInfos.name);
        newColorLogoAddonsForColor = newColorLogoAddonsForColor.filter((adInf) => !excludedAddons.includes(adInf.name));
        
      } else {
        // console.log("Updating logo addon " + addonInfos.name + " to " + JSON.stringify(addonInfos));
        newColorLogoAddonsForColor[logoAddonInfosIndex] = addonInfos;
      }

      // console.log("Setting a logo addon, newPersoAddons before excluding => ", JSON.stringify(newPersoAddons));

      newPersoAddons = persoAddonsWithoutLogoExcluded(addonInfos.name, newPersoAddons);
    }

    setPersoAddons(newPersoAddons);

    newColorLogoAddons[colorIndex] = newColorLogoAddonsForColor;

    // If the first color is changed, we update all the colors with the same selection of activated addons
    // while making sure not to change the potentially already selected logoVersion
    if (colorIndex === 0) {

      // Prepare empties array to recreate addonInfos for all other colors
      const addonInfosForOtherColors: IAddonInfos[][] = colors.map((color) => []);

      newColorLogoAddonsForColor.forEach((adInf) => {

        const addonName = ProductAllAddons(collectionProduct)
          .find((addon) => addon.id === adInf.addonId)?.name;

        if (addonName) {
            colors.forEach((color, c_idx) => {
              if (c_idx !== 0) {
                const correspondingAddon = colors[c_idx].colorImages.images.
                  map((image) => image.logoAddons).flat().
                  find((addon) => addon.name === addonName);

                const existingAddonInfo = correspondingAddon ? colorLogoAddons[c_idx]
                  .find((adInf) => adInf.addonId === correspondingAddon.id) : undefined;

                if (correspondingAddon)
                  if (!existingAddonInfo || arrEq(existingAddonInfo.logoIds, adInf.logoIds)) {
                    // console.log('Changing color ', c_idx, ' with addon ', addonName, ' id ', correspondingAddonId, ' existing addonInfo ', existingAddonInfo);
                    addonInfosForOtherColors[c_idx].push({
                      addonId: correspondingAddon.id,
                      name: addonName,
                      maxWidthSmall: correspondingAddon.maxWidthSmall,
                      maxWidthLarge: correspondingAddon.maxWidthLarge,
                      maxHeightSmall: correspondingAddon.maxHeightSmall,
                      maxHeightLarge: correspondingAddon.maxHeightLarge,
                      logoIds: adInf.logoIds,
                      logoVersionIds: adInf.logoVersionIds,
                      zoom: adInf.zoom,
                      optional: adInf.optional
                    });
                  } else {
                    // console.log('Keeping color ', c_idx, ' with addon ', addonName, ' id ', correspondingAddonId, ' existing addonInfo ', existingAddonInfo);

                    // we must maintain the same number of logoIds and logoVersionIds because
                    //

                    // we must activate the same
                    addonInfosForOtherColors[c_idx].push({
                      addonId: correspondingAddon.id,
                      name: addonName,
                      maxWidthSmall: correspondingAddon.maxWidthSmall,
                      maxWidthLarge: correspondingAddon.maxWidthLarge,
                      maxHeightSmall: correspondingAddon.maxHeightSmall,
                      maxHeightLarge: correspondingAddon.maxHeightLarge,
                      logoIds: existingAddonInfo.logoIds,
                      logoVersionIds: existingAddonInfo.logoVersionIds,
                      zoom: existingAddonInfo.zoom,
                      optional: adInf.optional      // optional is the same for all colors and decided by the first color
                    });
                  }
              }
            });
          }

        }
      );

      addonInfosForOtherColors.forEach((addonInfos, c_idx) => {
        if (c_idx !== 0) {
          newColorLogoAddons[c_idx] = addonInfosForOtherColors[c_idx]
        }
      });
    }

    setColorLogoAddons(newColorLogoAddons);
  }

  //----------------------------------------------------
  //------------------- PERSO COLORS -------------------
  //------------------- BOUTIQUE COLORS -------------------
  //
  // currentboutiqueColors contains color1, color2 and all colors from perso addon color
  const [boutiqueColors, setBoutiqueColors] = useState<string[]>(currentboutiqueColors);

  // back end saves colors as hex values, but we need to use color1, color2, etc. for the front end
  // so we read the hex values from the back end in each product color, and we convert them to the boutique color names
  const initialPersoColors = colors.map((color) =>
    DetectBoutiqueColors(currentboutiqueColors, color.persoColor));

  const [persoColors, setPersoColors] = useState<string[]>(initialPersoColors);

  const handlePersoColorChange = (colorIndex: number, color: string) => {
    let newPersoColors = [...persoColors];
    newPersoColors[colorIndex] = color;
    setPersoColors(newPersoColors);
  }

  const configChanged = () => {
    return (
      `${customTitle}` !== `${collectionProduct.customTitle}` ||
      margin !== collectionProduct.margin ||
      persoAllowed !== collectionProduct.persoAllowed ||
      JSON.stringify(initialPersoColors) !== JSON.stringify(persoColors) ||
      JSON.stringify(currentboutiqueColors) !== JSON.stringify(boutiqueColors) ||
      JSON.stringify(initialPersoAddons) !== JSON.stringify(persoAddons) ||
      JSON.stringify(initialColorLogoAddons) !== JSON.stringify(colorLogoAddons) ||
      JSON.stringify(collectionProduct.colors.map((color) => color.colorImages.color))
      !== JSON.stringify(colors.map((color) => color.colorImages.color))
    ) 
  }

  const configActions = () => {
    if (configChanged()) {
      return (
        <div className="save-actions">
          <CustomButton onClick={handleCancel}>
            <FormattedMessage id="global.cancel" />
          </CustomButton>
          <Button variant="contained" color="success" onClick={handleSave}>
            <FormattedMessage id="global.save" />
          </Button>
        </div>
      )
    }
  }

  const removeColor = (colorIndex: number) => {
    // console.log('Removing color ' + colorIndex);
    setColors(colors.filter((color, index) => index !== colorIndex));
    setPersoColors(persoColors.filter((color, index) => index !== colorIndex));
    setColorLogoAddons(colorLogoAddons.filter((color, index) => index !== colorIndex));
    setPersoAddons(persoAddons.map((perso) => ({
      ...perso,
      colorList: perso.colorList.filter((color, index) => index !== colorIndex)
    })));
  }

  const moveColorLeft = (colorIndex: number) => {
    // console.log('Moving color ' + colorIndex + ' to the left');
    setColors(arrayMoveImmutable(colors, colorIndex, colorIndex - 1));
    setPersoColors(arrayMoveImmutable(persoColors, colorIndex, colorIndex - 1));
    setColorLogoAddons(arrayMoveImmutable(colorLogoAddons, colorIndex, colorIndex - 1));
    setPersoAddons(persoAddons.map((perso) => ({
      ...perso,
      colorList: arrayMoveImmutable(perso.colorList, colorIndex, colorIndex - 1)
    })));
    setTabShown(colorIndex - 1);
  }

  const handleClose = () => {
    dispatch(openProductConfig(null))
  }

  const handleCancel = () => {
    setColors(collectionProduct.colors);
    setPersoColors(collectionProduct.colors.map((color) =>
                          DetectBoutiqueColors(currentboutiqueColors, color.persoColor)));
    setColorLogoAddons(collectionProduct.colors.map((color) => color.logoAddons));
    setMargin(collectionProduct.margin);
    setCustomTitle(collectionProduct.customTitle);
    setPersoAllowed(collectionProduct.persoAllowed);
    setPersoAddons(initialPersoAddons);
    setBoutiqueColors(currentboutiqueColors);
  }

  const handleSave = () => {
    // Before save, we convert all color codes to hex value
    const persoColorsConverted = persoColors.map((color) => BoutiqueColorToHex(boutiqueColors, color));

    const persoAddonsWithConvertedColors = persoAddons.filter((persoAddon) =>
      !allExcludedAddons.some((excludedAddonName: string) => persoAddon.name === excludedAddonName)).
    map((persoAddon) => (
      {
        ...persoAddon,
        colorList: persoAddon.colorList.map((color) => BoutiqueColorToHex(boutiqueColors, color))
      }));

    // console.log('%cSaving config with persoAddonsWithConvertedColors = ' + JSON.stringify(persoAddonsWithConvertedColors), 'color: green');

    // console.log('%cSaving product config => ', 'color: green', persoAddonsWithConvertedColors);

    dispatch(saveProductConfig(
      {
        id: product.id,
        productId: productId,
        collectionId: collectionId,
        version: version,
        margin: margin,
        customTitle: customTitle,
        colors: colors,
        persoAllowed: persoAllowed,
        multiLogos: multiLogos,
        persos: persoAddonsWithConvertedColors,
        colorLogoAddons: colorLogoAddons,
        persoColors: persoColorsConverted,
        boutiqueColors: boutiqueColors
      }
    ))
  }

  const [requestingPreview, setRequestingPreview] = useState(false);

  const handleRequestPreview = () => {

    // const persoColorsConverted = persoColors.map((color) => BoutiqueColorToHex(boutiqueColors, color));
    //
    // const persoAddonsWithConvertedColors = persoAddons.map((persoAddon) => (
    //   {
    //     ...persoAddon,
    //     colorList: persoAddon.colorList.map((color) => BoutiqueColorToHex(boutiqueColors, color))
    //   }));

    const infos = {
        boutiqueId: boutique.id,
        product: {
          id: product.id,
          productId: productId,
          version: version,
          colors: collectionProduct.colors.map((color, index) => (
            {
              colorName: color.colorImages.color,
              logoAddons: colorLogoAddons[index]
            } as RequestPreviewColor)
          ),
        }
      } as RequestPreviewData

    setRequestingPreview(true);
    requestProductPreview(infos);
  }

  interface previewImage {
    pagodeImageId:  number;
    boutiqueImageUrl: string;
  }
  async function requestProductPreview(infos: RequestPreviewData) {


    const response = await previewBoutiqueProduct(infos);

    setRequestingPreview(false);

    if (response.error) {
      console.log("Error requesting product preview ", response.error);
    } else {
      setColors(colors.map((color, index) => (
        {
          ...color,
          colorImages: {
            ...color.colorImages,
            images: color.colorImages.images.map((image, imageIndex) => {
              const boutiqueImageIndex = response.findIndex((boutiqueImage: previewImage) =>
                boutiqueImage['pagodeImageId'] === image.id);

              if (boutiqueImageIndex === -1) {
                return(image);
              } else {
                return ({
                  ...image,
                  boutiqueUrl: response[boutiqueImageIndex]['boutiqueImageUrl']
                });
              }
            })
          }
        }
      )))
    }
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabShown(newValue);
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    tabShown: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, tabShown, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={tabShown !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {tabShown === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  const addonsTotalPrice = () => {
    return(colorLogoAddons[0].reduce((total, addon) => {
      if (addon.logoIds[0] && addon.logoVersionIds[0] && !addon.optional) {
        const logo = productLogos?.find((logo) => logo.id === addon.logoIds[0]);
        if (logo) {
          const logoVersion = logo.versions.find((version) => version.id === addon.logoVersionIds[0]);
          if (logoVersion) {
            return total + logoVersion.priceCents;
          } else return total;
        } else return total;
      } else return total;
    }, 0));
  }

  const displayedTabIndex = tabShown > (colors.length - 1) ? (colors.length - 1) : tabShown

  // console.log("Rendering ProductConfig with " + colors.length + " colors with displayedTabIndex = " + displayedTabIndex + " and colorLogoAddons = " + JSON.stringify(colorLogoAddons));
  // console.log("Rendering ProductConfig with colors ", colors);

  const LogoAddonsUniqueList = (images: IProductImage[], img_index: number, colorIdx: number) => {

    // remove from the logoAddons of this image those who were listed in the previous images
    const imageLogoAddons = images[img_index].logoAddons
      .filter((addon) => !images.slice(0, img_index)
        .map((image) => image.logoAddons.map((addon) => addon.name)).flat()
        .includes(addon.name));

    return (<LogoAddonsList collectionProduct={collectionProduct}  imageAddons={imageLogoAddons}
                            colorLogoAddons={colorLogoAddons[colorIdx]} colorIndex={colorIdx}
                            firstColorName={firstColorName} excludedAddons={allExcludedAddons}
                            setAddonInfos={setAddonInfos} logos={productLogos} editMode={editMode}
                            multiLogos={multiLogos}
    />)

  }

  const PersoAddonsUniqueList= (images: IProductImage[], img_index: number, colorIdx: number) => {

    // remove from the logoAddons of this image those who were listed in the previous images
    const imagePersoAddons = images[img_index].persoAddons
      .filter((addon) => !images.slice(0, img_index)
        .map((image) => image.persoAddons.map((addon) => addon.name)).flat()
        .includes(addon.name));

    if (imagePersoAddons.length > 0) {
      return (<PersoAddonsList imagePersoAddons={imagePersoAddons} editMode={editMode}
                               persoAllowed={persoAllowed} setPersoAllowed={handlePersoAllowedChange}
                               persoColor={persoColors[colorIdx]} setPersoColor={handlePersoColorChange}
                               boutiqueColors={boutiqueColors} setBoutiqueColors={setBoutiqueColors}
                               persoExpanded={persoExpanded} setPersoExpanded={setPersoExpanded}
                               persoAddons={persoAddons}
                               colorIndex={colorIdx} firstColorName={firstColorName} nbProductColors={colors.length}
                               excludedAddons={allExcludedAddons} setPersoInfos={setPersoInfos} />)

    }
  }

  const key = `pcfg-${boutique.id}-${collectionProduct.collectionId}-${collectionProduct.product.id}-${collectionProduct.productId}-${collectionProduct.version}`;

  return(
    <Dialog key={key} fullWidth={true} maxWidth='xl' open={show} onClose={handleClose} className='product-config'>
      <DialogTitle>
        <span id='change-name' className={`product-title ${obsolete ? 'obsolete' : ''}`}>
          <InlineEdit text={customTitle || product.title} onSetText={setCustomTitle} editable={editMode}/>
          {obsolete && <span className='product-obsolete'> ({intl.formatMessage({id: 'product.discontinued'})})</span>}
        </span>
        {(version > 1) && <span className='product-version'> (v{version})</span>}
        <span className='product-price'>({ProductPricesString(product)})</span>
        <span className='product-sku' onClick={() => {
          handleClose();
          dispatch(openProduct(collectionProduct.product));
        }}>[{product.sku}]</span>
        <IconButton aria-label="close" onClick={handleClose} sx={{position: 'absolute', right: 8, top: 8, color: 'inherit'}}>
          <CloseIcon />
        </IconButton>
        <Tabs
          value={displayedTabIndex}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
        >

          {colors.map((color, i) => (
            <Tab key={i} label={
              <div className='color-tab-title'>
                <ColorTag key={`cpc-${color.colorImages.hexColor1}-${color.colorImages.hexColor2}`}
                          color1={color.colorImages.hexColor1} color2={color.colorImages.hexColor2}
                          active={false} size='big' />

                <div id={i === colors.length-1 ? `change-colours` : `chgclr${i}`}
                // set the color name to red if it is discontinued
                     className={`color-name ${discontinued.includes(color.colorImages.friendlyColor) ? 'discontinued' : ''}`}>
                  {color.colorImages.friendlyColor}{discontinued.includes(color.colorImages.friendlyColor) ?
                  ` (${intl.formatMessage({id: 'product.discontinued'})})` : ''}
                </div>
                  {editMode && colors.length > 1 && displayedTabIndex === i &&
                    <BoudaToolTip title={intl.formatMessage({id: 'product.remove-color'})} placement="bottom-end">
                      <CloseIcon aria-label="remove" onClick={() => {removeColor(i)}}
                                 sx={{position: 'absolute', right: -16, top: -12, color: 'inherit'}} />
                    </BoudaToolTip>
                  }
                  {editMode && i > 0 && displayedTabIndex === i &&
                    <BoudaToolTip title={intl.formatMessage({id: 'product.move-color'})} placement="bottom-end">
                      <KeyboardDoubleArrowLeftIcon aria-label="move left" onClick={() => {moveColorLeft(i)}}
                                                   sx={{position: 'absolute', right: 0, top: -12, color: 'inherit'}} />
                    </BoudaToolTip>
                  }
                </div>
            } />
          ))}
        </Tabs>
        <div className='product-total-price'>
          <Grid container spacing={2} className='price-container'>
            <Grid item xs={12} sm={4} className='color-img'></Grid>
            <Grid item xs={12} sm={8}>

            </Grid>
          </Grid>
        </div>
        {configActions()}
      </DialogTitle>
      <DialogContent>
        {colors.map((color, colorIdx) => (
          <TabPanel key={colorIdx} tabShown={displayedTabIndex} index={colorIdx}>
            <div className={`product-multi-logos ${multiLogos ? 'checked' : ''}`}>
              <IconButton onClick={() => openDocAtAnchor(locale, 'multi-logos')}>
                <HelpIcon className="help-icon" sx={{fontSize: 24}}/>
              </IconButton>
              <FormControlLabel label={<FormattedMessage id='product.multi-logos' />}
                                labelPlacement="start"
                                control={
                                  <Checkbox sx={{'&.MuiCheckbox-root': {color: '#808080'}}} size='small'
                                            disabled={colorIdx > 0 || !editMode}
                                            checked={multiLogos}
                                            onChange={() => setMultiLogos(!multiLogos)}/>
                                } />
            </div>
            {color.colorImages.images.map((image, img_index) =>
              <Grid container key={image.id} spacing={2} className={img_index == 0 ? '' : 'product-img'}>
                <Grid item xs={12} sm={4} className='color-img'>
                  <img src={image.boutiqueUrl || image.url} />

                  {image.boutiqueUrl && <div className='product-img-help'>
                    <IconButton onClick={() => openDocAtAnchor(locale, 'product-visuals')}>
                      <HelpIcon className="help-icon" sx={{fontSize: 32}}/>
                    </IconButton>
                  </div>}

                  {/*{img_index == 0 && configChanged() && boutique?.id && boutique?.id > 0 &&*/}
                  {/*  <div className='request-preview'>*/}
                  {/*    <Button variant="contained" color="primary" onClick={handleRequestPreview}>*/}
                  {/*      <FormattedMessage id="product.request-preview" />*/}
                  {/*      {requestingPreview && <CircularProgress size={15} color='info' sx={{ml:1, color: 'white'}} />}*/}
                  {/*    </Button>*/}
                  {/*  </div>*/}
                  {/*}*/}
                </Grid>
                <Grid item xs={12} sm={8}>
                  {LogoAddonsUniqueList(color.colorImages.images, img_index, colorIdx)}
                  {PersoAddonsUniqueList(color.colorImages.images, img_index, colorIdx)}
                </Grid>
              </Grid>
              )}
          </TabPanel>
        ))}
      </DialogContent>
      <DialogActions>
        {boutique.plan !== PLAN_STANDARD && <>
          <div>
            <FormattedMessage id="product.margin" />
          </div>
          <div className='product-boutique-margin'>
            <SelectProductMargin product={product} margin={margin} setMargin={setMargin} editMode={editMode} />
          </div>
        </>}
        <div className='product-total-price'>
          <FormattedMessage id="global.total-price" /> : {ProductPricesString(product, margin, addonsTotalPrice())}
        </div>
      </DialogActions>
    </Dialog>
  )
}
