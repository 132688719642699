import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {FormControl, FormHelperText} from "@mui/material";
import {productionStateSelector, selectStartDate} from "./productionSlice";
import dayjs, { Dayjs } from 'dayjs';
import {CustomDatepicker} from "../../utils/CustomDatePicker";

export function SelectStartDate() {

  const productionState = useSelector(productionStateSelector);
  const dispatch = useDispatch();

  const handleSelectStartDate = (date: Dayjs | null) => {
    const dateStr = date?.format("YYYY-MM-DD HH:mm:ss");
    // console.log("date: " + dateStr);
    dispatch(selectStartDate(dateStr))
  };

  const startDateTitle = productionState?.startDate ? `Depuis ${dayjs(productionState?.startDate).format("DD/MM/YYYY")}` : "Depuis ...";

  return (
    <FormControl sx={{ ml: 1, mr: 1, minWidth: 120 }} size="small" className='select-tool search-start-date'>
      <FormHelperText sx={{ml:0}}>{startDateTitle}</FormHelperText>
      <CustomDatepicker
        defaultValue={dayjs(productionState?.startDate || '2024-01-01')}
        onChange={handleSelectStartDate}
        disableFuture={true}
        minValue={dayjs('2024-01-01')}
      />
    </FormControl>
  )
}
