
export const getH1Headers = (markdownContent: string) => {
  // Use a regular expression to find all lines that start with '# ' (H1 headers in Markdown)
  const h1Headers = markdownContent
    .split('\n') // Split content into lines
    .filter(line => line.trim().startsWith('# ')) // Keep lines that start with '# '
    .map(line => line.replace(/^#\s*/, '').trim()); // Remove the '# ' and trim whitespace

  // Join the headers with a dash and return
  return h1Headers.join(' - ');
}