import React, { useState, useEffect, useMemo, useRef } from 'react';
import Markdown from 'markdown-to-jsx';
import { Box, Collapse } from '@mui/material';
import { useLocation } from "react-router-dom";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import WarningIcon from '@mui/icons-material/Warning';
import ReportIcon from '@mui/icons-material/Report';
import './MarkdownDocumentation.scss';
import { IntlShape } from "react-intl";

type TocItem = {
  id: string;
  text: string;
  level: number;
  children?: TocItem[];
};

type MarkdownDocumentationProps = {
  intl: IntlShape;
  markdownContent: string;
  allowPrivateContent: boolean;
};

type IconDefinition = {
  component: React.ElementType;
  color: string;
};

const ICONS: Record<'TipsAndUpdates' | 'PrivacyTip' | 'Warning' | 'Report', IconDefinition> = {
  TipsAndUpdates: { component: TipsAndUpdatesIcon, color: 'gold' },
  PrivacyTip: { component: PrivacyTipIcon, color: 'blue' },
  Warning: { component: WarningIcon, color: 'orange' },
  Report: { component: ReportIcon, color: '#4269af' },
};

const MarkdownDocumentation: React.FC<MarkdownDocumentationProps> = ({ intl, markdownContent, allowPrivateContent }) => {
  const location = useLocation();

  // ---------------------------
  // (1) Process Markdown & Build TOC
  // ---------------------------
  const processMarkdownContent = (markdown: string): { content: string; toc: TocItem[] } => {
    const unescapedMarkdown = markdown.replace(/\\</g, '<').replace(/\\>/g, '>');
    let headingIndex = 0;
    const toc: TocItem[] = [];
    const stack: { item: TocItem; level: number }[] = [];
    const privateTagRegex = /begin-private([\s\S]*?)end-private/gs;
    const iconTagRegex = /<<(\w+)>>/g;
    const anchorTagRegex = /<<anchor\s+([^>]+)>>/g;

    const processedMarkdown = unescapedMarkdown
      .replace(privateTagRegex, (_, content) => {
        return allowPrivateContent
          ? content.trim()
          : `<div class="private-placeholder">${intl.formatMessage({ id: 'help-page-private-content' })}</div>`;
      })
      .replace(iconTagRegex, (_, iconName) => {
        return iconName in ICONS ? `{{icon-${iconName}}}` : `<span class="invalid-icon">Invalid Icon: ${iconName}</span>`;
      });

    const lines = processedMarkdown.split('\n');
    const processedLines: string[] = [];

    for (let line of lines) {
      const anchorMatches = Array.from(line.matchAll(anchorTagRegex));
      const cleanedLine = line.replace(anchorTagRegex, '');
      for (const match of anchorMatches) {
        const anchorId = match[1].trim();
        processedLines.push(`<a id="${anchorId}"></a>`);
      }
      const headingMatch = /^(#{1,4})\s(.+)$/.exec(cleanedLine);
      if (headingMatch) {
        const level = headingMatch[1].length;
        const text = headingMatch[2].trim();
        const id = `heading-${++headingIndex}`;
        const tocItem: TocItem = { id, text, level };
        while (stack.length > 0 && stack[stack.length - 1].level >= level) {
          stack.pop();
        }
        if (stack.length === 0) {
          toc.push(tocItem);
        } else {
          const parent = stack[stack.length - 1].item;
          parent.children = parent.children || [];
          parent.children.push(tocItem);
        }
        stack.push({ item: tocItem, level });
        processedLines.push(`<h${level} id="${id}">${text}</h${level}>`);
      } else {
        processedLines.push(cleanedLine);
      }
    }
    return { content: processedLines.join('\n'), toc };
  };

  const { content, toc } = processMarkdownContent(markdownContent);
  const [processedContent] = useState<string>(content);
  const [processedToc] = useState<TocItem[]>(toc);

  // ---------------------------
  // (2) TOC Expansion state (for nested TOC items)
  // ---------------------------
  const initialExpandedLevels: { [key: string]: boolean } = {};
  processedToc.forEach((item) => {
    if (item.level === 1) {
      initialExpandedLevels[item.id] = true;
    }
  });
  const [expandedLevels, setExpandedLevels] = useState<{ [key: string]: boolean }>(initialExpandedLevels);

  const toggleExpand = (id: string) => {
    setExpandedLevels((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  // ---------------------------
  // (3) Scroll-to-content when clicking a TOC item
  // ---------------------------
  const handleScrollToContent = (id: string) => {
    const target = document.getElementById(id);
    if (target) {
      target.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  // ---------------------------
  // (4) Use IntersectionObserver to track which heading is visible.
  // This sets "activeHeading" to the id of the heading currently in view.
  // ---------------------------
  const [activeHeading, setActiveHeading] = useState<string>('');
  useEffect(() => {
    const headingElements = document.querySelectorAll('h1, h2, h3, h4');
    if (!headingElements.length) return;

    const observerOptions = {
      root: null,
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      const visibleEntries = entries.filter(entry => entry.isIntersecting);
      if (visibleEntries.length > 0) {
        visibleEntries.sort((a, b) => a.boundingClientRect.top - b.boundingClientRect.top);
        setActiveHeading(visibleEntries[0].target.id);
      }
    }, observerOptions);

    headingElements.forEach((heading) => observer.observe(heading));
    return () => {
      headingElements.forEach((heading) => observer.unobserve(heading));
      observer.disconnect();
    };
  }, [processedContent]);

  // ---------------------------
  // (5) Helper functions to get the “path” in the TOC for a given heading.
  // Then determine which toc item is actually visible based on expansion.
  // ---------------------------
  const findTocPath = (items: TocItem[], targetId: string): string[] | null => {
    for (const item of items) {
      if (item.id === targetId) {
        return [item.id];
      }
      if (item.children) {
        const childPath = findTocPath(item.children, targetId);
        if (childPath) {
          return [item.id, ...childPath];
        }
      }
    }
    return null;
  };

  const getVisibleTocId = (path: string[], expanded: { [key: string]: boolean }): string => {
    let visibleId = path[0];
    for (let i = 1; i < path.length; i++) {
      const parentId = path[i - 1];
      if (expanded[parentId]) {
        visibleId = path[i];
      } else {
        break;
      }
    }
    return visibleId;
  };

  // Compute the visible active TOC id.
  const visibleTocId = useMemo(() => {
    if (!activeHeading) return '';
    const path = findTocPath(processedToc, activeHeading);
    return path ? getVisibleTocId(path, expandedLevels) : activeHeading;
  }, [activeHeading, processedToc, expandedLevels]);

  // ---------------------------
  // (6) Render the TOC items.
  // We remove inline active styling here and instead add a data attribute.
  // ---------------------------
  const renderTocItems = (items: TocItem[]): JSX.Element[] => {
    return items.map((item) => (
      <div key={item.id} className={`toc-item toc-level-${item.level}`}>
        <div className="toc-header">
          {item.children ? (
            <div className="toc-icon" onClick={() => toggleExpand(item.id)}>
              {expandedLevels[item.id] ? '-' : '+'}
            </div>
          ) : (
            <div className="toc-icon">{item.level === 4 && '•'}</div>
          )}
          <span
            className="toc-link"
            data-toc-id={item.id}
            onClick={() => handleScrollToContent(item.id)}
          >
            {item.text}
          </span>
        </div>
        {item.children && (
          <Collapse in={!!expandedLevels[item.id]} timeout="auto">
            {renderTocItems(item.children)}
          </Collapse>
        )}
      </div>
    ));
  };

  // ---------------------------
  // (7) Imperatively update the active TOC item styling without causing a full re-render.
  // ---------------------------
  const tocContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (tocContainerRef.current) {
      // Schedule the DOM update for the next frame.
      requestAnimationFrame(() => {
        const container = tocContainerRef.current;
        // Remove the active class from all toc links.
        const links = container?.querySelectorAll('.toc-link');
        links?.forEach((link) => link.classList.remove('active'));
        // Add the active class to the link with the matching data attribute.
        const activeLink = container?.querySelector(
          `.toc-link[data-toc-id="${visibleTocId}"]`
        );
        if (activeLink) {
          activeLink.classList.add('active');
        }
      });
    }
  }, [visibleTocId]);

  // ---------------------------
  // (8) Optional: Scroll to URL anchor on mount.
  // ---------------------------
  useEffect(() => {
    if (location.hash) {
      const anchorId = location.hash.substring(1);
      const startTime = Date.now();
      const intervalId = setInterval(() => {
        const element = document.getElementById(anchorId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          clearInterval(intervalId);
        } else if (Date.now() - startTime > 15000) {
          clearInterval(intervalId);
        }
      }, 250);
    }
  }, [location.hash]);

  return (
    <Box className="markdown-documentation">
      <Box className="toc" component="nav" ref={tocContainerRef}>
        {renderTocItems(processedToc)}
      </Box>
      <Box className="content">
        <Markdown
          options={{
            overrides: {
              p: {
                component: ({ children }: { children: React.ReactNode }) => {
                  const content = React.Children.map(children, (child) => {
                    if (typeof child === 'string') {
                      const parts = child.split(/({{icon-\w+}})/g);
                      return parts.map((part, index) => {
                        const match = /{{icon-(\w+)}}/.exec(part);
                        if (match) {
                          const iconName = match[1];
                          const icon = ICONS[iconName as keyof typeof ICONS];
                          if (icon) {
                            const IconComponent = icon.component;
                            return (
                              <span className="icon" key={`${iconName}-${index}`} style={{ color: icon.color }}>
                                <IconComponent />
                              </span>
                            );
                          }
                        }
                        return part;
                      });
                    }
                    return child;
                  });
                  return <p>{content}</p>;
                },
              },
            },
          }}
        >
          {processedContent}
        </Markdown>
      </Box>
    </Box>
  );
};

export default MarkdownDocumentation;
