import React from "react";

const TankTopSvg = (height: string) => {

  return (
    <svg version="1.1" viewBox="0 0 64 64" style={{'height': height}}>
      <g className="icon">
        <path d="M16,17.7c0.1,3.4,0,7.2-0.5,11.5v28.4c0,1.4,1.1,2.5,2.5,2.5h28.1c1.4,0,2.5-1.1,2.5-2.5V30c-0.5-4.5-0.7-8.7-0.6-12.3
		c0.2-4,0.7-7.4,1.7-10.1C50,7,50.2,6.5,50.4,6l-1.9-0.8L45.6,4l0,0c-0.3-0.1-0.7-0.2-1-0.2l0,0h-3.1l-5.1,8.9
		c-0.9,1.6-2.5,2.5-4.3,2.5s-3.4-0.9-4.3-2.5l-5.1-8.9h-3.1l0,0c-0.1,0-0.3,0-0.4,0.1c-0.2,0-0.4,0.1-0.6,0.2l-2.9,1.2L13.6,6
		c0.2,0.5,0.4,1,0.6,1.6C15.2,10.3,15.8,13.7,16,17.7L16,17.7z"/>
      </g>
    </svg>
  );
}


export default TankTopSvg;

