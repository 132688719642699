import React, { PropsWithChildren, useState } from 'react';
import { BoudaToolTip } from "./BoudaToolTip";
import IconButton from "@mui/material/IconButton";
import { Popover } from "@mui/material";
import InlineEdit from "./InlineEdit";
import Box from "@mui/material/Box";
import { SxProps, Theme } from "@mui/system";

export type PopoverEditProps = {
  text: string | null;
  onSetText: Function;
  title: string;
  editable?: boolean;
  sx?: SxProps<Theme>; // Add the sx prop
};

const PopoverEdit = (props: PropsWithChildren<PopoverEditProps>) => {
  const { children, text, onSetText, title, sx } = props;
  const editable = props.editable || props.editable === undefined;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <BoudaToolTip placement="top" title={title} arrow disableInteractive={true} sx={{ textAlign: 'center' }}>
        <IconButton onClick={handleClick} sx={sx}>
          {children}
        </IconButton>
      </BoudaToolTip>
      <Popover
        key={`popover-${title}`}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ padding: '10px 15px', border: '1px solid #C0C0C0', borderRadius: '5px' }}>
          <InlineEdit text={text || title} onSetText={onSetText} color={'blue'} editable={editable} />
        </Box>
      </Popover>
    </>
  );
};

export default PopoverEdit;
