import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  cancelEmailChangeRequest,
  confirmPasswordToken,
  confirmUserEmail, createUser,
  getCurrentUser,
  loginWithEmailAndPassword, loginWithToken, logoutUserOnServer,
  removeSessionTokens,
  requestMagicLink, requestNewConfirmation, requestPasswordLink, requestRemoveHelpTip, storeSessionTokens,
  updateUserProfile
} from "../../app/sessionAPI";
import {RootState, AppThunk} from "../../app/store";
import {ISelectedProductPayload} from "../selection/selectionSlice";
import {IRequestedDemoPayload} from "../dashboard/DemoBoutique";

export const ROLE_BOUDIST = 0
export const ROLE_DEMO = 3
export const ROLE_BOUTIQUE = 5

export const ROLE_ADMIN = 10

export interface IUser {
  id?: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  role?: number;
  production?: boolean;
  superAdmin?: boolean;
  locale?: string;
  color1?: string;
  color2?: string;
  productLimit?: number | null;
  hasPassword?: boolean;
  view_orders?: boolean;
  update_orders?: boolean;
  view_deliveries?: boolean;
  update_deliveries?: boolean;
  view_markings?: boolean;
  update_markings?: boolean;
  view_transfers?: boolean;
  view_embroidery?: boolean;
  view_stiching?: boolean;
  view_stocks?: boolean,
  update_stocks?: boolean,
  view_exports?: boolean;
  createdAt?: string;
  confirmedAt?: string;
  unconfirmed_email?: string;
  updatedAt?: string;
  helpItems: IHelpItem[];
}

export interface IHelpItem {
  topic: string;
  component: string;
  anchor: string;
  placement: string;
  rough: string;
  messageKey: string;
}

export interface UserLoginData {
  email: string;
  password: string;
}

export interface IProfileUpdate {
  firstName?: string;
  lastName?: string;
  email?: string;
  locale?: string;
  color1?: string;
  color2?: string;
  password?: string;
  needPassword?: boolean;
  currentPassword?: string;
}

export interface UserConfirmEmailData {
  token: string;
}

interface AuthState {
  currentUser?: IUser;
  loading: boolean;
  waiting: boolean;
  confirming: boolean;
  error: boolean;
  errorMessages: string[];
  successMessage: string;
  expiresIn?: number;
  expiresAt?: number;
  tokenType?: string;
  currentRoute?: string;
  showingSpeedySignup: boolean;
  signUpPayload?: ISelectedProductPayload;
  serverOperation: boolean;
  definingPassword: boolean;
}

const initialState: AuthState = {
  loading: false,
  waiting: false,
  confirming: false,
  error: false,
  errorMessages: [],
  successMessage: "",
  expiresIn: undefined,
  tokenType: undefined,
  showingSpeedySignup: false,
  serverOperation: false,
  definingPassword: false,
}

// check if user email address ends with @dagoba.us
export const impersonatingUser = (user: IUser | undefined) => user &&
      (localStorage.getItem("iprsnt") === '1' || user?.email?.endsWith("@dagoba.us"));

export const flagImpersonating = localStorage.getItem("iprsnt") === '1';

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export interface ISignupPayload {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  locale: string;
  color1: string;
  color2: string;
  selection?: ISelectedProductPayload;
  boutique?: IRequestedDemoPayload;
}


////////////////////////////////////////////////////////////////////////////////////////////////////
// Signup
////////////////////////////////////////////////////////////////////////////////////////////////////
export const signUpUser = createAsyncThunk(
  "session/signUpUser",
  async (payload: ISignupPayload, thunkAPI) => {
    const response = await createUser(payload);
    if (response.errors) {
      // The value we return becomes the `rejected` action payload
      return thunkAPI.rejectWithValue(response);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

////////////////////////////////////////////////////////////////////////////////////////////////////
// Login by email & password
////////////////////////////////////////////////////////////////////////////////////////////////////
export const loginUserByEmail = createAsyncThunk(
  "session/loginUserByEmail",
  async (payload: UserLoginData, {rejectWithValue}) => {
    const loginResponse = await loginWithEmailAndPassword(
      payload.email,
      payload.password
    );
    if (loginResponse.access.error) {
      // The value we return becomes the `rejected` action payload
      return rejectWithValue(loginResponse.access);
    }

    // console.log("loginUserByEmail with loginResponse = " + JSON.stringify(loginResponse));
    storeSessionTokens(loginResponse.access.access_token, loginResponse.access.refresh_token);
    if (loginResponse.iprsnt) {
      localStorage.setItem("iprsnt", '1')
    } else {
      localStorage.removeItem("iprsnt")
    }

    const userResponse = await getCurrentUser();
    if (userResponse.error) {
      // The value we return becomes the `rejected` action payload
      return rejectWithValue(userResponse.data);
    }
    const response = {
      ...loginResponse.access,
      ...userResponse,
    };
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

////////////////////////////////////////////////////////////////////////////////////////////////////
// Reload user
////////////////////////////////////////////////////////////////////////////////////////////////////

export const reloadUser = createAsyncThunk(
  "session/reloadUser",
  async (_, {rejectWithValue}) => {
    const response = await getCurrentUser();
    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return rejectWithValue(response.data);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

////////////////////////////////////////////////////////////////////////////////////////////////////
// Update profile
////////////////////////////////////////////////////////////////////////////////////////////////////

export const updateProfile = createAsyncThunk(
  "session/updateProfile",
  async (profileUpdate: IProfileUpdate, {rejectWithValue}) => {
    const response = await updateUserProfile(profileUpdate);

    if (response.errors) {
      // The value we return becomes the `rejected` action payload
      return rejectWithValue(response);
    }
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

////////////////////////////////////////////////////////////////////////////////////////////////////
// Magic link
////////////////////////////////////////////////////////////////////////////////////////////////////

export const sendMagicLink = createAsyncThunk(
  "session/sendMagicLink",
  async (payload: {email: string, locale: string}, {rejectWithValue}) => {
    const response = await requestMagicLink(payload.email, payload.locale);
    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return rejectWithValue(response);
    }
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const loginUserByToken = createAsyncThunk(
  "session/loginUserByToken",
  async (token: string, {rejectWithValue}) => {
    const loginResponse = await loginWithToken( token);
    if (loginResponse.access.error) {
      // The value we return becomes the `rejected` action payload
      return rejectWithValue(loginResponse.access);
    }

    // console.log("loginUserByToken with loginResponse = " + JSON.stringify(loginResponse));
    storeSessionTokens(loginResponse.access.access_token, loginResponse.access.refresh_token);

    const userResponse = await getCurrentUser();
    if (userResponse.error) {
      // The value we return becomes the `rejected` action payload
      return rejectWithValue(userResponse.data);
    }
    const response = {
      ...loginResponse.access,
      ...userResponse,
    };
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

////////////////////////////////////////////////////////////////////////////////////////////////////
// Confirmations
////////////////////////////////////////////////////////////////////////////////////////////////////

export const newConfirmEmailLink = createAsyncThunk(
  "session/newConfirmEmailLink",
  async (_, thunkAPI) => {
    const response = await requestNewConfirmation();

    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return thunkAPI.rejectWithValue(response);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);


export const confirmEmail = createAsyncThunk(
  "session/confirmEmail",
  async (payload: {token: string, demo: boolean}, thunkAPI) => {
    const response = await confirmUserEmail(payload.token, payload.demo);

    if (response.errors) {
      // The value we return becomes the `rejected` action payload
      return thunkAPI.rejectWithValue(response);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const cancelEmailChange = createAsyncThunk(
  "session/cancelEmailChange",
  async (_, thunkAPI) => {
    const response = await cancelEmailChangeRequest();

    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return thunkAPI.rejectWithValue(response);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

////////////////////////////////////////////////////////////////////////////////////////////////////
// Password reset
////////////////////////////////////////////////////////////////////////////////////////////////////

export const sendPasswordLink = createAsyncThunk(
  "session/sendPasswordLink",
  async (payload: {email: string, locale: string | undefined}, {rejectWithValue}) => {
    const response = await requestPasswordLink(payload.email, payload.locale);
    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return rejectWithValue(response);
    }
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const confirmPassword = createAsyncThunk(
  "session/confirmPassword",
  async (token: string, thunkAPI) => {
    const response = await confirmPasswordToken(token);

    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return thunkAPI.rejectWithValue(response);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

////////////////////////////////////////////////////////////////////////////////////////////////////
// Logout
////////////////////////////////////////////////////////////////////////////////////////////////////

export const logoutUser = createAsyncThunk(
  "session/logoutUser",
  async (_, {rejectWithValue}) => {
    const response = await logoutUserOnServer();
    // if response has errors rejectwithvalue
    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return rejectWithValue(response);
    }
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

////////////////////////////////////////////////////////////////////////////////////////////////////
// removeHelpTip
////////////////////////////////////////////////////////////////////////////////////////////////////

export const removeHelpTip = createAsyncThunk(
  "session/removeHelpTip",
  async (payload: {component: string, anchor: string}, {rejectWithValue}) => {
    const response = await requestRemoveHelpTip(payload.component, payload.anchor);
    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return rejectWithValue(response);
    }
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);


const setSpeedySignupClosed = (state: AuthState) => {
  state.showingSpeedySignup = false;
  state.error = false;
  state.waiting = false;
  state.errorMessages = [];
}

const setCurrentUser = (state: AuthState, payload: any) => {
  // console.log("setCurrentUser with payload = ", JSON.stringify(payload));
  state.currentUser = {
    id: payload.id,
    firstName: payload.first_name,
    lastName: payload.last_name,
    email: payload.email,
    role: payload.role,
    production: payload.production,
    superAdmin: payload.superAdmin,
    locale: payload.locale,
    color1: payload.color1,
    color2: payload.color2,
    productLimit: payload.product_limit,
    hasPassword: payload.has_password,
    view_orders: payload.view_orders,
    update_orders: payload.update_orders,
    view_deliveries: payload.view_deliveries,
    update_deliveries: payload.update_deliveries,
    view_markings: payload.view_markings,
    update_markings: payload.update_markings,
    view_transfers: payload.view_transfers,
    view_embroidery: payload.view_embroidery,
    view_stiching: payload.view_stiching,
    view_stocks: payload.view_stocks,
    update_stocks: payload.update_stocks,
    view_exports: payload.view_exports,
    createdAt: payload.created_at,
    confirmedAt: payload.confirmed_at,
    unconfirmed_email: payload.unconfirmed_email,
    updatedAt: payload.updated_at,
    helpItems: payload.help_items,
    // iprsnt: payload.iprsnt,
  } as IUser;
}

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    openSpeedySignup: (state, action ) => {
      state.signUpPayload = action.payload;
      state.showingSpeedySignup = true;
      state.error = false;
      state.waiting = false;
      state.errorMessages = [];
    },
    closeSpeedySignup: (state) => {
      setSpeedySignupClosed(state);
    },
    resetErrorState: (state) => {
      state.error = false;
      state.errorMessages = [];
    },
    updateUnconfirmedEmail: (state, action) => {
      if (state.currentUser) {
        state.currentUser.unconfirmed_email = action.payload;
      }
    },
    closeHelpTip: (state, action) => {
      if (state.currentUser) {
        // remove the help item from the list with the same component and anchor
        state.currentUser.helpItems = state.currentUser.helpItems.filter((hi) =>
          !(hi.component === action.payload.component && hi.anchor === action.payload.anchor)
        );
      }
    },
    startServerOperation: (state) => {
      state.serverOperation = true;
    },
    stopServerOperation: (state) => {
      state.serverOperation = false;
    },
    setDefiningPassword: (state, action) => {
      state.definingPassword = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
////////////////////////////////////////////////////////////////////////////////////////////////////
// Signup
////////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(signUpUser.pending, (state) => {
        state.waiting = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(signUpUser.fulfilled, (state, action: any) => {
        // console.log("signUpUser.fulfilled with payload = ", action.payload);
        setSpeedySignupClosed(state);
        state.tokenType = action.payload.boudist.token_type;
        setCurrentUser(state, action.payload.boudist);
        storeSessionTokens(action.payload.boudist.access_token, action.payload.boudist.refresh_token);
      })
      .addCase(signUpUser.rejected, (state, action: any) => {
        // console.log("signUpUser.rejected with payload = ", action.payload);
        state.waiting = false;
        state.error = true;
        state.errorMessages = action.payload.errors;
      })
////////////////////////////////////////////////////////////////////////////////////////////////////
// Login by email & password
////////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(loginUserByEmail.pending, (state) => {
        state.waiting = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(loginUserByEmail.fulfilled, (state, action: any) => {
        // console.log("loginUserByEmail.fulfilled with payload = ", JSON.stringify(action.payload));
        state.expiresIn = action.payload.expires_in;
        setCurrentUser(state, action.payload)
        state.waiting = false;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(loginUserByEmail.rejected, (state, action: any) => {
        state.waiting = false;
        state.error = true;
        state.errorMessages = ["invalid-credentials"];
      })
////////////////////////////////////////////////////////////////////////////////////////////////////
// Reload user
////////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(reloadUser.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(reloadUser.fulfilled, (state, action: any) => {
        setCurrentUser(state, action.payload)

        state.loading = false;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(reloadUser.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorMessages = ["bad-token"];
        removeSessionTokens();
      })
////////////////////////////////////////////////////////////////////////////////////////////////////
// Update profile
////////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(updateProfile.pending, (state) => {
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(updateProfile.fulfilled, (state, action: any) => {
        state.tokenType = action.payload.token_type;
        setCurrentUser(state, action.payload)
        storeSessionTokens(action.payload.access_token, action.payload.refresh_token);
        state.error = false;
        state.definingPassword = false;
        state.confirming = false;
        state.errorMessages = [];
        // console.log("updateProfile.fulfilled, definingPassword = ", state.definingPassword);
      })
      .addCase(updateProfile.rejected, (state, action: any) => {
        state.error = true;
        state.errorMessages = action.payload.errors;
      })
////////////////////////////////////////////////////////////////////////////////////////////////////
// Magic link
////////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(sendMagicLink.pending, (state) => {
        state.waiting = true;
        state.error = false;
        state.errorMessages = [];
        state.successMessage = "";
      })
      .addCase(sendMagicLink.fulfilled, (state, action: any) => {
        state.waiting = false;
        state.error = false;
        state.errorMessages = [];
        state.successMessage = "A connection link has been sent to you. Please check your inbox"
      })
      .addCase(sendMagicLink.rejected, (state, action: any) => {
        state.waiting = false;
        state.error = true;
        state.errorMessages = ["email-not-found"];
        state.successMessage = "";
      })
      .addCase(loginUserByToken.pending, (state) => {
        state.waiting = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(loginUserByToken.fulfilled, (state, action: any) => {
        // console.log("loginUserByToken.fulfilled with payload = ", JSON.stringify(action.payload));
        state.expiresIn = action.payload.expires_in;
        setCurrentUser(state, action.payload)
        // storeSessionTokens(action.payload.access_token, action.payload.refresh_token);

        state.waiting = false;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(loginUserByToken.rejected, (state, action: any) => {
        state.waiting = false;
        state.error = true;
        state.errorMessages = ["invalid-token"];
      })

////////////////////////////////////////////////////////////////////////////////////////////////////
// Confirmations
////////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(newConfirmEmailLink.pending, (state) => {
        state.waiting = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(newConfirmEmailLink.fulfilled, (state, action: any) => {
        state.waiting = false;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(newConfirmEmailLink.rejected, (state, action: any) => {
        state.waiting = false;
        state.error = true;
        state.errorMessages = action.payload.error;
      })

      .addCase(confirmEmail.pending, (state) => {
        state.confirming = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(confirmEmail.fulfilled, (state, action: any) => {
        // console.log("confirmEmail.fulfilled with payload = ", JSON.stringify(action.payload));
        state.confirming = false;
        setCurrentUser(state, action.payload.boudist);
        storeSessionTokens(action.payload.boudist.access_token, action.payload.boudist.refresh_token);
      })
      .addCase(confirmEmail.rejected, (state, action: any) => {
        // console.log("confirmEmail.rejected with payload = ", JSON.stringify(action.payload));
        state.confirming = false;
        state.error = true;
        state.errorMessages = action.payload.errors || [];
      })

      .addCase(cancelEmailChange.pending, (state) => {
        state.confirming = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(cancelEmailChange.fulfilled, (state, action: any) => {
        state.confirming = false;
        setCurrentUser(state, action.payload)
        storeSessionTokens(action.payload.access_token, action.payload.refresh_token);
      })
      .addCase(cancelEmailChange.rejected, (state, action: any) => {
        state.confirming = false;
        state.error = true;
        state.errorMessages = action.payload.errors;
      })

////////////////////////////////////////////////////////////////////////////////////////////////////
// Password reset
////////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(sendPasswordLink.pending, (state) => {
        state.waiting = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(sendPasswordLink.fulfilled, (state, action: any) => {
        state.waiting = false;
        state.error = false;
      })
      .addCase(sendPasswordLink.rejected, (state, action: any) => {
        state.waiting = false;
        state.error = true;
        state.errorMessages = ["email-not-found"];
      })
      .addCase(confirmPassword.pending, (state) => {
        state.confirming = true;
        state.definingPassword = false;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(confirmPassword.fulfilled, (state, action: any) => {
        state.confirming = false;
        state.definingPassword = true;
        setCurrentUser(state, action.payload)
        storeSessionTokens(action.payload.access_token, action.payload.refresh_token);
        console.log("confirmPassword.fulfilled, definingPassword = ", state.definingPassword);
      })
      .addCase(confirmPassword.rejected, (state, action: any) => {
        state.confirming = false;
        state.definingPassword = false;
        state.error = true;
        state.errorMessages = [action.payload.error];
      })

////////////////////////////////////////////////////////////////////////////////////////////////////
// Logout
////////////////////////////////////////////////////////////////////////////////////////////////////
      .addCase(logoutUser.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(logoutUser.fulfilled, (state, action: any) => {
        state.currentUser = {
          id: undefined,
          email: undefined,
          role: undefined,
          production: undefined,
          superAdmin: undefined,
          hasPassword: undefined,
          view_orders: undefined,
          update_orders: undefined,
          view_deliveries: undefined,
          update_deliveries: undefined,
          view_markings: undefined,
          update_markings: undefined,
          view_transfers: undefined,
          view_embroidery: undefined,
          view_stiching: undefined,
          view_stocks: undefined,
          update_stocks: undefined,
          view_exports: undefined,
          createdAt: undefined,
          confirmedAt: undefined,
          updatedAt: undefined,
          helpItems: []
        };
        state.tokenType = undefined;
        removeSessionTokens();

        state.loading = false;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(logoutUser.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorMessages = [action.payload.error];
      })

////////////////////////////////////////////////////////////////////////////////////////////////////
// removeHelpTip
////////////////////////////////////////////////////////////////////////////////////////////////////
//       .addCase(removeHelpTip.pending, (state) => {
//         state.waiting = true;
//       })
//       .addCase(removeHelpTip.fulfilled, (state, action: any) => {
//         state.waiting = false;
//       })
//       .addCase(removeHelpTip.rejected, (state, action: any) => {
//         // console.log("signUpUser.rejected with payload = ", action.payload);
//         state.waiting = false;
//       })
    ;
  },
});

export const {
  resetErrorState,
  openSpeedySignup,
  closeSpeedySignup,
  updateUnconfirmedEmail,
  closeHelpTip,
  startServerOperation,
  stopServerOperation,
  setDefiningPassword
} = sessionSlice.actions;

export const speedySignupShowing = (state: RootState) => state.session.showingSpeedySignup;
export const signUpPayload = (state: RootState) => state.session.signUpPayload;

export default sessionSlice.reducer;
