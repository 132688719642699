import React from "react";

const HoodieSvg = (height: string) => {

  return (
    <svg version="1.1" viewBox="0 0 64 64" style={{'height': height}}>
      <g className="icon">
        <path d="M20.9,7.1c0.5-0.2,0.9-0.1,1.3,0.2c1.3,1.2,4.5,3.7,9.2,4L26,4.6c-0.5-0.6-1.1-1.1-1.9-1.5
					C24,3.1,23.9,3.1,23.7,3c-1.7-0.9-3.8-0.5-5,1c0,0.1-0.1,0.1-0.1,0.2C17.1,6,17.7,8,17.7,8.3C18.7,7.9,19.9,7.6,20.9,7.1
					L20.9,7.1z"/>
        <path d="M36.8,4.8c-1.7,0.5-3.3,0.7-5,0.7c-1.4,0-2.6-0.2-3.9-0.6l4.5,5.5L36.8,4.8L36.8,4.8z"/>
        <path d="M42.2,7.3c0.4-0.4,0.9-0.5,1.5-0.3c1.4,0.5,2.7,1,4.1,1.5c0-0.3,0.5-2.6-1.3-4.5l-0.1-0.1
					c-1.5-1.5-3.8-1.7-5.6-0.8c-0.3,0.2-0.6,0.3-1,0.5c0,0,0,0,0,0.1l-6.2,7.8C38,10.9,40.9,8.5,42.2,7.3L42.2,7.3z"/>
        <path d="M20.9,52.4c0.8-0.3,2.6-1.2,3.7-3.5c0.9-1.8,0.7-3.7,0.6-4.6c-0.1-0.5,0.2-0.9,0.7-1c0.5-0.1,0.9,0.2,1,0.7
					c0.2,1.1,0.4,3.3-0.7,5.6c-1.4,2.8-3.7,4-4.7,4.4c-0.1,0-0.2,0-0.2,0.1c-0.4,0-0.7-0.2-0.9-0.6C20.2,53,20.5,52.5,20.9,52.4
					L20.9,52.4z M39.3,43.8c0.1-0.5,0.6-0.7,1-0.6c0.5,0.1,0.7,0.6,0.6,1c-0.2,0.9-0.6,2.6,0.2,4.6c0.9,2.3,2.7,3.4,3.4,3.8
					c0.4,0.2,0.6,0.7,0.4,1.1c-0.1,0.3-0.4,0.5-0.7,0.5c-0.1,0-0.3,0-0.5-0.1c-0.9-0.5-3.2-1.7-4.3-4.7C38.6,47,39,44.9,39.3,43.8
					L39.3,43.8z M8.3,58.1v1.7c0,0.4,0.3,0.8,0.8,0.8h4.1c0.4,0,0.8-0.3,0.8-0.8v-1.6c0.5,0,0.8-0.4,0.9-0.9l2.4-20.8l1.3,21.7h28.7
					l0.5-22.4l2.9,21.3c0,0.3,0.3,0.6,0.6,0.8v1.5c0,0.4,0.3,0.8,0.8,0.8h4.1c0.4,0,0.8-0.3,0.8-0.8v-1.6c0.2-0.1,0.3-0.3,0.4-0.6
					c1.4-7.1,2.3-17.9-0.7-30.4c-1.3-5.3-3-10-4.8-13.8c-0.4-0.9-1.1-1.6-2-1.8c-2.3-0.8-4.5-1.6-6.8-2.4c-0.1,0-0.1,0-0.2,0
					c-0.6,0.7-2.8,2.8-6.5,4v19.1c0.3,0.3,0.6,0.7,0.6,1.1c0,0.8-0.7,1.5-1.6,1.5S34,33.7,34,33c0-0.5,0.2-0.9,0.6-1.1V13.2
					c-0.8,0.1-1.5,0.2-2.4,0.1c-0.4,0-0.8,0-1.1-0.1v19.4c0.3,0.3,0.6,0.7,0.6,1.1c0,0.8-0.7,1.5-1.6,1.5s-1.6-0.7-1.6-1.5
					c0-0.5,0.2-0.9,0.6-1.1V13.2c0-0.1,0-0.2,0-0.3c-3.9-1-6.4-3.3-7.1-4c-0.1-0.1-0.1-0.1-0.2,0l-6.9,2.7c-0.9,0.4-1.6,1.1-1.9,1.9
					c-2.4,5.7-4.9,13.6-6,23.2c-0.9,8.1-0.5,15.1,0.3,20.7C7.6,57.7,7.9,58,8.3,58.1L8.3,58.1z"/>
      </g>
    </svg>
  );
}


export default HoodieSvg;

