import { Navigate, useLocation } from "react-router-dom";
import { useContext, useEffect, useMemo, useState } from "react";
import { I18nContext, SUPPORTED_LOCALES } from "./LocaleWrapper";

// Define paths for which locale enforcement should be skipped
const LOCALE_EXCLUDED_PATHS = ["admin", "review"];

const EnsureLocale: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const { locale, selectLanguage } = useContext(I18nContext);
  const [isLocaleReady, setIsLocaleReady] = useState(false);

  // Memoize path analysis to prevent unnecessary recalculations
  const { firstSegment, shouldSkipEnforcement, hasValidLocale } = useMemo(() => {
    const segments = location.pathname.split("/").filter(Boolean);
    const first = segments[0] || "";
    return {
      firstSegment: first,
      pathSegments: segments,
      shouldSkipEnforcement: segments.length > 0 && LOCALE_EXCLUDED_PATHS.includes(first),
      hasValidLocale: segments.length > 0 && SUPPORTED_LOCALES.includes(first)
    };
  }, [location.pathname]);

  // Handle locale initialization
  useEffect(() => {
    // Skip for excluded paths
    if (shouldSkipEnforcement) {
      if (!isLocaleReady) setIsLocaleReady(true);
      return;
    }

    // Update locale if needed
    const updateLocale = async () => {
      if (hasValidLocale && firstSegment !== locale && selectLanguage) {
        await selectLanguage(firstSegment);
      }

      if (!isLocaleReady) setIsLocaleReady(true);
    };

    updateLocale();
  }, [firstSegment, locale, selectLanguage, hasValidLocale, shouldSkipEnforcement, isLocaleReady]);

  // Early return for excluded paths
  if (shouldSkipEnforcement) {
    return <>{children}</>;
  }

  // Don't render until locale is ready
  if (!isLocaleReady) {
    return null;
  }

  // Redirect to locale path if no path segments
  if (!firstSegment) {
    return <Navigate to={`/${locale}`} replace />;
  }

  // Redirect if first segment is not a valid locale
  if (!hasValidLocale) {
    return <Navigate to={`/${locale}${location.pathname}`} replace />;
  }

  // URL already includes valid locale
  return <>{children}</>;
};

export default EnsureLocale;
