import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import TestimonialCard, {Testimonial} from './TestimonialCard';
import {IntlShape} from "react-intl";

const SINGLE_TESTIMONIAL_WIDTH = 600;

interface TestimonialsCarouselProps {
  testimonials: Testimonial[];
  intl: IntlShape;
  interval?: number; // ms between scrolls, default 5000
}

const TestimonialsCarousel: React.FC<TestimonialsCarouselProps> = ({
                                                                     testimonials,
                                                                     intl,
                                                                     interval = 5000,
                                                                   }) => {
  const N = testimonials.length;

  const extended = [
    testimonials[N - 1], // clone of last item
    ...testimonials,     // all real items
    testimonials[0],     // clone of first item
  ];

  const [currentIndex, setCurrentIndex] = useState(1);
  const [transitionEnabled, setTransitionEnabled] = useState(true);
  const [visibleItems, setVisibleItems] = useState(2); // Default: show 2 items

  // Determine visible items based on viewport width
  useEffect(() => {
    const updateVisibleItems = () => {
      setVisibleItems(window.innerWidth < SINGLE_TESTIMONIAL_WIDTH ? 1 : 2);
    };
    updateVisibleItems(); // Initial check
    window.addEventListener('resize', updateVisibleItems);
    return () => window.removeEventListener('resize', updateVisibleItems);
  }, []);

  // Move forward every `interval` ms
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prev) => prev + 1);
    }, interval);

    return () => clearInterval(timer);
  }, [interval]);

  // Handle transition end for wrap-around logic
  const handleTransitionEnd = () => {
    if (currentIndex === N + 1) {
      setTransitionEnabled(false);
      setCurrentIndex(1);
    } else if (currentIndex === 0) {
      setTransitionEnabled(false);
      setCurrentIndex(N);
    }
  };

  // Re-enable transitions after instant jump
  useEffect(() => {
    if (!transitionEnabled) {
      requestAnimationFrame(() => setTransitionEnabled(true));
    }
  }, [transitionEnabled]);

  // Calculate the translation percentage based on visible items
  const translatePercentage = -(currentIndex * (100 / visibleItems));

  return (
    <Box position="relative" width="100%" overflow="hidden">
      <Box
        display="flex"
        alignItems="stretch"
        flexDirection="row"
        sx={{
          transition: transitionEnabled ? 'transform 0.5s ease' : 'none',
          transform: `translateX(${translatePercentage}%)`,
        }}
        onTransitionEnd={handleTransitionEnd}
      >
        {extended.map((item, idx) => (
          <Box
            key={idx}
            flex={`0 0 ${100 / visibleItems}%`} // Adjust flex-basis dynamically
            boxSizing="border-box"
            display="flex"
            flexDirection="column"
          >
            <TestimonialCard testimonial={item} intl={intl} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default TestimonialsCarousel;
