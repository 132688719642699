import React, {PropsWithChildren, useEffect, useState} from 'react';
import {IntlProvider} from 'react-intl';
import messages_en from "../../lang/en.json";
import messages_fr from "../../lang/fr.json";
import {reloadUser, updateProfile} from "../sessions/sessionSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../app/store";

export const SUPPORTED_LOCALES = ["en", "fr"];

// get browser language without the region code
const language = (navigator.language.split(/[-_]/)[0] == 'fr' ? 'fr' : 'en') as localeKey;

interface LocaleContext {
  locale: string;
  selectLanguage?: (e: any) => void;
}

const defaultLocaleState = {
  locale: language,
};

const I18nContext = React.createContext<LocaleContext>(defaultLocaleState);

type IntlMessages = typeof messages_en;

const all_messages = {
  'en': messages_en,
  'fr': messages_fr
};

type localeKey = keyof typeof all_messages;

const LocaleWrapper = (props:PropsWithChildren) => {
  const [locale, setLocale] = useState<string>(language);
  const [messages, setMessages] = useState<IntlMessages>(all_messages[language]);
  const currentUser = useSelector((state : RootState) => state.session.currentUser);
  const loggedUser = currentUser && currentUser.id !== undefined;

  async function selectLanguage(newLocale: string) {
    // console.log('LocaleWrapper: selectLanguage ' + newLocale);
    setLocale(newLocale);
    const locale_messages = all_messages[newLocale as localeKey];
    setMessages(locale_messages);
    return new Promise<void>((resolve) => {
      // Use setTimeout to ensure state updates have completed
      setTimeout(resolve, 0);
    });
  }

  // useEffect(() => {
  //   if (loggedUser) {
  //     console.log("LocaleWrapper useEffect: loggedUser = " + loggedUser + " and current locale = " + locale);
  //     if (currentUser?.locale && currentUser?.locale !== locale) {
  //       console.log('LocaleWrapper: updating user locale to ' + currentUser?.locale);
  //       setLocale(currentUser?.locale);
  //       const locale_messages = all_messages[currentUser?.locale as localeKey];
  //       setMessages(locale_messages)
  //
  //     }
  //   }
  // }, [loggedUser, currentUser]);

  return (
    <I18nContext.Provider value = {{locale, selectLanguage}}>
      <IntlProvider messages={messages} locale={locale}>
        {props.children}
      </IntlProvider>
    </I18nContext.Provider>
  );
}
export default LocaleWrapper;
export {I18nContext};