// import ActionCable from "@rails/actioncable";
// https://github.com/rmosolgo/graphql-ruby/issues/3812
import * as ActionCable from '@rails/actioncable'
import {Consumer, Subscription} from "@rails/actioncable";

const cable_url = `${process.env.REACT_APP_ACTION_CABLE_URL}/boudalive`;

export const cable = ActionCable.createConsumer(cable_url);

interface Subscriptions {
  selection: Subscription<Consumer> | null,
  logos: Subscription<Consumer> | null,
  boutiques: Subscription<Consumer> | null
}

export const UserSubscriptions: Subscriptions = {
  selection: null,
  logos: null,
  boutiques: null
};

