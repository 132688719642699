import './AppBar.scss';
import * as React from 'react';
import logo from '../../images/dagoba.png';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import StorefrontIcon from '@mui/icons-material/Storefront';
import HelpIcon from '@mui/icons-material/Help';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {To, useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from "../../app/store";
import {
  reloadUser,
  ROLE_ADMIN,
  ROLE_BOUTIQUE,
  updateProfile,
  impersonatingUser, flagImpersonating
} from "../sessions/sessionSlice";
import {useAppSelector} from "../../app/hooks";
import {ChangeEvent, KeyboardEventHandler, useCallback, useContext, useEffect, useState} from "react";
import {I18nContext, SUPPORTED_LOCALES} from "../locale/LocaleWrapper";
import ReactCountryFlag from "react-country-flag"
import {FormattedMessage, useIntl} from "react-intl";
import {getAccessToken} from "../../app/sessionAPI";
import {openDemoBoutique} from "../dashboard/boutiqueSlice";
import TShirtSvg from "../catalog/category/TShirtSvg";
import {showBackDrop} from "../global/globalSlice";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import SearchCatalog from "../catalog/SearchCatalog";

const ResponsiveAppBar = () => {

  const { locale } = useContext(I18nContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const catalogVisible = useAppSelector((state: RootState) => state.global.catalogVisible);
  const helpVisible = useAppSelector((state: RootState) => state.global.helpVisible);

  const currentUser = useSelector((state : RootState) => state.session.currentUser);
  const loggedUser = currentUser && currentUser.id !== undefined;
  const canAskDemo = true;
  const userBoutique = loggedUser && currentUser.role !== undefined &&
      (currentUser.role >= ROLE_BOUTIQUE || impersonatingUser(currentUser))

  const userProduction = loggedUser && currentUser.production
  const userLogs = loggedUser && currentUser.superAdmin

  const userAdmin = loggedUser && currentUser.role === ROLE_ADMIN

  const dispatch = useDispatch<AppDispatch>();

  const accessToken = getAccessToken();

  // console.log("ResponsiveAppBar rendering with locale = " + locale + " and accessToken = " + accessToken + " and currentUser = ", currentUser)

  useEffect(() => {
    if (accessToken && accessToken !== 'undefined' && !loggedUser) {
      // console.log("ResponsiveAppBar useEffect dispatching reloadUser because accessToken = ", accessToken)
      dispatch(reloadUser());
    }
  }, [dispatch, accessToken, currentUser]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };


  function handleNavigate(route: To, event: React.MouseEvent) {
    event?.preventDefault();
    navigate(route);
  }

  function handleLogout(event: React.MouseEvent<HTMLLIElement, MouseEvent>) {
    event?.preventDefault();
    handleCloseUserMenu();
    dispatch(showBackDrop())
    navigate(`/${locale}/logout`);
  }

  function handleUpdateProfileAccount(event: React.MouseEvent<HTMLLIElement, MouseEvent>) {
    event?.preventDefault();
    handleCloseUserMenu();
    navigate(`/${locale}/profile`);
  }

  const isEmpty = function(text: string): boolean {
    return text === null || text.match(/^ *$/) !== null;
  };

  function userDisplay() {
    if (loggedUser) {
      let first_last = currentUser.firstName + ' ' + currentUser.lastName;
      return (isEmpty(first_last) ? currentUser.email : first_last.trim());
    } else {
      // return <FormattedMessage id="global.account" />;
      return null;
    }
  }

  const handleUserMenuClose = () => {
    setAnchorElUser(null);
  };

  const clickBoutique = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (userBoutique) {
      handleNavigate(`/${locale}/dashboard`, event)
    } else {
      handleNavigate(`/${locale}/catalog`, event)
      dispatch(openDemoBoutique({open: true, loading: false}));
    }
  }

  const selectLocale = (newLocale: string, updateUser: boolean) => {

    // Update the URL:
    // Split the current pathname into segments.
    const segments = location.pathname.split("/");
    // If the first segment (after the initial empty string) is a supported locale,
    // replace it. Otherwise, prepend the new locale.
    if (segments[1] && SUPPORTED_LOCALES.includes(segments[1])) {
      segments[1] = newLocale;
    } else {
      segments.splice(1, 0, newLocale);
    }
    const newPath = segments.join("/");

    // Navigate to the new URL
    navigate(newPath, { replace: true });

    if (loggedUser && updateUser) {
      dispatch(updateProfile({locale: newLocale}));
    }
  };

  useEffect(() => {
    if (loggedUser) {
      // if the user has a locale different from the current locale, change the locale
      if (currentUser.locale && currentUser.locale !== locale) {
        selectLocale(currentUser.locale, false);
      }
    }
  }, [currentUser]);


  const menuId = 'primary-search-account-menu';

  // console.log("ResponsiveAppBar rendering")

  return (
    <AppBar position="static" className={`app-bar ${process.env.NODE_ENV}`}>
      <Toolbar disableGutters>
        <img src={logo} onClick={() => navigate(`/${locale}`)}
             style={{width: 80, cursor: 'pointer',
               marginLeft: 'clamp(0px, 1vw, 0.5rem)', marginRight: 'clamp(0px, 1vw, 3rem)'}}/>
        <Box sx={{flexGrow: 1, display: 'flex'}}>
          <Button key='catalog-1' className='menu-button' onClick={(e) => handleNavigate(`/${locale}/catalog`, e)}>
            <Box className='tshirt'>{TShirtSvg('clamp(1rem, 2.4vw, 2.2rem)')}</Box>
            {!catalogVisible && <Box className='hide-xs' sx={{ml: 1}} >
              <FormattedMessage id="appbar.catalog"/>
            </Box>}
          </Button>
          {(canAskDemo || flagImpersonating || process.env.NODE_ENV === 'development' || userBoutique)
            && (!userProduction || userAdmin) &&
            <Button key='eshop-1' className='menu-button' onClick={clickBoutique}>
              <StorefrontIcon />
              <Box sx={{display: {xs: 'none', sm: 'flex'}, ml: 1}} >
                <FormattedMessage id={`appbar.${userBoutique ? 'dashboard' : 'eshop'}`} />
              </Box>
            </Button>
          }
          {!userProduction &&
            <Button key='helpcenter' className='menu-button'
                  onClick={(event) => handleNavigate(`/${locale}/help`, event)}>
              <HelpIcon />
              {!catalogVisible && <Box sx={{display: {xs: 'none', sm: 'flex'}, ml: 1}} >
                <FormattedMessage id="appbar.help"/>
              </Box>}
            </Button>
          }
          {userAdmin &&
            <Button key='products-1' className='menu-button'
                    onClick={(event) => handleNavigate("/admin/catalog", event)}
			              sx={{my: 2, color: 'white', display: 'block'}}>
				    <FormattedMessage id="appbar.products"/>
			    </Button>
          }
          {userProduction &&
            <Button key='production-1' className='menu-button'
                    onClick={(event) => handleNavigate("/admin/production", event)}
                    sx={{my: 2, color: 'white', display: 'block'}}>
				    <FormattedMessage id="appbar.production"/>
			    </Button>
          }
          {userLogs &&
            <Button key='logs-1' className='menu-button'
                    onClick={(event) => handleNavigate("/admin/logs", event)}
                    sx={{my: 2, color: 'white', display: 'block'}}>
				    Logs
			    </Button>
          }
        </Box>

        {(catalogVisible || helpVisible || userProduction) &&
          <Box sx={{flexGrow: 1}}>
            <SearchCatalog />
          </Box>
        }

        {!catalogVisible && !userBoutique && !userProduction &&
          <Button color='success' variant='contained' key='start' size='small'
                  sx={{display: 'flex', fontSize: 'var(--fs-md)'}} onClick={clickBoutique}>
            <RocketLaunchIcon />
            <Box className='hide-xs' sx={{ml: 1, fontSize: 'var(--fs-normal)'}} >
              <FormattedMessage id="home.cta2" />
            </Box>
          </Button>
        }

        <Box sx={{flexGrow: 0, display: {xs: 'none', md: 'flex'}, ml: 1, cursor: 'pointer'}} onClick={handleOpenUserMenu}>
          {userDisplay()}
        </Box>
        <Box sx={{flexGrow: 0, display: {xs: 'flex'}}}>
          <IconButton
            sx={{mr: '0px'}}
            size="medium"
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleOpenUserMenu}
            color="inherit">
            <AccountCircle fontSize='large'/>
          </IconButton>
        </Box>
        <Menu
          sx={{mt: '35px'}}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {loggedUser && ([
            <MenuItem key='update' onClick={(event) => handleUpdateProfileAccount(event)}>
              <Typography textAlign="center"><FormattedMessage id="session.my-account"/></Typography>
            </MenuItem>,
            <MenuItem key='logout' onClick={(event) => handleLogout(event)}>
              <Typography textAlign="center"><FormattedMessage id="session.logout"/></Typography>
            </MenuItem>
          ]) || ([
            <MenuItem key='login' onClick={(event) => handleNavigate(`/${locale}/login`, event)}>
              <Typography textAlign="center"><FormattedMessage id="session.login"/></Typography>
            </MenuItem>,
            <MenuItem key='signup' onClick={(event) => handleNavigate(`/${locale}/signup`, event)}>
              <Typography textAlign="center"><FormattedMessage id="session.signup"/></Typography>
            </MenuItem>,
          ])}
        </Menu>
        <Box sx={{mr: '10px', flexGrow: 0, display: {xs: 'flex'}}}>
          <Select className='select-lang' value={locale} disableUnderline variant='standard'
                  onChange={(e) => selectLocale(e.target.value, true)}>
            <MenuItem value='en'><ReactCountryFlag countryCode="GB"/></MenuItem>
            <MenuItem value='fr'><ReactCountryFlag countryCode="FR"/></MenuItem>
          </Select>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default ResponsiveAppBar;


